.footer {
    &__tabs {
        @include max-screen($screen__m) {
            margin: $indent__base 0 $indent__l;
        }

        @include min-screen($screen__m) {
            [data-role="collapsible"] {
                pointer-events: none;
            }
        }

        @include screen($screen__m, $screen__l) {
            flex-wrap: wrap;
        }

        [aria-expanded="true"] {
            .footer__tab-title {
                &::after {
                    content: '';
                    height: 1px;
                    width: 12px;
                    background: $color-gray-dark;
                }
            }
        }
    }

    &__tab {
        @include min-screen($screen__xl) {
            flex: 1;
        }

        @include screen($screen__m, $screen__l) {
            width: 50%;
            flex: 0 0 50%;
        }
    }

    &__tab-content {
        padding-bottom: $indent__l;
        display: none;

        @include min-screen($screen__m) {
            display: block !important;
        }

        &,
        p {
            color: $color-gray-darker;
        }

        div {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    &__tab-title {
        letter-spacing: 2.5px;

        &::after {
            content: '';
            height: 12px;
            width: 12px;
            @include icon('plus');
            float: right;
            position: relative;
            top: 10px;
            transition: transform .5s;
        }

        @include max-screen($screen__m) {
            cursor: pointer;
        }

        @include min-screen($screen__m) {
            &::after {
                display: none;
            }
        }
    }

    @include max-screen($screen__m) {
        &__tab {
            box-shadow: 0 2px 0px -1px #000, 0 3px 0 -1px rgba(#fff, 0.1);

            &:first-child {

                &:before {
                    content: '';
                    height: 0;
                    border-top: #000;
                    border-bottom: 1px solid rgba(#fff, 0.1);
                    position: absolute;
                    left: $indent__base;
                    right: $indent__base;
                }
            }
        }

        &__tab-title {
            padding: 15px 0 10px;
            margin-bottom: 0 !important;
            text-align: center;
        }

        [aria-expanded="true"] {
            .footer__tab-title {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
