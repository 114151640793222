.cta-banner {
    background: rgb(0, 0, 0);
    color: $color-white;
    height: 360px;
    position: relative;
    margin-top: $indent__xl;

    &__image {
        height: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
            height: 100px;
            width: 100%;
            bottom: 0;
        }
    }

    &__text {
        color: $color-white;
        margin-top: 0;
    }

    &__button {
        @include btn-outlined-reverse;
        @include btn-icon;
    }

    &__content {
        padding: $indent__base;
        box-sizing: border-box;
    }

    @include max-screen($screen__m) {
        &__inner {
            height: 50%;
            display: flex;
            align-items: center;
        }
    }

    @include min-screen($screen__m) {
        height: 240px;
        display: flex;
        flex-direction: row-reverse;

        &__inner {
            flex: 1;
        }

        &__image {
            height: 100%;
            flex: 1;

            &::before {
                position: relative;
                background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                height: 100%;
                width: 230px;
                bottom: auto;
            }
        }

        &__content {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            padding: 0;
        }

        &__content-inner {
            @include layout-inner-small;
            width: 100%;
        }
    }
}