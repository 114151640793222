@include min-screen($screen__m) {
    .aw_blog-index-index,
    .aw_blog-category-view,
    .aw_blog-post-view {
        .columns {
            padding: 0;
        }
    }
}

