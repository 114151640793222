.aw-acp-popup-container.mfp-bg,
.aw-acp-popup-container.mfp-wrap {
    z-index: 999999;
}

.aw-acp-popup__submit.action.primary {
    @include btn-dark;
    font-size: 1.3rem !important;
    display: block !important;
    background: black !important;
    margin-bottom: 1rem !important;

    &:after {
        background: white !important;
    }
}

.aw-acp-popup__loader-bar {
    border-radius: 0;
    background: #4a4a4a;
}

.aw-acp-popup__columns {
    align-items: center;

    @include max-screen($screen__m) {
        display: flex;
        flex-direction: column;

        .aw-acp-popup__close-wrapper {
            order: 2;
        }

        .aw-acp-popup__submit-wrapper {
            order: 1;
            margin-bottom: 20px;
        }
    }
}

.aw-acp-popup__message--good {
    background: $color-gray;
    color: $color-black;
}

.aw-acp-popup__mobile-close-wrapper .aw-acp-popup__mobile-close.action.primary {
    color: $color-white !important; // sass-lint:disable-line no-important
}

.aw-acp-popup__mobile-title {
    display: none;
}

.aw-acp-popup__box-tocart-wrapper {
    .aw-acp-popup__box-price {
        margin-left: $indent__base;

        .price-container .price {
            color: $color-black;
        }
    }
}

.aw-acp-popup__related {
    .products-grid {
        &:not(.swiper-container) {
            margin: inherit;
        }

        .product-item-info {
            @media (min-width: $screen__s) {
                padding-bottom: 0;
            }

        }

        .product-item-link:hover {
            text-decoration: none;
        }

        .product-item .product-item-name em {
            display: block;
            font-style: normal;
            font-family: "Noto Serif JP", serif;
            color: #666666;
            font-weight: 300;
        }
    }
}


.aw-acp-popup__submit.action.submit {
    @include btn-primary();
    @include btn-dark();
    background: $color-black !important; // sass-lint:disable-line no-important
    color: $color-white !important; // sass-lint:disable-line no-important
}

.aw-acp-popup {

    .aw-acp-popup__loader {
        @include max-screen($screen__m) {
            margin-top: 80px;
        }
    }

    .aw-acp-popup__column:first-child {
        @include max-screen($screen__m) {
            .btn.btn-outline:first-child {
                margin-bottom: 20px;
            }
        }

        .btn-link {
            display: block !important;
            padding: 0;
            border: 0;
            text-decoration: underline;
            text-transform: inherit;
            background-color: transparent;
            font-size: 14px;

            &:hover {
                background-color: transparent;
                color: $color-black;
            }

            &::after {
                display: none;
            }
        }
    }

    @include min-screen($screen__m) {
        .aw-acp-popup__columns {
            align-items: center;
        }

        .aw-acp-popup__box-tocart-wrapper {
            align-items: center;

            .aw-acp-popup__box-qty {
                .field.qty {
                    display: flex;
                    align-items: center;

                    .label {
                        margin: 0;
                        margin-right: 10px;
                    }
                }
            }
        }

        .aw-acp-popup__column:first-child {
            display: flex;

            .btn.btn-outline:first-child {
                display: none !important;
            }
        }

        .aw-acp-popup__column:nth-child(2) {
            display: flex;
            flex-direction: column;
        }

        .swatch-attribute-options {
            text-align: left;
        }

        .swatch-attribute-selected-option {
            display: none;
        }
    }

    .aw-acp-popup__loader-bar {
        display: block;
        background: transparent;
        width: 47px !important;
        height: 47px !important;
        box-shadow: none;

        &::after {
            animation: none;
            background: url("../images/icons/loader.gif") no-repeat center;
        }
    }

    @include max-screen($screen__m) {
        .aw-acp-popup__submit-wrapper {
            display: flex;
            flex-direction: column;

            .btn {
                width: 100%;

                &[data-action="submit"] {
                    display: block !important;
                    order: 2;
                }
            }

        }

        .aw-acp-popup__mobile-header {
            height: auto;
        }

        .aw-acp-popup__mobile-close-wrapper {
            .aw-acp-popup__mobile-close.action.primary {
                color: black !important;
            }
        }

    }

    .aw-acp-popup__related strong {
        display: block;
        font-family: $font-family-name__base;
        text-transform: uppercase;
        font-weight: $font-weight__light;

        @include min-screen($screen__m) {
            margin-top: 5rem;
        }
    }

    @media (min-width: 640px) {
        .aw-acp-popup__related .products-grid .product-item-info:hover,
        .aw-acp-popup__related .products-grid .product-item-info .active {
            box-sizing: border-box;
        }

        .aw-acp-popup__related .products-grid .product-item-info .actions-primary {
            position: static;
        }
    }

}
