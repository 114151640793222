//
//  Button variables
//  _____________________________________________

$button__font-family                          : $font-family__base;
$button__font-size                            : 1.3rem;
$button__font-weight                          : $font-weight__heavier;
$button__line-height                          : $font-size__base + 6;
$button__padding                              : 12.5px 35px 12.5px 20px;
$button__padding-mobile                       : 12.5px 35px 12.5px 20px;

$button__border-radius                        : 0;

$button__color                                : $color-black;
$button__background                           : $color-white;
$button__border                               : 1px solid $button__background;

$button__hover__color                         : $color-black;
$button__hover__background                    : transparent;
$button__hover__border                        : 1px solid $button__hover__color ;

$button__active__color                        : $button__hover__color;
$button__active__background                   : $button__hover__background;
$button__active__border                       : $button__hover__border;

//  Large button
$button__font-size__l                         : $button__font-size;
$button__line-height__l                       : $button__line-height;
$button__padding__l                           : 15px 35px 15px 20px;

// Small button
$button__font-size__s                         : $button__font-size;
$button__line-height__s                       : $button__line-height;
$button__padding__s                           : 7.5px 35px 7.5px 20px;
