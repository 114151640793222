.product-options-wrapper {
    margin-top: 0;
    margin-bottom: $indent__base;

    .swatch-opt {
        margin-bottom: 0;
        margin-top: 0;
    }

    .fieldset {
        box-shadow: none;
    }

    .swatch-attribute-label {
        display: none;
    }
}

.product-add-form .fieldset > .field > .label span,
.product-options-wrapper .swatch-attribute-label {
    @include product-label;
    font-size: 1.6rem;
    font-weight: 600;

    &:after {
        display: none;
    }
}

.swatch-attribute.size {
    div.mage-error[generated] {
        margin: -5px 0 15px;
    }

    .swatch-attribute-label {
        display: none;
    }
}
