.popular-filter {
    clear: both;
    padding-top: 4.5rem;
}

.popular-filter-elements {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        overflow-x: scroll;
        scroll-padding: 0 50%;
        scroll-snap-type: x mandatory;

        @include min-screen($screen__m) {
            overflow: hidden;
            overflow-x: auto;
        }

        li {
            display: inline-block;
            scroll-snap-align: start;
        }

        a {
            display: inline-block;
            padding: .5rem 1rem;
            border: 1px solid #ddddde;
            font-size: 1.45rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            text-decoration: none;
            letter-spacing: 1px;
            background: white;
            transition: background .2s ease;
            white-space: nowrap;
            text-transform: uppercase;

            &:hover {
                background: #f0f0f0;
                text-decoration: none;
            }
        }
    }
}

.popular-filter-title {
    font-size: 1.45rem;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 1rem;
}

.popular-filter-container {
    display: block;

    @include min-screen($screen__md) {
        display: none;
    }
}

.catalog-category-view:not(.category-dames):not(.category-heren) {
    @include max-screen($screen__m) {
        .popular-filter {
            padding: 0;
        }

        .popular-filter .popular-filter--title {
            display: none;
        }
    }
}
