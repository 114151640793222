.tooltip-widget {
    @include lib-tooltip(
        $_tooltip-position: left,
        $_tooltip-selector-content: '.tooltip-widget__content',
        $_tooltip-selector-toggle : '.tooltip-widget__toggle'
    );

    &__toggle {
        @include icon('info');
    }

    &__content {
        box-shadow: $dropdown-list__shadow;
    }
}
