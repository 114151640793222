.catalog-product-view {
    .block-photo-slider {
        margin-bottom: 20px !important; // sass-lint:disable-line no-important
    }
}

.cms-index-index {
    .block-photo-slider {
        .block-title {
            display: none;
        }
    }
}

.block-photo-slider {
    &.container {
        width: 100% !important; // sass-lint:disable-line no-important
        padding-right: 0;
        padding-left: 0;
    }

    .slides li > a .text {
        display: none;
    }

    ul.nine-photo {
        & > li {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(7) {
                width: 50%;
                float: left;
            }

            &:nth-child(5) {
                width: 100%;
                float: right;
            }

            &:nth-child(8),
            &:nth-child(9) {
                display: none;
            }
        }
    }

    // hide unused elements
    .instagram-button {
        display: none;
    }

    // unset preset margin:
    .flexslider {
        margin: 0;
        border: none;
    }
}

// pop-up
#widget-detail-branding span.powered-by {
    display: none !important; // sass-lint:disable-line no-important
}

.widget-instagram-url {
    pointer-events: none;
}

.hotspot-thumbnail {
    width: 47px;
    height: 47px;
    background-color: $color-white;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    &:after {
        @include icon('eye');
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
}

@include min-screen($screen__m) {
    .block-photo-slider {
        ul.nine-photo {
            & > li {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6),
                &:nth-child(7) {
                    width: 20%;
                    float: left;
                }

                &:nth-child(4) {
                    width: 40%;
                    float: right;
                }

                &:nth-child(5) {
                    width: 40%;
                    float: left;
                }

                &:nth-child(8),
                &:nth-child(9) {
                    display: block;
                    width: 20%;
                    float: right;
                }
            }
        }
    }
}
