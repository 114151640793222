.header {
    .logo {
        position: absolute;
        left: 50%;
        top: 18px;
        margin: 0 -50% 0 0;
        transform: translate(-50%, 0);
        transform-origin: center left;
        border: 2px solid transparent;

        img {
            width: 122px;
        }
    }

    @include min-screen($screen__m) {
        .logo {
            top: 30px;
            margin: 0;

            //.is-header-sticky & {
            //    top: 30px;
            //}

            img {
                width: 176px;
            }
        }
    }
}

body.offscreen-nav-open .header.headroom,
.header.headroom--not-top {
    .logo {
        img {
            width: 122px;
        }
    }

    @include min-screen($screen__m) {
        .logo {
            top: 24px;
        }

        .header.content {
            border-bottom: 0;
        }
    }
}

