.aw_blog-index-index {

    .blog-posts-post {
        box-sizing: border-box;
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: $indent__s;
        padding-left: $indent__s;
        padding-bottom: 15px;


        .blog-post-link-wrapper {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            height: 100%;
        }

        .blog-post-content {
            padding: 20px;
            display: flex;
            flex: 1 1 auto;
            flex-flow: column nowrap;
            justify-content: flex-start;

            .blog-post-read-more-wrapper {
                display: flex;
                flex: 1 1 auto;
                flex-flow: column nowrap;
                justify-content: flex-end;

                .blog-post-read-more {
                    text-decoration: underline;
                }
            }
        }

        .blog-post-info {
            display: none;
        }

    }
}

.blog-posts {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: ($indent__s * -1);
    margin-left: ($indent__s * -1);
}

.blog-posts-post {

    .blog-post-title a {
        text-decoration: none;

    }

    .blog-post-featured-image-wrapper {
        width: 100%;

        .blog-post-featured-image-link {
            height: 0;
            overflow: hidden;
            padding-top: ( 9 / 16 ) * 100%;
            position: relative;
            display: block;

            & > img {
                display: block;
                transition: transform .3s ease;
                @include object-fit(cover);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &:hover {
        .blog-post-featured-image-link {
            & > img {
                transform: scale(1.05);
            }
        }
    }
}

@include min-screen($screen__s) {

    .aw_blog-index-index {

        .blog-posts-post {
            flex-basis: (100% / 2 * 1);
            max-width: (100% / 2 * 1);

            &:nth-child(3),
            &:nth-child(8) {

                flex-basis: (100% / 2 * 2);
                max-width: (100% / 2 * 2);

                .blog-post-content {
                    background-color: $color-primary;
                    color: $color-white;

                    .blog-post-title-link,
                    a,
                    p {
                        color: $color-white;
                    }

                }
            }
        }
    }

}

@include min-screen($screen__l) {

    // post gird
    .aw_blog-index-index {

        .blog-posts-post {
            flex-basis: (100% / 3 * 1);
            max-width: (100% / 3 * 1);

            // first reset tablet
            &:nth-child(3),
            &:nth-child(8) {
                flex-basis: (100% / 3 * 1);
                max-width: (100% / 3 * 1);

                .blog-post-content {
                    background-color: $color-white;
                    color: $color-primary;

                    .blog-post-title-link,
                    a,
                    p {
                        color: $color-primary;
                    }

                }
            }

            // dark items
            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7),
            &:nth-child(8) {

                .blog-post-content {
                    background-color: $color-primary;
                    color: $color-white;

                    .blog-post-title-link,
                    a,
                    p {
                        color: $color-white;
                    }

                }

            }

            // expanded items
            &:nth-child(6n+1) {
                flex-basis: (100% / 3 * 2);
                max-width: (100% / 3 * 2);

                .blog-post-link-wrapper {
                    flex-direction: row;
                }


                .blog-post-featured-image-wrapper {
                    .blog-post-featured-image-link {
                        height: 100%;
                        padding-top: 0;
                    }
                }

                .blog-post-content {
                    width: 25%;
                }
            }

        }
    }

}
