.sizechart {
    display: none;
    cursor: pointer;
    margin-left: $indent__m;
    color: $color-gray-dark;
    transform: translateY(15px);

    &.is-visible {
        display: flex;
    }
}


.sizechart__label {
    text-decoration: underline;
    font-size: 12px;
    margin-left: $indent__s;
    transform: translateY(2px);
}
