.column.main {
    #layered-selected-filters-block {
        display: none;
    }
}

.filter-current .item {
    display: inline-block;
    cursor: pointer;
    background: white;
    font-weight: $font-weight__light;
    font-size: 1.2rem;
    letter-spacing: 1.38px;
    border: 1px solid rgba(86, 84, 88, 0.2);
    margin-bottom: 10px !important;
    border-radius: 1px;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;

    a {
        text-decoration: none !important;
    }

    .action-close {
        span,
        &:before {
            display: none;
        }

        &:after {
            @include icon('close-small');
            position: relative;
            margin-left: $indent__xs;
        }
    }

    &:hover {
        text-decoration: none;
        background: $color-black;
        color: white;

        a {
            color: white;
        }

        .action-close {
            &:after {
                @include icon('close-small-white');
            }

            &:hover:after {
                background-color: transparent !important;
            }
        }
    }
}

.filter-actions {
    font-size:  1.2rem;
    margin-bottom: 40px !important;
}
