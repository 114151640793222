.checkout-payment-method {
    .form.payments {
        max-width: 500px;
        accent-color: black;
    }

    .payment-methods {
        input[type="checkbox"] {
            display: none;
        }

        input[type="checkbox"] + label {
            margin: 0;
            padding-left: 30px;
        }

        input[type="checkbox"] + label {
            position: relative;
            cursor: pointer;

            &::before {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                box-sizing: border-box;
                border: 1px solid $color-black;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                cursor: pointer;
                background-color: transparent;
                transition: background 250ms ease-in-out;
            }

            &::after {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                background-repeat: no-repeat;
                background-position: center 5px;
                background-image: url('../images/icons/check-white.svg');
                transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
                cursor: pointer;
                opacity: 0;
            }
        }

        input[type="checkbox"]:checked + label {
            &::before {
                background-color: $color-black;
            }

            &::after {
                background-color: $color-black;
                visibility: visible;
                opacity: 1;
            }
        }

        input[type="radio"] {
            display: none;
        }

        input[type="radio"] + label {
            margin: 0;
        }

        input[type="radio"] + label {
            position: relative;
            cursor: pointer;
        }

        input.bank-types-input:checked + .bank-name {
            font-weight: bold;
        }

        label.bank-types {
            cursor: pointer;
        }

        label[class^="buckaroo_magento2"]::after {
            background-size: contain !important;
        }
    }

    .payment-method {
        border: 1px solid #e4e4e4;
        border-radius: 3px;
        margin-bottom: 1.5rem;

        &._active {
            border-color: #8c8c8c;
        }

        .payment-fee {
            display: none; // TEMP DISPLAY TO ENABLE PAYMENT COSTS
        }

        label.label {
            display: flex !important;

            & > span {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding-left: 1rem;
                font-weight: 500;
            }
        }

        .actions-toolbar {
            margin: 0 1rem 0 0 !important;

            .primary {
                float: none;
                display: block;
                width: 100%;
                margin: 0 !important;

                .action.checkout {
                    width: 100% !important;
                    margin-left: -38px;
                }
            }
        }

        .bank-types input {
            display: inline-block !important;
        }
    }

    .payment-option-title {
        border: none !important;
    }

    .billing-address-details {
        display: none;
    }

    .billing-address-form {
        margin-right: 3rem;
    }
}

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
    border-top: none !important;
}

.payment-method .payment-method-title {
    .bk-payment-wrap {
        width: 100%;
        .bk-img-wrap {
            max-width: 40px;
        }
    }
}

.checkout-index-index .checkout-payment-method .payment-method-title {
    padding: 20px 0;
}

.checkout-payment-method .buckaroo_magento2_all_giftcards {
    border-top: none !important;

    .payment-method-content {
        .messages {
            max-width: 500px;
            margin-right: 4rem !important;

            @include max-screen($screen__m) {
                margin-right: 15px !important;
                max-width: unset;
                align-items: initial;
            }
        }
    }

    .fieldset.payment {
        .field {
            max-width: 500px;
            margin-right: 4rem !important;

            @include max-screen($screen__m) {
                margin-right: 15px !important;
                max-width: unset;
                align-items: initial;
            }
        }

        .apply-gift-card {
            background-image: none;
            background: transparent;
            border: 1px solid #FFF;
            color: #1D1C22;
            cursor: pointer;
            display: inline-block;
            font-family: "Prompt", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 500;
            margin: 1rem 0 0;
            padding: 12.5px 35px 12.5px 20px;
            font-size: 1.3rem;
            line-height: 2.1rem;
            box-sizing: border-box;
            vertical-align: middle;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            font-family: "Prompt", "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-decoration: none;
            position: relative;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;
            border-color: #222128;
            border-radius: 0;
            text-decoration: none;
            align-self: flex-start;
        }
    }

    .payment-method-title img.buckaroo_magento2_logo {
        max-height: 30px;
    }
}

.checkout-billing-address {
    .billing-address-form {
        fieldset {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            .field {
                width: 100%;
                margin-right: 10px !important;
                flex-direction: column;

                &.fieldset.street,
                &[name="billingAddressadyen_cc.telephone"] {
                    width: 100%;
                    margin-right: 0;
                }

                &.pc_postcode {
                    width: calc(50% - 1rem);
                }

                &.pc_postcode_addition + .pc_postcode > label {
                    display: none !important;
                }
            }
        }
    }
}

.checkout-payment-method .payment-method-title label img,
.checkout-payment-method .payment-method-title label div {
    max-width: 65px;
    padding-right: 0 !important;
    margin-right: 10px;
    border-radius: 6px;
}

.checkout-payment-method .payment-method-title label div {
    width: 100% !important;
}

.checkout-index-index {
    .payment-option {
        // Styling inputs
        input[type="text"],
        input[type="date"],
        input[type="password"],
        input[type="url"],
        input[type="tel"],
        input[type="search"],
        input[type="number"],
        input[type="datetime"],
        input[type="email"],
        textarea,
        select {
            min-height: 50px;
            border: 1px solid #8c8c8c !important; // sass-lint:disable-line no-important
            border-radius: 3px;

            &.mage-error,
            &[aria-invalid="true"] {
                border-color: red !important; // sass-lint:disable-line no-important
            }
        }
    }
}


@media (min-width: 1200px) {
    .buckaroo_magento2_ideal .payment-method-second-col,
    .buckaroo_magento2_idealprocessing .payment-method-second-col,
    .buckaroo_magento2_creditcard .payment-method-second-col,
    .buckaroo_magento2_creditcards .payment-method-second-col,
    .buckaroo_magento2_transfer .payment-method-second-col,
    .buckaroo_magento2_afterpay .payment-method-second-col,
    .buckaroo_magento2_afterpay2 .payment-method-second-col,
    .buckaroo_magento2_afterpay20 .payment-method-second-col,
    .buckaroo_magento2_billink .payment-method-second-col,
    .buckaroo_magento2_sepadirectdebit .payment-method-second-col,
    .buckaroo_magento2_giropay .payment-method-second-col,
    .buckaroo_magento2_payperemail .payment-method-second-col,
    .buckaroo_magento2_klarna .payment-method-second-col,
    .buckaroo_magento2_klarnakp .payment-method-second-col,
    .buckaroo_magento2_klarnain .payment-method-second-col,
    .buckaroo_magento2_emandate .payment-method-second-col,
    .buckaroo_magento2_applepay .payment-method-second-col,
    .buckaroo_magento2_capayablein3 .payment-method-second-col,
    .buckaroo_magento2_capayablepostpay .payment-method-second-col,
    .buckaroo_magento2_tinka .payment-method-second-col,
    .buckaroo_magento2_mrcash .payment-method-second-col {
        width: 100% !important;
    }
}

.buckaroo_magento2_ideal .payment-method-billing-address,
.buckaroo_magento2_idealprocessing .payment-method-billing-address,
.buckaroo_magento2_creditcard .payment-method-billing-address,
.buckaroo_magento2_creditcards .payment-method-billing-address,
.buckaroo_magento2_transfer .payment-method-billing-address,
.buckaroo_magento2_afterpay .payment-method-billing-address,
.buckaroo_magento2_afterpay2 .payment-method-billing-address,
.buckaroo_magento2_afterpay20 .payment-method-billing-address,
.buckaroo_magento2_billink .payment-method-billing-address,
.buckaroo_magento2_sepadirectdebit .payment-method-billing-address,
.buckaroo_magento2_giropay .payment-method-billing-address,
.buckaroo_magento2_payperemail .payment-method-billing-address,
.buckaroo_magento2_klarna .payment-method-billing-address,
.buckaroo_magento2_klarnakp .payment-method-billing-address,
.buckaroo_magento2_klarnain .payment-method-billing-address,
.buckaroo_magento2_emandate .payment-method-billing-address,
.buckaroo_magento2_applepay .payment-method-billing-address,
.buckaroo_magento2_capayablein3 .payment-method-billing-address,
.buckaroo_magento2_capayablepostpay .payment-method-billing-address,
.buckaroo_magento2_tinka .payment-method-billing-address,
.buckaroo_magento2_mrcash .payment-method-billing-address {
    padding-right: 0 !important;
}

.checkout-payment-method .payment-methods input[type="radio"] {
    display: block !important;
    box-shadow: none !important;
}

.checkout-payment-method .payment-methods label.bank-types input[type="radio"],
.checkout-payment-method .payment-methods input[type="checkbox"] {
    display: inline-block !important;
    margin-right: 5px;
}

.checkout-payment-method .payment-methods {
    .buckaroo_magento2_afterpay20 .payment-method-second-col {
        fieldset {
            margin-right: 3rem;
        }

        #Saluation li {
            display: inline-block;
            margin-right: 10px;
        }

        input[type="radio"] {
            display: inline !important;
        }

        .field input {
            max-width: 100%;
            margin-bottom: 0 !important;
        }
    }

    .payment-method-content {
        .label > span {
            width: auto;
        }

        select {
            min-width: 280px;
        }
    }

    .label {
        padding-bottom: 5px;
    }

    #buckaroo_magento2_afterpay20_DoB {
        width: 85%;
        margin-right: 1rem;
    }

    .buckaroo_magento2_creditcards {
        .card .card_back .card_info {
            margin-top: 0;
            right: 5rem;
            position: absolute;
        }

        .card.styled .width-10 {
            float: none;
        }
    }

    .buckaroo_magento2_sofortbanking .buckaroo_magento2_image_title:before {
        margin-right: 10px;
        height: 25px;
    }

    .buckaroo_magento2_afterpay20 .buckaroo_magento2_image_title:before {
        background-size: auto 24px;
        height: 25px;
        margin-top: -1px;
    }

    .buckaroo_magento2_all_giftcards .buckaroo_magento2_image_title {
        @include max-screen($screen__m) {
            span {
                order: 1;
            }

            img.buckaroo_magento2_logo {
                order: 2;
            }
        }
    }
}

.checkout-index-index .checkout-payment-method .fieldset .field {
    display: block !important;
}

.checkout-payment-method .payment-methods .checkout-billing-address input[type="checkbox"] {
    display: none !important;
}

.checkout-payment-method-load .payment-method form .field label span {
    padding-left: 0;
}

.buckaroo_magento2_afterpay20.checkout-payment-method .payment-method label.label > span {
    padding-left: 0 !important;
}

.buckaroo_magento2_afterpay20 {
    .field.required + span + .control {
        display: flex;
    }
}

.checkout-index-index .checkout-payment-method .fieldset.payment .field {
    flex-direction: column;
}

.checkout-payment-method-load {
    .checkout-billing-address,
    .billing-address-form,
    .payment-method-billing-address {
        width: 100% !important;
    }
}


.checkout-index-index {
    .payment-method-billing-address {
        width: 100% !important;
    }

    .field label span {
        padding-left: 0 !important;
    }


    .field input[type="text"],
    .field input[type="date"],
    .field input[type="tel"],
    .field select {
        min-height: 50px;
        border: 1px solid #8c8c8c !important; // sass-lint:disable-line no-important
        border-radius: 3px;
    }

    .billing-address-form .field-tooltip.toggle {
        display: none !important;
    }
}

.ui-datepicker-trigger {
    &::after {
        display: none;
    }
}

.checkout-payment-method {
    .payment-option-title {
        .action-toggle {
            font-weight: 600;
        }
    }

    .step-discount-trigger {
        cursor: pointer;
        background: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        font-size: 14px;
        text-transform: inherit;
        transform: translateY(5px);

        * {
            pointer-events: none;
        }

        span {
            text-decoration: underline;
        }

        @include max-screen($screen__m) {
            font-size: 12px;
            transform: translateY(10px);
        }
    }

    .opc-payment-additional.giftcardaccount {
        padding-bottom: 250px;
    }

    .form-giftcard-account .field .control,
    .form-discount .field .control {
        flex-grow: 2;
    }

    .payment-group__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include max-screen($screen__m) {
            margin-bottom: 20px;
        }
    }

    div.mage-error {
        color: #e02b27;
        font-size: 1.2rem;
    }

    .payment-methods #buckaroo_magento2_afterpay20_DoB {
        width: 100%;
    }

    .buckaroo_magento2_afterpay20.payment-method._active .field {
        align-items: flex-start;
    }

    .buckaroo_magento2_afterpay20 {
        input[name="payment[method]"] {
            margin-top: 7px !important;
        }

        .ui-datepicker-trigger {
            position: absolute;
            right: 0;
            top: 52px;
        }
    }
}
