.product-options-bottom {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: $indent__base;
    margin-top: $indent__base;
    background-color: $color-gray;

    @include max-screen($screen__m) {
        max-width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 98;
        padding: 0 15px 20px 15px;
        //min-height: 107px !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        .product-options-wrapper {
            min-height: 55px !important;
        }
    }

    .product-options-wrapper {
        margin-bottom: 0;
    }

    //Dropdown
    .swatch-opt {
        display: inline-flex;
        order: 2;
        width: 100% !important;
        border: none;
        background: transparent;
        margin: 10px 0;
    }

    .swatch-dropdown {
        border: 1px solid $color-primary;
        background: #fff;
        color: #000;

        .action.toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;
            border: none;
            background: transparent;
            font-size: 15px;
            box-sizing: border-box;
            height: auto;

            &:after {
                float: right;
                position: relative;
                right: -5px;
            }

            @include max-screen($screen__m) {
                height: 40px;
            }
        }

        ul.dropdown {
            @include max-screen($screen__m) {
                top: inherit;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 0;
                border-top: 1px solid #565458;
                min-height: 30px !important;
                padding: 8px 0 30px;
                box-sizing: border-box;

                .swatch-dropdown__option {
                    padding: 8px 20px;
                    font-size: 1.5rem;
                }
            }

            @include min-screen($screen__m) {
                width: calc(100% + 2px);
                margin: -1px;
            }
        }

        .swatch-dropdown__option--placeholder {
            display: none;
            color: grey;
            pointer-events: none;

            @include max-screen($screen__m) {
                display: block;
            }
        }
    }

    .swatch-attribute {
        width: calc(100% - 2px) !important;

        .swatch-attribute-options {
            margin-top: 0;
            margin: 10px 0;

            @include max-screen($screen__m) {
                margin: 10px 0 5px;
            }
        }
    }

    //height adjustment sticky indicator
    .product-stock-indicator__column-label {
        @include max-screen($screen__m) {
            margin: 5px 0 10px;
        }
    }

    @include max-screen($screen__l) {
        max-width: 100%;
    }

    @include min-screen($screen__l) {
        padding: 25px 30px 0 30px;
    }

    .field-error,
    div.mage-error[generated] {
        font-weight: $font-weight__bold;
    }
}

.product-options-wrapper {
    @include max-screen($screen__m) {
        margin-bottom: 0 !important;
    }
}

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
    margin-bottom: 0;
    margin-top: 5px;
    width: 100%;

    .field.qty {
        display: none;
        @include min-screen($screen__m) {
            display: none;
        }
    }

    .actions {
        display: block;
        padding-top: 0;
        text-align: left;
        display: flex;
        flex-direction: column;

        button#product-addtocart-button {
            width: 100%;
            order: 2;

            @include max-screen($screen__m) {
                height: 40px;
            }
        }
    }

    @include max-screen($screen__m) {
        margin-top: 5px;
    }
}

.product-add-form {
    .action.primary.tocart {
        @include btn-dark;
        height: 50px;
        padding: 0 60px 0 $indent__base;
        position: relative;
        width: 100%;
        text-align: left;

        &:after {
            display: none;
        }

        &:before {
            @include icon('cart-white');
            position: absolute;
            right: 20px;
            top: 14px;

            @include max-screen($screen__m) {
                top: 9px;
            }
        }

        &:focus {
            color: black !important;
            background-color: white !important;
            opacity: 1 !important;

            &:before {
                @include icon('cart');
            }
        }

        &.disabled {
            color: black !important;
            background-color: white !important;
            opacity: 1 !important;

            &:before {
                @include icon('cart');
            }

            &:focus {
                color: black !important;
                background-color: white !important;
                opacity: 1 !important;
            }

            &:hover {
                color: black !important;
                background-color: white !important;
                opacity: 1 !important;
            }
        }

        &:hover {
            &:before {
                @include icon('cart');
            }
        }
    }
}

// Catalog view
body {
    &.catalog-product-view {
        @include max-screen($screen__m) {
            padding-bottom: 160px;
        }
    }
}

#product-addtocart-button {
    display: none;
}
