body {
    .product-items.widget-product-carousel {
        position: static;
        margin: 0 -#{$indent__base};
        display: none;

        &.slick-initialized {
            display: block;
        }

        &.slick-slider {
            .slick-arrow {
                margin-top: -100px;
            }
        }

        .product-item {
            padding-left: $indent__base;
            padding-right: $indent__base;
            transition: transform 300ms ease-in-out;

            &:hover {
                transform: translateY(-10px);
            }
        }

        .product-item-info {
            width: 100%;
            text-align: center;
        }
    }
}

