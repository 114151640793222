// PDP add to cart block
.box-tocart {
    .location-block-pdp {
        display: none;
        width: 100%;
        order: 3;
        color: #1D1C22;
        margin-top: 10px;

        &:hover {
            color: #000;
        }
    }

    .find_a_store {
        display: flex;
    }

    .location-block-pdp.is-disabled {
        cursor: not-allowed;

        .location-button {
            opacity: 0.7 !important;
        }
    }

    .location-button {
        min-height: 50px;
        margin-bottom: 0;

        @include max-screen($screen__m) {
            margin-left: 3px;
        }

        .icon_location {
            display: none;
        }

        &__select_option,
        &__text {
            display: inline-flex;
            position: relative;
            width: 100%;
            font-family: "Prompt", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            line-height: 2.1rem;
            text-align: left;
            text-decoration: none;
            box-sizing: border-box;
            vertical-align: middle;
            margin: 0;
            padding: 0 25px 0 0;
            cursor: pointer;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;
        }

        &::before {
            background-image: url('../images/icons/icon-pickup-in-store.svg');
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            right: 19px;
            top: 14px;
            display: block !important;
            width: 21px;
            height: 21px;
            transform: scale(2);
            content: '';

            @include max-screen($screen__m) {
                top: 9px;
                transform: scale(1.8) !important;
            }
        }

        &:hover {
            &:before {
                background-image: url('../images/icons/icon-pickup-in-store-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                right: 19px;
                top: 14px;
                display: block !important;
                width: 21px;
                height: 21px;
                transform: scale(2);
                content: '';
                transition: 200ms ease-in-out;
            }
        }

        &::after {
            display: none;
        }

        @include max-screen($screen__m) {
            min-height: 40px;
            height: 40px;
        }
    }
}
