//
//  Normalize
//  ---------------------------------------------

b,
strong {
    font-weight: $font-weight__heavier;
}

body {
    font-weight: $font-weight__light;
    letter-spacing: 1.3px;
}

//
//  Typography
//  ---------------------------------------------

h1,
.h1 {
    @include lib-heading($_heading_level: h1);

    letter-spacing: 7.2px;
    font-size: $h1__font-size-mobile;

    @include min-screen($screen__m) {
        letter-spacing: $h1__letter-spacing;
        font-weight: $font-weight__semibold;
        font-size: $h1__font-size;
    }

    &.h1-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.4rem;
        letter-spacing: 3.11px;

        @include min-screen($screen__m) {
            font-size: 2.2rem;
            letter-spacing: 7px;
        }
    }
}

h2,
.h2 {
    @include lib-heading($_heading_level: h2);

    letter-spacing: 5.33px;
    font-size: $h2__font-size-mobile;

    @include min-screen($screen__m) {
        letter-spacing: 7.47px;
        font-weight: $font-weight__light;
        font-size: $h2__font-size;
    }

    &.h2-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.4rem;
        letter-spacing: 3.11px;

        @include min-screen($screen__m) {
            font-size: 1.8rem;
            letter-spacing: 4px;
        }
    }
}

h3,
.h3 {
    @include lib-heading($_heading_level: h3);

    letter-spacing: 3.78px;
    font-size: $h3__font-size-mobile;

    @include min-screen($screen__m) {
        letter-spacing: 4px;
        font-size: $h3__font-size;
    }

    &.h3-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.3rem;
        letter-spacing: 3px;

        @include min-screen($screen__m) {
            font-size: 1.7rem;
            letter-spacing: 3.5px;
        }
    }
}

h4,
.h4 {
    @include lib-heading($_heading_level: h4);

    &.h4-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.3rem;
        letter-spacing: 3px;

        @include min-screen($screen__m) {
            font-size: 1.7rem;
            letter-spacing: 3.5px;
        }
    }
}

h5,
.h5 {
    @include lib-heading($_heading_level: h5);

    &.h5-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.3rem;
        letter-spacing: 3px;

        @include min-screen($screen__m) {
            font-size: 1.7rem;
            letter-spacing: 3.5px;
        }
    }
}

h6,
.h6 {
    @include lib-heading($_heading_level: h6);

    &.h6-sub,
    em {
        display: block;
        margin-top: $indent__xs;
        color: #666666;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $heading_sub__font-weight__base;
        font-style: normal;
        font-size: 1.3rem;
        letter-spacing: 3px;

        @include min-screen($screen__m) {
            font-size: 1.7rem;
            letter-spacing: 3.5px;
        }
    }
}

//
//  Typography
//  ---------------------------------------------

// sass-lint:disable-all
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    text-transform: uppercase;
    small {
        display: block;
        font-size: inherit;
        line-height: $line-height__xl;
    }
}

// sass-lint:enable-all

//
//  Paragraphs
//  ---------------------------------------------

.text-large {
    @include text-large;
}

p,
.text-normal {
    @include text-normal;
    letter-spacing: 1.5px;
}

.text-small {
    @include text-small;
}

.text-color-primary {
    @include text-color-primary;
}

//
//  Lists
//  ---------------------------------------------

.list-unstyled {
    @include list-unstyled;
}

.list-inline {
    @include list-inline;
}

.list-usps {
    @include list-usps;
}

.list-usps-large {
    @include list-usps-large;
}
