//
//  Override lib-typography
//  The font in the body (Nunito Light) is 300
//  Changing the default $_font-weight to 300
//  _____________________________________________

@mixin lib-typography(
  $_font-size: $font-size__base,
  $_color: $text__color,
  $_font-family: $font-family__base,
  $_font-weight: $font-weight__light,
  $_line-height: $line-height__base,
  $_font-style: $font-style__base
) {
  @include lib-css(color, $_color);
  @include lib-css(font-family, $_font-family);
  @include lib-css(font-style, $_font-style);
  @include lib-css(font-weight, $_font-weight);
  @include lib-css(line-height, $_line-height);
  @include lib-font-size($_font-size);
}

//
//  Base typography
//  ---------------------------------------------

@mixin lib-typography__base(
  $_abbr-border-color     : $abbr__border-color,
  $_dfn-font-style        : $font-style__emphasis,
  $_emphasis-font-style   : $font-style__emphasis,
  $_hr-border-color       : $hr__border-color,
  $_hr-border-style       : $hr__border-style,
  $_hr-border-width       : $hr__border-width,
  $_hr-margin-bottom      : $line-height__computed,
  $_hr-margin-top         : $line-height__computed,
  $_mark-background-color : $mark__background-color,
  $_mark-color            : $mark__color,
  $_p-margin-bottom       : $p__margin-bottom,
  $_p-margin-top          : $p__margin-top,
  $_root-font-size        : $root__font-size,
  $_small-font-size       : $font-size__s,
  $_strong-font-weight    : $font-weight__bold,
  $_sub-sup-font-size     : ($font-size__xs / $font-size__base) * 100%
) {
  html {
    @include lib-css(font-size, $_root-font-size);
    font-size-adjust: 100%;
  }

  body {
    @include lib-typography();
  }

  p {
    & {
      @include lib-css(margin-top, lib-font-size-value($_p-margin-top));
    }

    & {
      @include lib-css(margin-bottom, lib-font-size-value($_p-margin-bottom));
    }
  }

  //  Abbreviations and acronyms
  abbr[title] {
    @include lib-css(border-bottom, 1px dotted $_abbr-border-color);
    cursor: help;
  }

  b,
  strong {
    @include lib-css(font-weight, $_strong-font-weight);
  }

  em,
  i {
    @include lib-css(font-style, $_emphasis-font-style);
  }

  mark {
    @include lib-css(background, $_mark-background-color);
    @include lib-css(color, $_mark-color);
  }

  small,
  .small {
    @include lib-css(font-size, $_small-font-size);
  }

  hr {
    border: 0;
    @include lib-css(border-top, $_hr-border-width $_hr-border-style $_hr-border-color);
    @include lib-css(margin-bottom, $_hr-margin-bottom);
    @include lib-css(margin-top, $_hr-margin-top);
  }

  sub,
  sup {
    @include lib-css(font-size, $_sub-sup-font-size);
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  dfn {
    @include lib-css(font-style, $_dfn-font-style);
  }
}

//
//  Heading responsive
//  _____________________________________________

@mixin heading-responsive(
  $default: h3,
  $headings: ($screen__m: h2, $screen__l: h1)
) {
  @include lib-heading($_heading_level: $default);

  @each $breakpoint, $heading in $headings {
    @include min-screen($breakpoint) {
      @include lib-heading($_heading_level: $heading);
    }
  }
}

//
//  Font-size responsive
//  _____________________________________________

@mixin font-size-responsive(
  $default: $font-size__base,
  $sizes: ($screen__l: $font-size__l)
) {
  font-size: $default;

  @each $breakpoint, $size in $sizes {
    @include min-screen($breakpoint) {
      font-size: $size;
    }
  }
}

//
//  Text styles
//  _____________________________________________

@mixin text-large() {
  @include lib-typography(
          $_font-size: $font-size__l,
          $_font-weight: $font-weight__light
  );
}

@mixin text-normal() {
  @include lib-typography(
          $_font-weight: $font-weight__light
  );
}

@mixin text-small() {
  @include lib-typography(
          $_font-size: $font-size__s,
          $_line-height: $line-height__xl,
          $_font-weight: $font-weight__regular
  );
}

@mixin text-color-primary() {
  color: $color-primary;

  a,
  a:hover,
  a:visited,
  a:active {
    color: $color-primary;
  }
}

//
//  Lists
//  ---------------------------------------------

@mixin list-unstyled() {
  list-style: none;
  padding-left: 0;
}

@mixin list-inline(
  $padding: 0 $indent__base*1.5 0 0
) {
  @include list-unstyled;

  li {
    display: inline-block;
    padding: $padding;
  }
}

@mixin list-icon(
  $padding: 0 $indent__base 0 0,
  $icon: 'check',
  $icon-margin: 0 9px 0 0
) {
  @include list-unstyled;

  li {
    padding: $padding;

    &:before {
      @include icon(
              $icon: $icon
      );
      margin: $icon-margin
    }
  }
}


@mixin list-large-icon(
    $padding: 0 $indent__base 0 0,
    $icon-margin: 0 9px 0 0
) {
    @include list-unstyled;

    li {
        padding: $padding;
        font-size: 1.7rem;
        &:not(:last-child) {
            margin-bottom: $indent__base/2;
        }
        &:before {
            content: '—';
            margin: $icon-margin;
            color: $color-gray-light;
        }
    }
}

@mixin list-usps(
  $padding: 0 $indent__base 0 0,
  $icon: 'check',
  $icon-margin: 0 9px 0 0
) {
  @include list-icon(
          $padding: $padding,
          $icon: $icon,
          $icon-margin: $icon-margin
  );
}


@mixin list-usps-large(
    $padding: 0 $indent__base 0 0,
    $icon-margin: 0 9px 0 0
) {
    @include list-large-icon(
        $padding: $padding,
        $icon-margin: $icon-margin
    );
}

@mixin icon-usp-item(
  $icon: 'usp-blinds'
) {
  display: block;
  position: relative;
  padding: 0 0 0 $indent__xxl;
  text-decoration: none;

  &:before {
    @include icon(
            $icon: $icon
    );
    position: absolute;
    top: 0;
    left: 0;
  }

  > strong {
    display: block;
    font-size: 2rem;
    font-weight: $font-weight__semibold;
    color: $color-primary;
  }

  > span {
    display: block;
    font-size: 1.6rem;
    font-weight: $font-weight__regular;
    color: $color-dark-gray
  }
}

//
//  Unstyled link
//  ---------------------------------------------

@mixin unstyled-link() {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

//
//  Labels
//  ---------------------------------------------

@mixin label(
  $background-color: $color-red,
  $color: $color-white,
  $border: 2px solid $color-white,
  $font-size: 1.1rem
) {
  border-radius: 50%;
  background-color: $color-red;
  border: $border;

  span {
    font-size: $font-size;
    color: $color;
    width: 100%;
  }
}
