.page-products {
    .swatch-option-tooltip {
        display: none !important;
    }
}

.swatch-option {
    &.text {
        &,
        &:not(.disabled),
        &.disabled {
            font-size: 1.2rem;
            font-weight: $font-weight__light;
            letter-spacing: 1.5px;
            color: $color-black;
            background: #ececec !important;
        }

        &:not(.disabled) {
            font-size: 1.3rem;
            background: white !important;
        }
    }

    &.image,
    &.color {
        &,
        &:not(.disabled),
        &.disabled {
            width: 35px;
            height: 35px;
            border-radius: 35px;
            border-color: white;
            border-width: 3px;
            box-shadow: 0 0 0 1px $color-gray-darker;
            outline: none;
            box-sizing: border-box;
            margin: 5px;

            &:hover,
            &.active {
                box-shadow: 0 0 0 1px $color-gray-dark;
                outline: none;
                border-width: 3px;
            }
        }
    }

    &,
    &.text,
    &.image,
    &.color {
        &.disabled {
            pointer-events: none;
        }
    }
}

.filter-options {
    .swatch-option {
        &.text {
            &,
            &:not(.disabled),
            &.disabled {
                display: block;
                font-size: 1.3rem;
                font-weight: $font-weight__light;
                letter-spacing: 1.5px;
                color: $color-black;
                background: transparent;
                border: 0 !important;
                min-width: auto;
                max-width: 100%;
                height: auto;
                width: percentage(1/3);
                margin: 0 0 10px;
                padding: 2px 0 0 30px;
                position: relative;
                text-align: left;
                box-sizing: border-box;
                outline: 0 !important;

                &:before {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background: white;
                    border: 1px solid $color-gray-darker;
                    position: absolute;
                    left: 0;
                    top: 2px;
                }

                &:not(.disabled).active,
                &:hover {
                    &:before {
                        border-color: $color-gray-dark;
                    }

                    &:after {
                        @include icon('check-thicker');
                        position: absolute;
                        left: 3px;
                        top: 6px;
                    }
                }
            }
        }

        &.image,
        &.color {
            &,
            &:not(.disabled),
            &.disabled {
                margin: 7.5px 135px 7.5px 0;
                overflow: visible;
                background-size: cover !important;

                &:after {
                    content: attr(option-label);
                    position: absolute;
                    padding-left: 30px;
                    text-align: left;
                    top: 4px;
                    white-space: nowrap;
                }

                &.active {
                    &:before {
                        @include icon('check-thicker-white');
                        position: relative;
                        top: 4px;
                    }

                    &[option-tooltip-value="#ffffff"] {
                        &:before {
                            @include icon('check-thicker');
                        }
                    }
                }
            }
        }

        &,
        &.text {
            &.disabled {
                &:after {
                    content: '';
                    background:
                        linear-gradient(to top left,
                            rgba(0,0,0,0) 0%,
                            rgba(0,0,0,0)
                            calc(50% - 0.8px),
                            rgba(0,0,0,1) 50%,
                            rgba(0,0,0,0) calc(50% + 0.8px),
                            rgba(0,0,0,0) 100%),
                        linear-gradient(to top right,
                            rgba(0,0,0,0) 0%,
                            rgba(0,0,0,0) calc(50% - 0.8px),
                            rgba(0,0,0,1) 50%,
                            rgba(0,0,0,0)
                            calc(50% + 0.8px),
                            rgba(0,0,0,0) 100%);
                    top: 3px;
                    left: 1px;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        &.image,
        &.color {
            &.disabled {
                &:before {
                    content: '';
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2'%3E%3Ccircle id='Oval' fill='%23000000' cx='7' cy='7' r='7'%3E%3C/circle%3E%3Cg id='check-green' transform='translate(4.000000, 4.000000)' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='0 3 2.3478 6 6 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-size: cover;
                    top: 6px;
                    left: 6px;
                    width: 17px;
                    height: 17px;
                    opacity: 1;
                    display: block;
                    position: absolute;
                }

                &[option-tooltip-value="#000000"]:before,
                &[option-tooltip-value="#17417b"]:before {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='7' cy='7' r='7'%3E%3C/circle%3E%3Cg id='check-green' transform='translate(4.000000, 4.000000)' stroke='%23000000'%3E%3Cpolyline id='Path' points='0 3 2.3478 6 6 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }

                &:after {
                    background: none;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }
            }
        }
    }
}
