@mixin landingpage-subtitle {
    font-style: normal;
    font-family: $heading_sub__font-family-name__base;
    font-weight: $font-weight__light;
    color: $color-gray-dark;
    text-transform: uppercase;
}

@mixin landingpage-button-after {
    text-decoration: none;
    color: white;

    &::after {
        content: '';
        width: 25px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        transition: background-color 250ms ease-in-out;
        background-color: white;
    }

    &:focus {
        text-decoration: none;
    }

    &:hover {
        background-color: white;
        color: $color-black !important;

        &::after {
            background-color: $color-black;
        }

    }
}

@mixin landingpage-content-visibility($transition-delay, $transform-position) {
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    transition-delay: $transition-delay;
    transform: $transform-position scale(1.15);
    opacity: 0;
    pointer-events: none;
}
