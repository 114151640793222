$product-stock-indicator-size: 10px;

[data-indicator-state=""] {
    [data-visible-in-state] {
        display: none;
    }
}

[data-indicator-state="in-stock"] {
    [data-visible-in-state]:not([data-visible-in-state="in-stock"]) {
        display: none;
    }
}

[data-indicator-state="low-stock"] {
    [data-visible-in-state]:not([data-visible-in-state="low-stock"]) {
        display: none;
    }
}

[data-indicator-state="no-stock"] {
    [data-visible-in-state]:not([data-visible-in-state="no-stock"]) {
        display: none;
    }
}

.product-stock-indicator {
    @include lib-clearfix();
    display: none;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.5px;
    margin-top: -5px !important;
    text-align: left;
    width: 100%;
    justify-content: center;

    &.is-visible {
        display: flex;
    }

    &.product-stock-indicator--left {
        display: flex !important;
        justify-content: flex-start;
    }
}

.product-stock-indicator__status {
    align-items: baseline;
    display: flex;
    white-space: normal;

    &:before {
        border-radius: $product-stock-indicator-size;
        content: '';
        display: block;
        flex: 0 0 auto;
        height: $product-stock-indicator-size;
        margin-right: 12px;
        width: $product-stock-indicator-size;

        @include max-screen($screen__m) {
            margin-right: 6px;
        }
    }

    &[data-indicator-state="in-stock"],
    [data-indicator-state="in-stock"] & {
        &:before {
            background-color: $color-green;
        }
    }

    &[data-indicator-state="low-stock"],
    [data-indicator-state="low-stock"] & {
        &:before {
            background-color: $color-orange;
        }
    }

    &[data-indicator-state="no-stock"],
    [data-indicator-state="no-stock"] & {
        &:before {
            background-color: $color-red;
        }
    }
}

.product-stock-indicator-dropdown {
    @include lib-dropdown(
        $_toggle-selector: '.product-stock-indicator__dropdown-trigger',
        $_options-selector: '.product-stock-indicator-dropdown__dropdown-content',
        $_dropdown-list-border: 1px solid $border-color__secondary,
        $_dropdown-list-margin-top: 6px,
        $_dropdown-list-pointer: false,
        $_dropdown-list-pointer-border: $border-color__secondary,
        $_dropdown-list-item-padding: 0,
        $_dropdown-toggle-icon-content: $icon-down,
        $_dropdown-toggle-active-icon-content: $icon-up,
        $_dropdown-actions-padding: 0 21px,
        $_icon-font-text-hide: true,
        $_icon-font-size: 22px,
        $_icon-font-line-height: 22px
    );
    display: block;

    .product-stock-indicator-dropdown__dropdown-content {
        padding: 6px 27px 6px;
        white-space: nowrap;
        width: auto;
    }
}

.product-stock-indicator__dropdown-trigger {
    cursor: pointer;

    &[data-indicator-count="0"] {
        cursor: default;
    }
}

.product-stock-indicator__store-count-text {
    text-decoration: underline;
}

.product-stock-indicator__store-list {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    margin-bottom: 10px;
}

.product-stock-indicator__store-list-header-row {
    font-weight: $font-weight__bold;
    margin: 18px 0;
    width: 100%;
}

.product-stock-indicator__store-list-footer-row {
    width: 100%;
    font-size: 1.2rem;
    margin: 30px 0 10px 0;
    padding-top: 18px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid $color-gray-light01;

    .product-stock-indicator__status {
        margin-right: 20px;
        margin-bottom: 10px;
        font-size: 1.2rem;
        font-weight: normal;
    }
}

.product-stock-indicator__store-list-row {
    box-sizing: border-box;
    display: flex;
    margin: 6px 0;
    padding-right: 9px;
    width: 50%;
}

.product-stock-indicator__store-list-row--no-stock {
    display: none;
}

.product-stock-indicator__store-list-column {
    flex: 1 1 auto;
    min-width: 150px;
}

.product-stock-indicator__store-list-column--store {
    float: left;
}

@include max-screen($screen__m) {
    .product-stock-indicator {
        margin-top: 12px;

        &.is-visible {
            flex-wrap: wrap;
        }
    }

    .product-stock-indicator__column:first-child {
        margin-right: 8px;
    }

    .product-stock-indicator__column-label {
        margin: 9px 0;
        font-size: 1.1rem;
    }

    .product-stock-indicator-dropdown__dropdown-content {
        left: 0;
        right: 0;
    }

    .product-stock-indicator__store-list-row {
        margin: 9px 0;
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .product-stock-indicator__column {
        float: left;
        margin: 6px 18px 6px 0;

        &:last-of-type {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
}

.product-stock-notification  {
    @include max-screen($screen__m) {
        .wk-container {
            margin-top: 0 !important;
            padding: 10px !important;
        }
    }
}
