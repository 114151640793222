$header-offset: 35px;
$header-offset-desktop: $header-height-desktop-sticky;

.offscreen-nav {
    display: block;
    position: fixed;
    top: 35px;
    left: -120vw;
    right: auto;
    bottom: auto;
    height: calc(100vh - #{$header-offset});
    width: 100%;
    z-index: 99;
    overflow-y: auto;
    color: $color-white;
    transition: .25s ease-in-out;
    transition-property: left;
    text-align: center;
    box-sizing: border-box;
    padding-right: 10%;

    .offscreen-nav-shadow {
        width: 10%;
        height: 100%;
        background: rgba(black, 0.2);
        display: block;
        position: fixed;
        right: 120vw;
        top: 35px;
        bottom: 0;
        z-index: 9999;
        transition: .25s ease-in-out;
    }

    @include min-screen($screen__xl) {
        height: calc(100vh - #{$header-offset-desktop});
        top: -120vh;
        left: 0;
        right: 0;
        transition-property: top;
    }

    .is-header-sticky.offscreen-nav-open & {
        top: 35px;

        @include min-screen($screen__md) {
            top: 35px;
        }
    }

    .is-header-sticky.header-is-pinned.offscreen-nav-open & {
        top: 35px;

        @include max-screen($screen__md) {
            top: 35px;
        }
    }

    .header-is-pinned.offscreen-nav-open & {
        top: 35px;
    }

    &__inner {
        margin: 0;
        padding: 65px $indent__base*2 0;
        background: $color-primary;

        @include max-screen($screen__xl) {
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 35px);
        }

        @include min-screen($screen__xl) {
            margin: $indent__xxl auto $indent__xl;
            width: 100%;
            max-width: 1400px;
            position: relative;
        }
    }

    &__top {
        font-family: $heading__font-family__base;
        font-size: $h2__font-size;

        a {
            display: block;
            font-size: 2.4rem;
        }

        > ul > li > a {
            min-width: 250px;
        }

        // 2nd depth
        > ul > li > ul {
            &,
            li > ul {
                display: none;
            }

            @include min-screen($screen__xl) {
                position: absolute;
                top: 0;
                left: 240px;
                padding-left: $indent__xl;
                padding-bottom: $indent__xl;

                // 3rd depth
                li > ul {
                    position: absolute;
                    min-height: 100%;
                    top: 0;
                    left: 100%;
                    padding-left: $indent__xl;
                    padding-bottom: $indent__xl;
                    white-space: nowrap;
                }

            }
        }

        li {
            margin-bottom: 0;

            &.has-dropdown {
                li {
                    font-size: 2rem;

                    @include max-screen($screen__xl) {
                        font-size: 1.8rem;

                        &.has-dropdown {
                            padding-left: $indent__s;

                            > span a,
                            > a {
                                font-size: 2.2rem;
                            }
                        }
                    }
                }

                &.is-active {
                    ul {
                        display: block;
                    }
                }
            }

            @include min-screen($screen__xl) {
                &.has-dropdown {
                    &:hover {
                        > a {
                            color: rgba($color-white, 0.8);
                        }

                        > ul {
                            display: block;
                        }
                    }
                }
            }
        }

        .widget.block {
            margin-bottom: 0;
        }
    }

    &__middle {
        font-family: $heading__font-family__base;
        font-size: $h4__font-size;
        margin: $indent__m 0;

        li {
            margin-bottom: 0.5rem;
        }

        @include min-screen($screen__xl) {
            font-size: $h3__font-size;
            margin: $indent__xl 0 $indent__xxl;

            li {
                margin-bottom: 1rem;
            }
        }
    }

    &__top,
    &__middle {
        text-transform: uppercase;

        a {
            &:hover {
                color: rgba($color-white, 0.8);
            }
        }
    }

    &__bottom {
        @extend %phone-links-white;

        @include max-screen($screen__xl) {
            margin: 50px 0 0 0;

            a[href^="tel:"] {
                display: flex;
                font-size: 1.6rem;

                &:before {
                    top: auto;
                }
            }
        }

        @include min-screen($screen__xl) {
            font-size: 1.7rem;
        }
    }

    &__bottom-actions {
        > div {
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        a {
            font-weight: $font-weight__heavier;
        }

        .header-customer-support-container {
            margin-bottom: 15px;
        }
    }

    &__account-link {
        display: flex;

        &::before {
            @include icon('user-white');
            margin-right: 20px;
            position: relative;
            top: 10px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        color: $color-white;
        text-decoration: none;

        &:not(.offscreen-nav__account-link):not(.offscreen-nav__logout-link) {
            padding: 10px;
            border-bottom: 1px solid rgba($color-white, 0.19);
        }
    }

    .nav-toggle {
        top: 10px !important;
        left: 10px !important;
        padding: 10px !important;
    }

    .nav-toggle * {
        color: white !important;
    }

    .nav-toggle:before {
        display: none;
    }

    .nav-toggle .nav-toggle__icon span {
        background: white !important;
    }

    .nav-toggle .nav-toggle__label {
        height: auto;
        width: auto;
        margin: 0;
        padding-left: 36px;
        font-weight: 300;
        font-size: 1.4rem;
        clip: auto;

        &:nth-child(even) {
            display: none;
        }

        &:last-child {
            display: block;
        }
    }

    .offscreen-nav__account .offscreen-nav__container {
        a:only-child {
            align-items: baseline;
        }
    }

    .offscreen-nav__account {
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offscreen-nav__account-link {
        font-size: 1.3rem;
        width: 100%;
    }

    &__logout-link {
        font-size: 1.3rem;
        width: 100%;
        padding-left: 40px;

        &:hover {
            text-decoration: underline;
        }
    }

    .switcher {
        @include lib-dropdown;
        text-transform: uppercase;
        max-width: 320px;
        margin: 0 auto;

        .label {
            display: none;
        }

        .dropdown {
            min-width: 200px;
            font-size: 1.3rem;

            &.active {
                &,
                .dropdown {
                    display: block;
                }
            }
        }

        .options .action.toggle {

            &, &.active {
                &::after {
                    @include icon('chevron-down-white');
                }
            }
        }

        ul.dropdown {
            top: auto;
            bottom: 32px;
            background: $color-gray-dark;
            padding: 5px;

            li {
                padding: 0;

                &:hover {
                    a {
                        color: $color-primary;
                    }
                }

                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }

                a {
                    display: block;
                    padding: 20px 10px;
                    text-align: left;

                    .switcher-option__flag {
                        display: inline-block;
                        margin-right: 10px;
                        border-radius: 50%;
                        overflow: hidden;
                        position: relative;
                        top: 3px;
                    }
                }
            }

            &::before {
                transform: rotate(180deg);
                left: 50%;
                margin-left: -6px;
                right: auto;
                top: auto;
                bottom: -12px;
                border-color: transparent transparent $color-gray-dark transparent;
            }

            &::after {
                transform: rotate(180deg);
                left: 50%;
                margin-left: -7px;
                right: auto;
                top: auto;
                bottom: -12px;
                border-color: transparent transparent $color-gray-dark transparent;
                z-index: 99;
            }
        }
    }

    li.has-dropdown {
        > span {
            display: block;
        }
        > span a,
        > a {
            align-items: center;
            display: flex;
            justify-content: center;

            &::after {
                @include icon('chevron-down-white');
                margin-left: 9px;
            }
        }

        ul {
            a {
                border-bottom: none;
                font-size: 15px;
                line-height: 1;
            }
        }

        .block-static-block {
            display: none;
            margin: 0 -20px;
            text-align: left;
        }

        [data-content-type="column"] {
            margin-top: 20px !important;
        }

        [data-content-type="heading"] {
            color: $color-white;
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 3px;
            margin: 0 0 18px 10px;
            position: relative;
        }

        [data-content-type="image"] {
            bottom: -20px;
            position: relative;

            [data-element="link"] {
                border-bottom: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 0;

                &:hover {
                    text-decoration: none;
                }

                &:after {
                    display: none;
                }
            }

            img {
                align-self: flex-end;
            }
        }

        [data-element="caption"] {
            @include btn;
            position: absolute;
            bottom: 0;
            margin: 20px;
            right: 0;
        }
    }

    li.has-dropdown.is-active {
        .block-static-block {
            display: block;
        }
    }
}

.offscreen-nav.has-collapsed-submenu {
    .offscreen-nav__inner {
        // Prevent positioning the iOS menu bar on top of the mobile navigation links
        // for more details @see https://benfrain.com/the-ios-safari-menu-bar-is-hostile-to-web-apps-discuss/
        @supports (-webkit-touch-callout: none) {
            margin-bottom: 150px;
        }
    }
}

body.offscreen-nav-open {
    overflow: hidden;

    .offscreen-nav {
        left: 0;

        .offscreen-nav-shadow {
            right: 0;
        }

        @include min-screen($screen__xl) {
            top: $header-offset-desktop;
        }
    }
}
