.bestsellers-slider {
    display: none;

    .slick-track {
        display: flex;
    }

    &:not(.slick-initialized.slick-slider) {
        display: flex;
        flex-flow: row wrap;
        flex-direction: row !important;
    }

    // Avoid large tiles before loading slider
    [data-content-type="text"] {
        width: calc(25% - 40px);
    }

    &.slick-initialized {
        display: block;
    }

    .slick-dots {
        display: none;
    }

    &.slick-slider {
        .slick-arrow {
            top: 35%;
        }
    }

    .slick-list {
        margin: 0 -#{$indent__base};

        @include max-screen($screen__xs) {
            margin: 0 -#{$indent__s};
        }
    }

    div[data-content-type="text"] {
        text-align: center;
        padding: $indent__base $indent__base 70px !important;
        position: relative;

        @include max-screen($screen__xs) {
            padding: $indent__s $indent__s 70px !important;
        }

        @include min-screen($screen__m) {
            &:hover img {
                transform: scale(1.1);
            }
        }

        img {
            height: auto !important;
            width: auto !important;
            transition: transform 300ms ease-in-out;

            @include screen($screen__xl, 1920px) {
                width: 100% !important;
            }
        }

        h3 {
            margin-top: $indent__m;
            margin-bottom: $indent__s;
        }

        a {
            text-decoration: none;

            @include min-screen($screen__m) {
                position: relative;
                overflow: hidden;
                display: block;
            }
        }

        p {
            &:last-child {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                a {
                    @include btn-outlined-gray;
                    margin-top: $indent__s;

                    @include max-screen($screen__xs) {
                        padding: 12px 25px 12px 15px;
                    }
                }
            }
        }

        @include max-screen($screen__m) {
            h3 + p {
                display: none;
            }
        }
    }
}
