//
//  Responsive variables
//  _____________________________________________

$screen__md: 768px;
$screen__xl  : 1280px;
$screen__xxl  : 1440px;
$screen__fhd : 1600px;
$screen__max-tablet : 769px;

$screen_fhdplus : 1921px;
