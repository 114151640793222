//
//  Layout
//  ---------------------------------------------

[data-role="main-css-loader"] {
    display: none;
}

body {
    overflow-x: hidden;
}

.page-main {
    @include min-screen($screen__xl) {
        width: 100%;
        max-width: $layout__max-width;
        margin-bottom: 40px;
     }

    .cms-home & {
        margin-top: 46px;
    }

    .contact-index-index & {
        margin-top: 56px;
    }

    .checkout-onepage-success & {
        margin-top: 56px;
    }
}

@include max-screen($screen__m) {
    .amlocator-location-view .page-main,
    .amlocator-index-index .page-main,
    .cms-retourneren .page-main {
        margin-top: 56px;
    }
}

body[class^="customer-account"] .page-main,
body.account .page-main {
    margin-top: 60px;
}

@media screen and (min-width: 768px) {
    body[class^="customer-account"] .page-main,
    body.account .page-main {
        margin-top: 20px;
        min-height: 70vh;
    }
}

.page-wrapper > .page-bottom {
    box-sizing: border-box;
    width: 100%;
}

@include min-screen($screen__m) {
    .page-main {
        margin-top: 0;

        .cms-home & {
            margin-top: 0;
        }
    }
}
