.product-stock-notification {
    &:not(.product-stock-notification--simple) {
        display: none;
    }

    .wk-container {
        background-color: $color-gray;
        padding: 30px 20px 0;
        width: 100%;
        box-sizing: border-box;
        max-width: 100%;
        margin: -50px 0 0 0;

        @include min-screen($screen__l) {
            padding: 30px 30px 0;
        }
    }

    #associate-select { // sass-lint:disable-line no-ids
        display: none;
    }

    input[type="text"] {
        height: 48px;
        background: transparent;
        font-size: 1.4rem;
        width: 100%;
        margin-bottom: $indent__s;

        &::placeholder {
            color: $color-black;
        }
    }

    .message {
        display: none;
    }

    button:not(.btn-icon):not(.btn-dark-icon):not(.btn-outline-icon):not(.btn-link):not(.slick-arrow) {
        &.action.primary.tocart {
            @include btn-dark;

            &:after {
                background-color: $color-white;
            }

            &:focus {
                color: $color-black;
            }

            &:focus,
            &:hover {
                &:after {
                    background-color: $color-black !important;
                }
            }
        }
    }

    .wk-oosn-emailBox,
    #wk-oosn-button { // sass-lint:disable-line no-ids
        margin-bottom: $indent__xs;
        width: 100%;
        max-width: none;
    }

    .wk-oosn-emailBox__label {
        font-size: 12px;
        font-weight: bold;
    }

    #wk-oosn-button { // sass-lint:disable-line no-ids
        float: none;
        display: inline-block;
    }

    .wk-oosn-notificationcontainer {
        margin-top: 0;
        display: block;
    }

    .wk-oosn-container {
        @include max-screen($screen__m) {
            text-align: left;
        }
    }
}

.product-stock-notification__text {
    margin-top: 1.5rem;

    p {
        font-size: 1.3rem;
    }
}
