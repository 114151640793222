$screen__iphone5: 374px;

.hero-banner {
    position: relative;

    &.is-loading {
        .hero-banner__inner {
            display: none;
        }
    }

    &:after {
        @include icon('chevron-down-white');
        position: absolute;
        bottom: 0px;
        left: 20px;
        margin-bottom: 20px;
        transform: scale(2);
        transform-origin: left;
        animation: move-up-down 1s linear infinite;

        @include min-screen($screen__m) {
            display: none;
        }
    }

    &.hero-banner--full-items {
        &:after {
            display: none;
        }
    }

    @include max-screen($screen__m) {
        margin-bottom: 92px;
    }

    h1.hero-banner__title,
    .hero-banner__links a {
        transition: 0.2s color text-shadow linear;
        text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
    }

    .hero-banner__links ul {
        margin-bottom: 2rem;
    }

    &__media-wrapper {
        width: 100%;
        height: calc(100vh - 175px);
        min-height: 420px;
        display: flex;

        &[data-default="0"] {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
        }

        @include min-screen($screen__m) {
            height: calc(100vh - 260px) !important;
            min-height: 700px;
        }

        // iPhone 5 fix
        @include max-screen($screen__iphone5) {
            min-height: 380px;
        }
    }

    &__video {
        @include object-fit(cover);
        object-position: center;
        width: 100%;
    }

    &__image {
        @include object-fit(cover);
        object-position: bottom;
        width: 100%;
    }

    &__inner {
        @include layout-inner-small;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-top: 70px;

        @include min-screen($screen__m) {
            margin-top: 130px;
        }

        // iPhone 5 fix
        @include max-screen($screen__iphone5) {
            margin-top: 40px;
        }
    }

    &__title {
        display: none;
        margin-bottom: $indent__xl;
        max-width: 506px; // fix for IE11
        font-size: 7rem;
        line-height: 1;

        @include min-screen($screen__m) {
            display: block;
        }

    }

    &__links {
        ul {
            padding: 0;
            list-style: none;

            @include min-screen($screen__m) {
                display: flex;
                flex-wrap: wrap;
                max-width: 700px;
            }
        }

        li {
            margin-bottom: 0;

            @include min-screen($screen__m) {
                flex: 1 1 50%;
                width: 50%;
            }
        }

        a {
            font-family: $heading__font-family__base;
            font-weight: $font-weight__heavier;
            font-size: 3rem;
            text-decoration: none;
            text-transform: uppercase;
            display: block;

            @include min-screen($screen__m) {
                font-size: 3.5rem;
            }

            // iPhone 5 fix
            @include max-screen($screen__iphone5) {
                font-size: 2.4rem;
            }

            &:after {
                @include icon(
                    $icon: 'large-chevron-right-black'
                );
                position: relative;
                top: 7px;
            }

            &:hover {
                &:after {
                    animation: shake-right 0.3s linear both;
                }
            }
        }
    }

    &__buttons {
        display: flex;

        @include max-screen($screen__m) {
            display: flex;
            position: absolute;
            bottom: -82px;
            left: 0;
            right: 0;
            margin: 20px;
            width: calc(100% - 40px);
            text-align: center;
        }

        @include min-screen($screen__m) {
            display: block;
        }
    }

    &__button {
        @include btn;
        @include btn-icon;

        @include max-screen($screen__m) {
            flex: 1;
        }

        // iPhone 5 fix
        @include max-screen($screen__iphone5) {
            &:after {
                display: none !important;
            }
        }

        &:visited {
            color: $color-white;
        }

        &:hover,
        &.hero-banner__button-secondary {
            background: $color-white;
            color: $color-black;
            border-color: $color-black;

            @include min-screen($screen__m) {
                border-color: $color-white;
            }
        }

        &.hero-banner__button-secondary {
            margin-left: $indent__base;

            @include btn-icon(
                $icon: 'chevron-right-black',
                $icon-hover: 'chevron-right-white'
            );

            &:hover {
                background: $color-black;
                color: $color-white;
                border-color: $color-white;

                @include min-screen($screen__m) {
                    border-color: $color-black;
                }
            }
        }
    }
}

.hero-banner--text-color-white {
    h1.hero-banner__title,
    .hero-banner__links a {
        color: $color-white;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    .hero-banner__links a {
        &:after {
            @include icon(
                $icon: 'large-chevron-right-white'
            );
        }
    }
}
