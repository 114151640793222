@mixin form-reset() {
    .fieldset {
        margin-bottom: 0;

        > .field:not(.choice) {
            > .label {
                width: 100%;
                text-align: left;
                padding: 0;
                font-size: 1.5rem;

                span {
                    @include text-normal;
                    letter-spacing: 1.5px;
                }
            }

            > .control {
                width: 100%;
                @include min-screen($screen__l) {
                    width: 50%;
                }
            }
        }

        > .field {
            margin: 0 0 15px;

            &.choice,
            &.no-label {
                &:before {
                    display: none;
                }
            }

            &.choice {
                .label {
                    font-size: 1.5rem;
                    font-weight: $font-weight__light;
                }
            }
        }
    }

    .actions-toolbar {
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }
}
