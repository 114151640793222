/*
.text-center
.text-left
.text-right

.desktop-text-center
.desktop-text-left
.desktop-text-right

.mobile-text-center
.mobile-text-left
.mobile-text-right
*/
$alignments: (left, right, center);

@each $alignment in $alignments {

    .text-#{$alignment} {
        text-align: $alignment;
    }

    @include min-screen($screen__m) {
        .desktop-text-#{$alignment} {
            text-align: $alignment;
        }
    }

    @include max-screen($screen__m) {
        .mobile-text-#{$alignment} {
            text-align: $alignment;
        }
    }
}
