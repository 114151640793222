// SCSS conversion of /vendor/aheadworks/module-ajaxcartpro/view/frontend/web/css/source/_module.less

//
//  Variables
//  _____________________________________________
$aw-acp-mfp__background-color: #0b0b0b;
$aw-acp-mfp-container__background-color: #fff;
$aw-acp-mfp-preloader__color: #ccc;
$aw-acp-price__color: #009900;
$aw-acp-mobile-close__color: #1979C3;
$aw-acp-message-god__color: #27ae60;
$aw-acp-message-bad__color: #e74c3c;
$aw-acp-message-info__color: #2c3e50;
$aw-acp-message-god__background-color: #e8fff2;
$aw-acp-message-bad__background-color: #ffedeb;
$aw-acp-message-info__background-color: #fffae6;
$aw-acp-bar-gradient-top__color: #54f054;
$aw-acp-bar-gradient-bottom__color: #2bc253;
$aw-acp-mobile-header__border-color: #000;
//
//  Common
//  _____________________________________________

  .aw-acp-popup-container {
    .aw-acp-popup__image-wrapper {
        text-align: center !important;
        //@include min-screen($screen__m + 1) {
        //    .product-image-container {
        //        max-width: 180px;
        //        margin-top: 20px !important;
        //    }
        //}
    }


    &.mfp-bg {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 105;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: $aw-acp-mfp__background-color;
      opacity: 0.8;
      transition: all 0.3s ease-out;
    }

    &.mfp-wrap {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 106;
      width: 100%;
      height: 100%;
      outline: none !important;
      -webkit-backface-visibility: hidden;
    }

    .mfp-container {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: 0 8px;
      width: 100%;
      height: 100%;
      text-align: center;

      &::before {
        display: inline-block;
        height: 100%;
        content: "";
        vertical-align: middle;
      }
    }

    &.mfp-align-top {
      .mfp-container {
        &::before {
          display: none;
        }
      }
    }

    .mfp-content {
      position: relative;
      z-index: 106;
      display: inline-block;
      margin: 0 auto;
      vertical-align: middle;
      text-align: left;
    }

    .mfp-ajax-holder, .mfp-inline-holder {
      .mfp-content {
        width: 100%;
        cursor: auto;
      }
    }

    &.mfp-ajax-cur {
      cursor: progress;
    }

    &.mfp-auto-cursor {
      .mfp-content {
        cursor: auto;
      }
    }

    .mfp-preloader {
      user-select: none;
      position: absolute;
      top: 50%;
      right: 8px;
      left: 8px;
      z-index: 105;
      margin-top: -0.8em;
      width: auto;
      color: $aw-acp-mfp-preloader__color;
      text-align: center;

      a {
        color: $aw-acp-mfp-preloader__color;
        &:hover {
          color: $aw-acp-mfp-container__background-color;
        }
      }
    }

    .mfp-s-ready {
      .mfp-preloader {
        display: none;
      }
    }

    .mfp-s-error {
      .mfp-content {
        display: none;
      }
    }

    .aw-acp-popup-with-anim {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &--zoom-in {
      &.mfp-ready {
        .aw-acp-popup-with-anim {
          opacity: 1;
        }
      }

      &.mfp-removing {
        .aw-acp-popup-with-anim {
          opacity: 0;
        }

        &.mfp-bg {
          opacity:0;
        }
      }
    }
  }

  .aw-acp-popup {
    position: relative;
    margin: 20px auto;
    padding: 15px;
    max-width: 640px;
    background: $aw-acp-mfp-container__background-color;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    .swatch-option {
      box-sizing: content-box;
    }

    &__title,
    &__description,
    &__options-wrapper {
      margin: 0 0 20px;
    }

    &__mobile-title {
      overflow: hidden;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    &__form {
      .fieldset {
        > .field {
          &:not(.choice) {
            > .label, > .control {
              width: auto;
              float: none;
            }

            > .label {
              text-align: left;
              box-sizing: content-box;
              padding: 0;
              margin: 0 0 5px;
            }
          }
        }
      }
    }

    &__options-wrapper {
      .fieldset {
        margin-left: 2px;

        .legend {
          margin: 0 0 20px;
        }
      }
    }

    &__box-tocart-wrapper {
     display: flex;
      -webkit-box-align: flex-end;
    }

    &__box-qty {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      margin: 0;
    }

    &__box-price {
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      text-align: center;

      .price-container {
        .price {
          font-size: 24px;
          color: $aw-acp-price__color;
        }
      }
    }

    &__mobile-close-wrapper {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-left: 15px;
      line-height: 10px;

      &.headless {
        margin-left: 0;
        width: 100%;
      }

      .aw-acp-popup__mobile-close {
        &.action.primary {
          border: none;
          background: none;
          color: $aw-acp-mobile-close__color;
          width: 100%;
        }
      }
    }

    &__message {
      margin: 0 0 20px;
      padding: 13px 20px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;

      &--good {
        background: $aw-acp-message-god__background-color;
        color: $aw-acp-message-god__color;
      }

      &--bad {
        background: $aw-acp-message-bad__background-color;
        color: $aw-acp-message-bad__color;
      }

      &--info {
        background: $aw-acp-message-info__background-color;
        color: $aw-acp-message-info__color;
      }
    }

    &__main {
      margin-bottom: 20px;
    }

    &__loader {
      display: flex;
      -webkit-box-align: center;
      padding: 0 40px;
      height: 300px;

      &-bar {
        position: relative;
        display: block;
        overflow: hidden;
        margin: auto;
        max-width: 300px;
        width: 100%;
        height: 20px;
        border-radius: 10px;
        background: $aw-acp-bar-gradient-bottom__color -webkit-linear-gradient(top,$aw-acp-bar-gradient-top__color,$aw-acp-bar-gradient-bottom__color);
        background: $aw-acp-bar-gradient-bottom__color linear-gradient(180deg,$aw-acp-bar-gradient-top__color,$aw-acp-bar-gradient-bottom__color);
        box-shadow: inset 0 2px 9px hsla(0,0%,100%,.3),inset 0 -2px 6px rgba(0,0,0,.4);

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 101;
          overflow: hidden;
          border-radius: 10px;
          background-image: -webkit-linear-gradient(135deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
          background-image: linear-gradient(-45deg,hsla(0,0%,100%,.2) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.2) 0,hsla(0,0%,100%,.2) 75%,transparent 0,transparent);
          background-size: 50px 50px;
          content: "";
          -webkit-animation: a 2s linear infinite;
          animation: a 2s linear infinite;
        }
      }
    }

    &__column--media {
      display: flex;
      flex-direction: column;
      -webkit-justify-content:flex-start;
      justify-content: flex-start;
    }
    &__reviews-wrapper,
    &__image-wrapper {
      margin: 0;
      text-align: center;
    }


    &__image-wrapper {
    }
    &__reviews-wrapper {
      height: 20px;
      flex: 1 1 auto;

      .product-reviews-summary {
        display: block;

        .rating-summary, .reviews-actions {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &__image-wrapper {
      flex: 0 0 auto;
    }

    &__image {
      max-width:100%;
      max-height:300px;
      height:auto;
    }

    &__actions {
      -webkit-box-align: center;
    }

    &__close-wrapper {
      text-align:center;
    }

    &__columns {
      width: auto !important;

      .price-box {
        margin: 0;
      }
    }

    &__submit,
    &__close {
      font-size: 18px!important;
    }

    &__submit {
      width: 100%;
      height: 50px;
    }

    &__result {
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      text-align: center;

      &-title {
        margin: 0 0 10px;
        font-size: 18px;
      }

      &-price {
        margin: 0 0 10px;
        font-size: 24px;
      }

      &-items {
        margin: 0 0 10px;
      }

      &-view-cart {
        margin: 0;
      }

      /* Related */

      &__related {
        border-top: 1px solid $aw-acp-mfp-preloader__color;
        margin-top: 25px;
        margin-bottom: 0;

        .title {
          margin-top: 10px;
        }

        .products {
          margin: 0;

          .product-item-info {
            margin: -10px;
            padding: 9px;
          }
        }
      }

    }


  }

  .aw-acp-popup__related {
    margin-top: 25px;

    .products-grid {
      .product-item {
        box-sizing: content-box;
      }
    }
  }

  @-webkit-keyframes a {
    0% {
      background-position:0 0;
    }
    to {
      background-position:50px 50px;
    }
  }
  @keyframes a {
    0% {
      background-position:0 0;
    }
    to {
      background-position:50px 50px;
    }
  }

  .mfp-hide {
    display: none!important;
  }

  /* Controls */
  .input-text.qty {
    width: 54px;
    text-align: center;
  }

  .field.qty .label {
    font-weight: 600;
    margin: 8px 0;
    display: block;
  }

  @media (min-width:580px) {
    .aw-acp-popup-container {
      .aw-acp-popup-with-anim {
        transform: scale(0.8);
      }

      &--zoom-in {
        &.mfp-ready {
          .aw-acp-popup-with-anim {
            transform: scale(1);
          }
        }
        &.mfp-removing {
          .aw-acp-popup-with-anim {
            transform: scale(0.8);
          }
        }
      }
    }

    .aw-acp-popup {
      &__mobile-header {
        display: none;
      }

      &__options-wrapper {
        max-height: 700px;
        overflow-y: auto;
        padding: 0 5px 0 0;
      }

      &__columns {
        display: flex;
      }

      &__column {
        &:first-child {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          width: 250px;
        }

        &:nth-child(2) {
          -webkit-box-flex: 1;
          -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
          flex: 1 1 auto;
          margin-left: 20px;
        }
      }
    }
  }

  @media (min-width:640px) {
    .products-grid {
      &.products-acp-related {
        .product-item {
          width: 24% !important;

          &:nth-child(n+5) {
            display: none;
          }
        }
      }
    }

    .aw-acp-popup__related {
      .products-grid {
        .product-item-info {
          height: 100%;
          padding-bottom: 35px;
          position: relative;

          .actions-primary {
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
          }

          &:hover,
          .active {
            box-sizing: content-box;

            .actions-primary {
              bottom: 9px;
              left: 9px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .aw-acp-popup__related {
      .products-grid {
        &.products-acp-related {
          .product-item {
            width: 23% !important;
            margin-left: 0% !important;

            &:nth-child(3n + 1) {
              margin-left: 0% !important;
            }
          }
        }
      }
    }
  }

  @media (max-width:579px) {
    .aw-acp-popup-container {
      .mfp-container {
        position: static;
        padding: 0;
        background: $aw-acp-mfp-container__background-color;

        &::before {
          display: none;
        }
      }

      .mfp-content {
        position: static;
      }
    }

    .aw-acp-popup {
      position: static;
      margin: 0;

      &__mobile-header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 102;
        display:flex;
        -webkit-box-align: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        overflow: hidden;
        padding: 0 15px;
        width: 100%;
        height: 40px;
        border-bottom: 1px solid $aw-acp-mobile-header__border-color;
        background: $aw-acp-mfp-container__background-color;
      }

      &__product-info {
        display: none;
      }

      &--result {
        .aw-acp-popup__message {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;

          &--info {
            display: none;
          }
        }

        .aw-acp-popup__columns {
          -webkit-box-flex: 0;
          -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          margin: auto;
          padding-bottom: 20px;

            @include min-screen($screen__m) {
                margin-top: -20px;
            }
        }

        .aw-acp-popup__column {
          &--media {
            display: none;
          }
        }

      }

      &__main {
        left: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0;
        padding: 40px 15px 40px 15px;
        width: 100%;

        &.aw-acp-popup--result {
          display: flex;
          flex-direction: column;
        }
      }

      &__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__column {
        &:first-child {
          margin-bottom: 20px;
        }

        &--media {
          flex-direction: column-reverse;

          &.small {
            .aw-acp-popup__image-wrapper {
              width: 50%;
              margin: auto;
            }
          }
        }
      }

      &__reviews-wrapper {
        text-align: left;
        margin-bottom: 10px;
      }

      &__close-wrapper {
        display:none;
      }

      &__submit-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
      }

    }

    .aw-acp-mpf-open {
      overflow: hidden !important;
      height: 100% !important;

      body {
        overflow: hidden !important;
        height: 100% !important;
        -webkit-transform: translateZ(0);

        > div {
          display: none;

          &.mfp-bg,
          &.mfp-wrap {
            display: block;
          }
        }
      }
    }
  }
