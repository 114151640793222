//
//  Form variables
//  _____________________________________________

$form-element-input-placeholder__color: $color-primary-lighter;
$form-element-input__focus__color: $color-primary;
$form-element-input__border-color: $color-white;
$form-element-input__border: none;
$form-element-input__font-size: 1.3rem;
$form-element-input__disabled__border: none;
$form-element-input__font-family: $font-family__base;

$input-text__font-family: $form-element-input__font-family;
$select__font-family: $form-element-input__font-family;
$textarea__font-family: $form-element-input__font-family;

$input-text__border: none;
$select__border: none;
$textarea__border: none;

$focus__box-shadow: 0 0 3px 1px rgba($color-primary, 0.25);
