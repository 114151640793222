$storelocator-map-size: 80vh;
$storelocator-search-input-size: 48px;

.amlocator-index-index,
.amlocator-location-view {
    .page-title-wrapper {
        text-align: center;
    }
}

.storelocator__wrapper {
    position: relative;
    text-align: left;
}

.storelocator__toolbar {
    margin-top: 30px;
}

.storelocator__map-container {
    display: block;

    @include min-screen($screen__m) {
        height: auto;
        max-height: none;
    }
}

.storelocator__map {
    height: $storelocator-map-size !important;
    padding-left: 0;
    width: 100%;

    button:after {
        display: none;
    }
}

.storelocator__main {
    @include min-screen($screen__m) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 75px;
    }

    @include min-screen($screen__xl) {
        flex-wrap: nowrap;
    }
}

.storelocator__column--left {
    @include min-screen($screen__m) {
        flex: 1 1 30%;
        overflow-y: auto;
        max-height: 80vh;
    }
}

.storelocator__column--right {
    @include min-screen($screen__m) {
        flex: 10 1 50%;
        margin-left: 2%;
    }
}

.storelocator__map,
.storelocator__locations {
    @include max-screen($screen__m) {
        margin-top: 36px;
    }
}

.storelocator__search-container {
    background: none;
    margin: 0 auto 0;
    max-width: 460px;
    padding: 0;
    text-align: center;
    width: 100%;

    .storelocator__search-input {
        &,
        &:focus {
            @include lib-input-placeholder;
            border: 0;
            border-bottom: 1px solid $border-color__base;
            border-radius: 0;
            height: $storelocator-search-input-size;
            padding-right: $storelocator-search-input-size;
            width: 100%;
        }
    }
}

.storelocator__search-input-icon {
    background: none;
    cursor: pointer;
    line-height: $storelocator-search-input-size;
    height: $storelocator-search-input-size;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: $storelocator-search-input-size;

    &:before {
        @include icon('search');
    }
}

.storelocator__search-radius {
    display: none;
}

.storelocator__block {
    margin-top: 52px;

    a.storelocator__link-btn {
        margin-right: 9px;
        margin-top: 9px;
    }
}

.storelocator__block--first {
    margin-top: 0;
}

.storelocator__block-title {
    @include lib-heading($_heading_level: h3);
    display: block;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.storelocator__title {
    display: block;
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.storelocator__description {
    margin: 0 -10px;
}

.storelocator__label {
    cursor: pointer;
    display: none;
    font-size: 1.3rem;
    font-weight: $font-weight__heavier;
    letter-spacing: 2.5px;
    margin: 0 0 $indent__base;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    word-break: break-all;
    z-index: 1;
}

.storelocator__content {
    border-bottom: 1px solid $color-gray-darker;
    padding-bottom: $indent__base;
}

.storelocator__input,
.storelocator__actions {
    display: none;
}

.storelocator__link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.storelocator__button {
    @include btn;
    border-color: $color-black;

    @include min-screen($screen__m) {
        @include btn;
    }
}

.storelocator__button--nearby {
    @include lib-button-reset;
    align-items: center;
    color: $color-gray-dark;
    display: flex;
    font-weight: $heading_sub__font-weight__base;
    font-style: normal;
    font-size: 1.2rem;
    margin: 15px auto;
    letter-spacing: 3px;
    text-decoration: underline;

    &:hover {
        color: $color-gray-dark;
        text-decoration: none;
    }

    &:before {
        @include icon('map');
        margin-right: 7.5px;
    }

    &:after {
        display: none;
    }
}

.storelocator__store-list {
    font-size: 1.3rem !important;
    font-weight: $font-weight__light !important;
    letter-spacing: 1.5px;
}

.storelocator__store-list-item {
    background: none !important;
    border-bottom: 1px solid $color-gray-darker;
    cursor: pointer;
    margin-bottom: 0 !important;
    padding: 30px 0;
    box-sizing: border-box;
    color: $color-black !important;
    font-size: 1.3rem !important;
    margin-top: 0 !important;
    text-align: left;

    .store__content {
        display: none;
    }

    .amlocator-link,
    .storelocator__link {
        color: $color-primary-lighter;
    }

    &:hover,
    &.storelocator__store-list-item--active {
        color: $color-black;

        .store__content {
            display: block;
        }

        .storelocator__link {
            color: $color-primary;
        }
    }
}

.storelocator__store-information {
    cursor: pointer;
    display: flex;
    width: 100%;
}

.storelocator__store-title {
    font-size: 1.3rem !important;
    font-weight: $font-weight__heavier !important;
    letter-spacing: 2.5px;
    margin-bottom: 3px !important;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    word-break: break-all;
}

.storelocator__value--brandstore {
    font-weight: $font-weight__bold;
}

.storelocator__distance {
    color: $text__color__muted;
    font-size: $font-size__s;
    margin-left: auto;
}

.storelocator__distance-number-label {
    display: none;
}

.storelocator__filter-title {
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 4px;
    margin-bottom: $indent__base;
    text-transform: uppercase;
}

.storelocator__filter-content {
    display: flex;
}

.storelocator__filter-option {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1.5px;
    line-height: 21px;
    margin: 15px 15px 15px 0;
    position: relative;

    &:last-child {
        margin-right: 0;
        display: none; // Temp fix untill verkooppunten are available
    }
}

.storelocator__filters-container {
    display: none;
}

.storelocator__filter-option-input {
    margin-right: 12px;
}

.storelocator__filter-attribute,
.storelocator__clear {
    @include btn-outlined;
}

.storelocator-popup {
    text-align: left;

    .test {
        color: $color-black;
    }
    .amlocator-name {
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 2.5px;
        margin-bottom: 7.5px;
        margin-top: 0;
        padding: 0;
        text-transform: uppercase;
        word-break: break-all;
        color: $color-black;

        * {
            color: $color-black;
        }
    }

    .amlocator-link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .amlocator-image {
        margin-bottom: 12px;

        img {
            height: auto;
            max-width: 300px;
            max-height: 100%;
        }
    }
}

.storelocator-popup__route-link {
    display: block;
    margin-top: 7.5px;
    color: $color-black;
}

.storelocator__location-gallery {
    &.slick-slider {
        padding: 0;
    }
}

.pac-container {
    .pac-item {
        cursor: pointer;
        line-height: $storelocator-search-input-size;
        padding: 0 15px;
    }

    .pac-icon {
        margin-top: 15px;
    }
}

.storelocator__row {
    display: flex;
}

.storelocator__row--btn {
    margin-top: 15px;
}

.storelocator__cell--day {
    min-width: 150px;

    &:after {
        content: ':';
    }
}

.is-header-sticky {
    .fancybox-toolbar {
        top: $header-height-desktop-sticky;
    }
}

.fancybox-button {
    &:after {
        display: none;
    }
}
