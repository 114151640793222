.swatch-dropdown {
    @include lib-dropdown;
    display: none;
    margin-bottom: $indent__xs;

    @include max-screen($screen__m) {
        position: inherit;
    }

    .swatch-dropdown__option {
        font-size: 1.2rem;

        &[data-selected="1"] {
            pointer-events: none;
            background: $color-gray;
        }

        &.out-of-stock {
            color: rgba($color-black, 0.35);

            span {
                margin-right: 10px;
                font-weight: 300;
                color: rgba($color-black, 0.35);
                text-decoration: line-through;
            }
        }
    }

    &.active {
        .action.toggle {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    .action.toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        padding: 0;
        border-bottom: 1px solid $color-primary;
        font-size: 15px;
        box-sizing: border-box;

        &:after {
            float: right;
            position: relative;
            right: -5px;
        }
    }

    ul.dropdown {
        margin-top: 0;
        border-top: 0;
        max-height: 300px;
        overflow: auto;

        li {
            white-space: nowrap;
        }

        &:before,
        &:after {
            display: none;
        }
    }
}

.product-info-main {
    .product-options-wrapper {
        .fieldset {
            display: flex;
            align-items: center;
        }
    }
    .swatch-attribute.size,
    .swatch-attribute.full_size {
        .swatch-option {
            display: none;
        }

        .swatch-dropdown {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            min-width: 250px;

            @include max-screen($screen__m) {
                min-width: 210px;
            }
        }
    }

    .swatch-attribute-options[aria-invalid="true"] {
        //box-shadow: 0 0 2px $color-red;
    }

    .swatch-attribute-selected-option {
        display: none;
    }

    .swatch-attribute.full_size {
        .mage-error {
            margin-top: 0;
            margin-bottom: 2rem;
        }
    }
}

@include max-screen($screen__m) {
    .swatch-dropdown-blackout {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(black, 0.5);

        .active & {
            display: block;
        }

        &::after {
            content: '×';
            border-radius: 50%;
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 315px;
            right: 15px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
    }

    .swatch-dropdown-gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 30px;
        background: rgb(221,221,221);
        background: linear-gradient(180deg, rgba(221,221,221,0) 0%, rgba(221,221,221,1) 100%);
        z-index: 100;
    }

    .swatch-dropdown-active {
        #launcher {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.product-info-main .product-options-wrapper .fieldset {
    flex-direction: column;
}
