.page-main {
    .catalog-category-view:not(.is-header-sticky) &,
    .catalogsearch-result-index:not(.is-header-sticky) & {
        margin-top: 0 !important;
    }
}

main#maincontent {
    .catalog-category-view & {
        margin-top: 0 !important;
    }
}

.category-cms {
    h1 {
        margin-bottom: 0;
        font-size: 2.8rem;
        font-weight: $font-weight__light;
        letter-spacing: 7px;

        em {
            font-family: $font-family-name__base;
            font-weight: $font-weight__light;
            font-size: 1.8rem;
            letter-spacing: 4px;
        }
    }

    @include max-screen($screen__m) {
        div[class^="pagebuilder-column"] {
            background-image: none;
            min-height: inherit !important;

            [data-content-type="text"] {
                padding: 0 !important;
            }

            h1 {
                font-size: 20px !important;
                letter-spacing: 2px !important;
                padding-top: 3.875rem;

                span {
                    font-size: 20px !important;
                    font-weight: 400;
                }
            }

            h2 {
                margin-bottom: 15px;
            }

            p {
                display: none;
            }
        }

        [data-background-type="image"] {
            min-height: inherit !important;
        }

        div {
            padding: 0;
        }
    }
}

.category-description {
    clear: both;

    h3 {
        display: block;
        margin-top: $indent__xs;
        color: $color-gray-dark;
        font-weight: $font-weight__light;
        font-style: normal;
        font-size: 1.6rem;
        letter-spacing: 3px;

        &:before {
            content: '- ';
        }

        &:after {
            content: ' -';
        }

        @include min-screen($screen__m) {
            font-size: 1.8rem;
            letter-spacing: 4px;
        }
    }

    .readmore {
        margin-top: 2rem;
        padding: 10px;
    }

    .readmore__button {
        text-decoration: underline;
        margin: 0;
        padding: 0;
        text-transform: none;
        font-weight: normal;
        font-family: $font-family__base;
        border: none !important;

        &:hover {
            background: transparent !important;
            color: black !important;
        }

        &:after {
            display: none;
        }
    }

    & > div {
        &.is--concat {
            overflow: hidden;
            height: 204px;
        }

        &.is--expanded {
            height: auto;
        }
    }
}

@include min-screen($screen__m) {
    .page-products {
        .category-description {
            margin: 0 auto 100px auto;
            text-align: justify;

            ul {
                list-style-position: inside;
                column-count: 2;
            }
        }

        .vertical-note {
            display: block;

            &.scroll-to-top {
                cursor: pointer;
            }
        }
    }

    .category-description__divider .pagebuilder--margin-bottom-50-desktop {
        margin-bottom: 0 !important;
    }
}
