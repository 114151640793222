%svg-common {
	background: url("svg/sprite.css.svg") no-repeat;
}

.icon-arrow-left {
	@extend %svg-common;
	background-position: 57.971014492753625% 43.8423645320197%;
}

.icon-arrow-left-dims {
	width: 28px;
	height: 10px;
}

.icon-arrow-left-variant {
	@extend %svg-common;
	background-position: 92.62672811059907% 65.02463054187191%;
}

.icon-arrow-left-variant-dims {
	width: 18px;
	height: 10px;
}

.icon-arrow-left-white {
	@extend %svg-common;
	background-position: 31.884057971014492% 20.19704433497537%;
}

.icon-arrow-left-white-dims {
	width: 28px;
	height: 10px;
}

.icon-arrow-right {
	@extend %svg-common;
	background-position: 31.884057971014492% 25.123152709359605%;
}

.icon-arrow-right-dims {
	width: 28px;
	height: 10px;
}

.icon-arrow-right-variant {
	@extend %svg-common;
	background-position: 92.62672811059907% 69.95073891625616%;
}

.icon-arrow-right-variant-dims {
	width: 18px;
	height: 10px;
}

.icon-arrow-right-white {
	@extend %svg-common;
	background-position: 61.83574879227053% 49.26108374384236%;
}

.icon-arrow-right-white-dims {
	width: 28px;
	height: 10px;
}

.icon-arrow-sort {
	@extend %svg-common;
	background-position: 23.28767123287671% 98.98477157360406%;
}

.icon-arrow-sort-dims {
	width: 16px;
	height: 16px;
}

.icon-cart {
	@extend %svg-common;
	background-position: 70.5607476635514% 89.17525773195877%;
}

.icon-cart-dims {
	width: 21px;
	height: 19px;
}

.icon-cart-white {
	@extend %svg-common;
	background-position: 80.37383177570094% 89.17525773195877%;
}

.icon-cart-white-dims {
	width: 21px;
	height: 19px;
}

.icon-chat {
	@extend %svg-common;
	background-position: 14.009661835748792% 77.83783783783784%;
}

.icon-chat-dims {
	width: 28px;
	height: 28px;
}

.icon-check {
	@extend %svg-common;
	background-position: 99.10313901345292% 14.146341463414634%;
}

.icon-check-dims {
	width: 12px;
	height: 8px;
}

.icon-check-green {
	@extend %svg-common;
	background-position: 99.10313901345292% 18.048780487804876%;
}

.icon-check-green-dims {
	width: 12px;
	height: 8px;
}

.icon-check-thicker {
	@extend %svg-common;
	background-position: 99.10313901345292% 22.277227722772277%;
}

.icon-check-thicker-dims {
	width: 12px;
	height: 11px;
}

.icon-check-thicker-white {
	@extend %svg-common;
	background-position: 99.10313901345292% 27.722772277227723%;
}

.icon-check-thicker-white-dims {
	width: 12px;
	height: 11px;
}

.icon-check-white {
	@extend %svg-common;
	background-position: 99.10313901345292% 32.68292682926829%;
}

.icon-check-white-dims {
	width: 12px;
	height: 8px;
}

.icon-chevron-down {
	@extend %svg-common;
	background-position: 98.22222222222223% 69.71153846153847%;
}

.icon-chevron-down-dims {
	width: 10px;
	height: 5px;
}

.icon-chevron-down-black {
	@extend %svg-common;
	background-position: 99.10313901345292% 36.231884057971016%;
}

.icon-chevron-down-black-dims {
	width: 12px;
	height: 6px;
}

.icon-chevron-down-white {
	@extend %svg-common;
	background-position: 98.22222222222223% 72.46376811594203%;
}

.icon-chevron-down-white-dims {
	width: 10px;
	height: 6px;
}

.icon-chevron-right-black {
	@extend %svg-common;
	background-position: 96.5065502183406% 40.298507462686565%;
}

.icon-chevron-right-black-dims {
	width: 6px;
	height: 12px;
}

.icon-chevron-right-white {
	@extend %svg-common;
	background-position: 99.12663755458516% 40.298507462686565%;
}

.icon-chevron-right-white-dims {
	width: 6px;
	height: 12px;
}

.icon-chevron-up-black {
	@extend %svg-common;
	background-position: 99.10313901345292% 44.927536231884055%;
}

.icon-chevron-up-black-dims {
	width: 12px;
	height: 6px;
}

.icon-close {
	@extend %svg-common;
	background-position: 99.10313901345292% 49.00990099009901%;
}

.icon-close-dims {
	width: 12px;
	height: 11px;
}

.icon-close-red {
	@extend %svg-common;
	background-position: 99.10313901345292% 54.45544554455446%;
}

.icon-close-red-dims {
	width: 12px;
	height: 11px;
}

.icon-close-small {
	@extend %svg-common;
	background-position: 97.3568281938326% 78.53658536585365%;
}

.icon-close-small-dims {
	width: 8px;
	height: 8px;
}

.icon-close-small-white {
	@extend %svg-common;
	background-position: 97.3568281938326% 82.4390243902439%;
}

.icon-close-small-white-dims {
	width: 8px;
	height: 8px;
}

.icon-close-white {
	@extend %svg-common;
	background-position: 96.9298245614035% 85.92233009708738%;
}

.icon-close-white-dims {
	width: 7px;
	height: 7px;
}

.icon-envelope {
	@extend %svg-common;
	background-position: 27.536231884057973% 77.83783783783784%;
}

.icon-envelope-dims {
	width: 28px;
	height: 28px;
}

.icon-facebook {
	@extend %svg-common;
	background-position: 88.93805309734513% 77.94871794871794%;
}

.icon-facebook-dims {
	width: 9px;
	height: 18px;
}

.icon-facebook-alt {
	@extend %svg-common;
	background-position: 47.29064039408867% 54.054054054054056%;
}

.icon-facebook-alt-dims {
	width: 32px;
	height: 28px;
}

.icon-facebook-hover {
	@extend %svg-common;
	background-position: 92.92035398230088% 77.94871794871794%;
}

.icon-facebook-hover-dims {
	width: 9px;
	height: 18px;
}

.icon-fast-shipping {
	@extend %svg-common;
	background-position: 36.46408839779006% 0;
}

.icon-fast-shipping-dims {
	width: 54px;
	height: 41px;
}

.icon-flag-be {
	@extend %svg-common;
	background-position: 30.59360730593607% 98.98477157360406%;
}

.icon-flag-be-dims {
	width: 16px;
	height: 16px;
}

.icon-flag-de {
	@extend %svg-common;
	background-position: 37.89954337899543% 98.98477157360406%;
}

.icon-flag-de-dims {
	width: 16px;
	height: 16px;
}

.icon-flag-en {
	@extend %svg-common;
	background-position: 45.205479452054796% 98.98477157360406%;
}

.icon-flag-en-dims {
	width: 16px;
	height: 16px;
}

.icon-flag-nl {
	@extend %svg-common;
	background-position: 52.51141552511415% 98.98477157360406%;
}

.icon-flag-nl-dims {
	width: 16px;
	height: 16px;
}

.icon-flag-uk {
	@extend %svg-common;
	background-position: 59.817351598173516% 98.98477157360406%;
}

.icon-flag-uk-dims {
	width: 16px;
	height: 16px;
}

.icon-free-returns {
	@extend %svg-common;
	background-position: 0 36.87150837988827%;
}

.icon-free-returns-dims {
	width: 54px;
	height: 34px;
}

.icon-free-shipping {
	@extend %svg-common;
	background-position: 29.834254143646408% 36.87150837988827%;
}

.icon-free-shipping-dims {
	width: 54px;
	height: 34px;
}

.icon-heart {
	@extend %svg-common;
	background-position: 81.69014084507042% 63.21243523316062%;
}

.icon-heart-dims {
	width: 22px;
	height: 20px;
}

.icon-heart-small {
	@extend %svg-common;
	background-position: 67.12328767123287% 98.98477157360406%;
}

.icon-heart-small-dims {
	width: 16px;
	height: 16px;
}

.icon-heart-white {
	@extend %svg-common;
	background-position: 81.69014084507042% 73.57512953367876%;
}

.icon-heart-white-dims {
	width: 22px;
	height: 20px;
}

.icon-icon-arrow-select {
	@extend %svg-common;
	background-position: 98.22222222222223% 75%;
}

.icon-icon-arrow-select-dims {
	width: 10px;
	height: 5px;
}

.icon-icon-info-tooltip {
	@extend %svg-common;
	background-position: 15.596330275229358% 99.48979591836735%;
}

.icon-icon-info-tooltip-dims {
	width: 17px;
	height: 17px;
}

.icon-icon-modal-close {
	@extend %svg-common;
	background-position: 93.48837209302326% 0;
}

.icon-icon-modal-close-dims {
	width: 20px;
	height: 20px;
}

.icon-icon-pencil-gray {
	@extend %svg-common;
	background-position: 92.62672811059907% 87.17948717948718%;
}

.icon-icon-pencil-gray-dims {
	width: 18px;
	height: 18px;
}

.icon-icon-pickup-in-store {
	@extend %svg-common;
	background-position: 61.53846153846154% 26.62721893491124%;
}

.icon-icon-pickup-in-store-dims {
	width: 40px;
	height: 44px;
}

.icon-icon-pickup-in-store-white {
	@extend %svg-common;
	background-position: 0 59.171597633136095%;
}

.icon-icon-pickup-in-store-white-dims {
	width: 40px;
	height: 44px;
}

.icon-icon-tab-min {
	@extend %svg-common;
	background-position: 98.66071428571429% 62.735849056603776%;
}

.icon-icon-tab-min-dims {
	width: 11px;
	height: 1px;
}

.icon-icon-tab-plus {
	@extend %svg-common;
	background-position: 98.66071428571429% 66.33663366336634%;
}

.icon-icon-tab-plus-dims {
	width: 11px;
	height: 11px;
}

.icon-icon-video {
	@extend %svg-common;
	background-position: 0 78.26086956521739%;
}

.icon-icon-video-dims {
	width: 29px;
	height: 29px;
}

.icon-icon-zoom {
	@extend %svg-common;
	background-position: 0 90.57591623036649%;
}

.icon-icon-zoom-dims {
	width: 21px;
	height: 22px;
}

.icon-info {
	@extend %svg-common;
	background-position: 74.42922374429224% 98.98477157360406%;
}

.icon-info-dims {
	width: 16px;
	height: 16px;
}

.icon-instagram {
	@extend %svg-common;
	background-position: 93.05555555555556% 10.362694300518134%;
}

.icon-instagram-dims {
	width: 19px;
	height: 20px;
}

.icon-instagram-hover {
	@extend %svg-common;
	background-position: 93.05555555555556% 20.72538860103627%;
}

.icon-instagram-hover-dims {
	width: 19px;
	height: 20px;
}

.icon-large-chevron-right-black {
	@extend %svg-common;
	background-position: 19.32367149758454% 56.17977528089887%;
}

.icon-large-chevron-right-black-dims {
	width: 28px;
	height: 35px;
}

.icon-large-chevron-right-white {
	@extend %svg-common;
	background-position: 32.85024154589372% 56.17977528089887%;
}

.icon-large-chevron-right-white-dims {
	width: 28px;
	height: 35px;
}

.icon-linkedin {
	@extend %svg-common;
	background-position: 93.48837209302326% 30.927835051546392%;
}

.icon-linkedin-dims {
	width: 20px;
	height: 19px;
}

.icon-linkedin-hover {
	@extend %svg-common;
	background-position: 93.48837209302326% 40.72164948453608%;
}

.icon-linkedin-hover-dims {
	width: 20px;
	height: 19px;
}

.icon-location {
	@extend %svg-common;
	background-position: 81.30841121495327% 12.76595744680851%;
}

.icon-location-dims {
	width: 21px;
	height: 25px;
}

.icon-location-white {
	@extend %svg-common;
	background-position: 81.30841121495327% 26.06382978723404%;
}

.icon-location-white-dims {
	width: 21px;
	height: 25px;
}

.icon-map {
	@extend %svg-common;
	background-position: 81.7351598173516% 98.98477157360406%;
}

.icon-map-dims {
	width: 16px;
	height: 16px;
}

.icon-minus {
	@extend %svg-common;
	background-position: 99.54954954954955% 6.6350710900473935%;
}

.icon-minus-dims {
	width: 13px;
	height: 2px;
}

.icon-pencil {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-pencil-dims {
	width: 14px;
	height: 14px;
}

.icon-phone {
	@extend %svg-common;
	background-position: 9.859154929577464% 90.57591623036649%;
}

.icon-phone-dims {
	width: 22px;
	height: 22px;
}

.icon-phone-small {
	@extend %svg-common;
	background-position: 41.06280193236715% 77.83783783783784%;
}

.icon-phone-small-dims {
	width: 28px;
	height: 28px;
}

.icon-phone-white {
	@extend %svg-common;
	background-position: 20.187793427230048% 90.57591623036649%;
}

.icon-phone-white-dims {
	width: 22px;
	height: 22px;
}

.icon-pinterest {
	@extend %svg-common;
	background-position: 30.373831775700936% 90.57591623036649%;
}

.icon-pinterest-dims {
	width: 21px;
	height: 22px;
}

.icon-pinterest-hover {
	@extend %svg-common;
	background-position: 40.18691588785047% 90.57591623036649%;
}

.icon-pinterest-hover-dims {
	width: 21px;
	height: 22px;
}

.icon-play {
	@extend %svg-common;
	background-position: 71.42857142857143% 23.076923076923077%;
}

.icon-play-dims {
	width: 11px;
	height: 18px;
}

.icon-play-white {
	@extend %svg-common;
	background-position: 71.42857142857143% 32.30769230769231%;
}

.icon-play-white-dims {
	width: 11px;
	height: 18px;
}

.icon-plus {
	@extend %svg-common;
	background-position: 99.10313901345292% 60.19900497512438%;
}

.icon-plus-dims {
	width: 12px;
	height: 12px;
}

.icon-plus-white {
	@extend %svg-common;
	background-position: 99.54954954954955% 8%;
}

.icon-plus-white-dims {
	width: 13px;
	height: 13px;
}

.icon-safe-payment {
	@extend %svg-common;
	background-position: 66.29834254143647% 0;
}

.icon-safe-payment-dims {
	width: 54px;
	height: 45px;
}

.icon-search {
	@extend %svg-common;
	background-position: 89.04109589041096% 98.48484848484848%;
}

.icon-search-dims {
	width: 16px;
	height: 15px;
}

.icon-size-chart {
	@extend %svg-common;
	background-position: 67.78846153846153% 76.19047619047619%;
}

.icon-size-chart-dims {
	width: 27px;
	height: 24px;
}

.icon-size-chart-white {
	@extend %svg-common;
	background-position: 83.65384615384616% 0;
}

.icon-size-chart-white-dims {
	width: 27px;
	height: 24px;
}

.icon-trash {
	@extend %svg-common;
	background-position: 0 100%;
}

.icon-trash-dims {
	width: 17px;
	height: 18px;
}

.icon-trash-light {
	@extend %svg-common;
	background-position: 7.798165137614679% 100%;
}

.icon-trash-light-dims {
	width: 17px;
	height: 18px;
}

.icon-twitter {
	@extend %svg-common;
	background-position: 93.48837209302326% 50%;
}

.icon-twitter-dims {
	width: 20px;
	height: 17px;
}

.icon-twitter-alt {
	@extend %svg-common;
	background-position: 54.589371980676326% 77.83783783783784%;
}

.icon-twitter-alt-dims {
	width: 28px;
	height: 28px;
}

.icon-twitter-hover {
	@extend %svg-common;
	background-position: 93.48837209302326% 58.673469387755105%;
}

.icon-twitter-hover-dims {
	width: 20px;
	height: 17px;
}

.icon-user {
	@extend %svg-common;
	background-position: 80.93023255813954% 39.15343915343915%;
}

.icon-user-dims {
	width: 20px;
	height: 24px;
}

.icon-user-white {
	@extend %svg-common;
	background-position: 80.93023255813954% 51.851851851851855%;
}

.icon-user-white-dims {
	width: 20px;
	height: 24px;
}

.icon-video {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-video-dims {
	width: 66px;
	height: 66px;
}

.icon-whatsapp {
	@extend %svg-common;
	background-position: 50.23474178403756% 90.57591623036649%;
}

.icon-whatsapp-dims {
	width: 22px;
	height: 22px;
}

.icon-whatsapp-white {
	@extend %svg-common;
	background-position: 60.563380281690144% 90.57591623036649%;
}

.icon-whatsapp-white-dims {
	width: 22px;
	height: 22px;
}

.icon-youtube {
	@extend %svg-common;
	background-position: 60.093896713615024% 55.83756345177665%;
}

.icon-youtube-dims {
	width: 22px;
	height: 16px;
}

.icon-youtube-hover {
	@extend %svg-common;
	background-position: 70.4225352112676% 55.83756345177665%;
}

.icon-youtube-hover-dims {
	width: 22px;
	height: 16px;
}

