.faq-item {
    border-bottom: 1px solid $color-gray;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .question {
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        padding-right: 20px;

        &:after {
            background-image: url(../images/icons/icon-tab-plus.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            content: "";
            height: 11px;
            margin-top: -5.5px;
            position: absolute;
            right: 0;
            top: 10px;
            width: 11px;
        }
    }

    .answer {
        margin-top: 10px;
    }

    &.open {
        .question {
            &:after {
                background-image: url(../images/icons/icon-tab-min.svg);
            }
        }
    }
}
