.cms-noroute-index {
    .page-main-full-width {
        max-width: 1920px;
        padding: 0 20px;
        margin-top: 104px;

        @include min-screen($screen__m) {
            margin-top: 60px;
        }
    }
}
