.page-header {
    .panel {
        &.wrapper {
            background-color: $color-gray;
            height: $panel-wrapper-height;

            @include max-screen($screen__m) {
                position: relative;
                z-index: 99;
            }

            @include min-screen($screen__m) {
                border-bottom: none;
            }
        }

        &.header {
            position: relative;

            @include min-screen($screen__m) {
                padding-top: 0;
                padding-bottom: 0;
            }

            > .header.links {
                display: none;
            }

            .header-right-container {
                display: none;
                position: absolute;
                right: 0;
                height: 35px;
                background-color: #EAEAEB;
                z-index: 9999;

                @include min-screen($screen__xl) {
                    display: inline-flex;
                    padding-right: 35px;
                    right: -35px;
                }
            }

            .header-customer-support-container {
                @include min-screen($screen__xl) {
                    display: inline-flex;
                    position: relative;
                    align-items: center;
                    padding: 0 20px;
                    font-weight: 400;

                    &:hover {
                        background-color: #dddddf;
                    }

                    span a {
                        text-decoration: none;
                        text-transform: uppercase;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}
