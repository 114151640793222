.size-selector-container {
    display: none;

    &.is--active {
        display: block;
    }

    .product-stock-notification {
        .wk-container {
            background-color: transparent;
            padding-left: 0 !important;
            padding-right: 0 !important;
            display: block !important;

            @include max-screen($screen__m) {
                padding: 0 !important;
            }
        }

        input[type="text"] {
            border: 1px solid $color-gray-light;
            border-radius: 3px;
        }
    }

    .product-stock-notification__text {
        @include max-screen($screen__m) {
            margin-top: 0.5rem;
        }
    }
}

.size-selector {
    background-color: white;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 10;
    top: 0;
    width: 500px;
    display: flex;
    flex-direction: column;
    height: 100%;
    transform: translateX(100%);
    transition-delay: 1s;
    transition: transform 250ms ease-in-out;


    .product-stock-notification:not(.product-stock-notification--simple) {
        display: block;
    }

    @include max-screen($screen__m) {
        width: 92%;
        z-index: 9999;
    }

    &.is--active {
        transform: translateX(0%);
    }

    thead,
    tbody {
        tr {
            display: flex;
            width: 100%;
            align-items: center;

            @include max-screen($screen__m) {
                td {
                    padding: 8px;
                }
            }
        }
    }

    tr {
        display:block;
    }

    td.dummy {
        * {
            display: none;
        }
    }

    tr th:first-child,
    tr td:first-child {
        min-width: 50px;
        line-height: 43px;
        font-weight: bold;
    }

    tr th:not(:first-child),
    tr td:not(:first-child) {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include min-screen($screen__m) {
            min-width: 120px;
        }
    }

    tbody {
        display:block;
        overflow:auto;
        height: 100%;
        width: 100%;
    }

    th {
        text-align: center;

        @include max-screen($screen__m) {
            font-size: 11px;
        }

        span {
            font-weight: normal;
            display: block;
            font-size: 10px;
            max-width: 100px;
            text-align: center;

            @include min-screen($screen__m) {
                min-width: 120px;
            }
        }
    }

    .size-selector__content {
        overflow-y: auto;

        thead th {
            position: sticky;
            top: 0;
        }
    }

    .size-selector__head,
    .size-selector__footnote,
    .size-selector__content {
        padding: 0 40px;
        box-sizing: border-box;
        margin-bottom: 20px;

        @include max-screen($screen__m) {
            padding: 0 10px;
        }
    }

    .size-selector__head {
        @include max-screen($screen__m) {
            margin-bottom: 0;
        }
    }
}

.size-selector__close-button {
    background-color: $color-black;
    border: 1px solid $color-black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
    font-size: 18px;
    line-height: 100%;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;

    &:hover {
        color: $color-black;
        background-color: white;
    }

    @include min-screen($screen__m) {
        display: none;
    }
}

.size-selector__head {
    text-align: center;

    h3 {
        @include min-screen($screen__m) {
            font-size: 24px;
            font-weight: 600;
            margin-top: 40px;
        }
    }
}

.size-selector__content {
    margin-bottom: 10px;
}

.size-selector__footnote {
    bottom: 0;
    border-top: 1px solid $color-border;
    padding-top: 20px !important;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    h3 {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }
}

.size-selector-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba($color-black, 0.4);

    @include max-screen($screen__m) {
        z-index: 99;
    }
}

.size-tile-selector {
    min-width: 80px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid $color-border;
    border-radius: 4px;
    text-align: center;

    @include max-screen($screen__m) {
        padding: 10px 4px !important;
    }

    &:after {
        display: none;
    }

    // Not available sizes
    &.saleable-0 {
        text-decoration: line-through;
        background-color: $color-border;

        &:hover {
            color: $color-black;
        }
    }

    &.is--selected {
        background-color: black;
        border-color: $color-black;
        color: white !important;
        pointer-events: none;
    }

    &.is--selected.saleable-0 {
        background-color: #e5e5e5;
        border-color: $color-black;
        color: $color-black !important;
    }
}

.size-selector__column {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

.size-selector-notification__submit-button {
    margin-top: 20px;
    background-color: $color-black;
    color: white;
    white-space: nowrap;


    &:before {
        @include icon('cart-white');
        position: absolute;
        right: 20px;
        top: 14px;
    }

    &:after {
        display: none !important;
    }


    &:hover,
    &:focus {
        background-color: white !important;
        color: $color-black;

        &:after {
            background-color: $color-black !important;

        }
    }

    &:after {
        background-color: white !important;
    }
}

.toggle-size-selector-button {
    white-space: nowrap;
    margin-bottom: 0;
    width: 100%;
    text-align: center;

    @include max-screen($screen__m) {
        margin-top: 20px;
        transform: translateY(15px) !important;
    }

    &.is--disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.size-selector__submit-button {
    background-color: $color-black;
    color: white;
    white-space: nowrap;
    border: 1px solid $color-black;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        background-color: white !important;
        color: $color-black;

        &:before {
            @include icon('cart');
        }
    }

    &:after {
        display: none !important;
    }

    &:before {
        @include icon('cart-white');
        position: absolute;
        right: 20px;
        top: 14px;
    }
}

.size-selector-notification {
    display: none;

    &.is--active {
        display: block;
    }

    h3 {
        @include max-screen($screen__m) {
            margin: 0;
            font-size: 15px;
        }
    }
}

.size-selector__clickcollect-placeholder {
    margin-top: 15px;
    max-width: 100%;
    width: 100%;

    .location-button {
        padding: 0;
    }

    .location-button__text {
        padding: 8px 32px 8px 20px;
    }

    .location-button__text,
    .location-button__select_option {
        font-size: 12px;
        padding-right: 30px;
        box-sizing: border-box;
        display: flex;
        width: 100%;
        text-align: left;
    }
}

// Overflow body if size selector is active
html.size-selector-active {
    overflow: hidden;
}

body.size-selector-active {
    overflow: hidden;

    .product.media {
        pointer-events: none;
    }

    @include max-screen($screen__m) {
        .page-header.headroom--pinned {
            z-index: inherit;
        }

        .page-header {
            z-index: 10;
        }
    }

    @include min-screen($screen__m) {
        .page-header {
            z-index: inherit !important;
        }
    }

    .product-main .product-info-main {
        z-index: 10;
    }

    #robin_tab_container {
        display: none;
    }
}

body.hide-stock-heading {
    .size-selector thead {
        display: none;
    }
}

body.product-giftcard-tcs,
body.product-single-stock-column {
    .size-selector tr td:first-child,
    .size-selector tr th:first-child {
        display: none;
    }

    .size-selector-body {
        display: flex;
        flex-flow: column wrap;
        align-items: start;
        justify-content: start;

        .size-table-row {
            width: auto;
            margin: 0;
        }

        .product {

        }
    }

    .size-selector-thead {
        .size-table-row {
            justify-content: center;
        }
    }
}

// Specific styling for products with 1 stock type column
body.product-single-stock-column {
    .size-selector__head {
        margin-bottom: 0;
    }
}

.catalog-product-view {
    .swatch-opt {
        height: 0;
    }

    .product-options-bottom .product-options-wrapper {
        @include max-screen($screen__m) {
            width: 100% !important;
        }
    }

    &.product-has-size-selector {
        .page-header.headroom--pinned {
            z-index: 0;
        }

        .product-add-form {
            #product-addtocart-button {
                @include max-screen($screen__m) {
                    display: none;
                }
            }
        }

        .product-options-bottom {
            @include max-screen($screen__m) {
                display: flex;
                align-items: flex-end;
                min-height: inherit !important;

                .product-options-wrapper {
                    margin-bottom: 0 !important;
                    width: 100%;
                }
            }
        }

        @include min-screen($screen__m) {
            .pdp-gallery {
                z-index: inherit;
            }
        }
        .product-add-form {
            .box-tocart {
                display: none;
            }
        }
    }
}

.product-options-bottom {
    @include max-screen($screen__m) {
        display: flex;
        align-items: center;

        .box-tocart {
            display: none;
        }
    }
}

