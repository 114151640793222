.aw-faq-search {
    margin-bottom: $indent__base;
    justify-content: flex-start !important; // sass-lint:disable-line no-important

    // overwrite module styling
    form {
        display: flex !important; // sass-lint:disable-line no-important

        @include max-screen($screen__s) {
            width: 100% !important; // sass-lint:disable-line no-important
        }

        .aw-search-field {
            flex: 1 1 100%;
            width: 100%;

            .control {
                width: 100%;
            }

            #aw-faq-search-field { // sass-lint:disable-line no-ids
                height: 42px;
                width: 100%;
                float: none;

                @include min-screen($screen__m) {
                    height: 52px;
                }
            }
        }

        button[type="submit"].action.primary {
            max-width: none;
            margin-left: $indent__s;

            @include max-screen(576px) {
                padding-top: 10px;
                padding-bottom: 10px;

                &:after {
                    display: none;
                }

                span {
                    padding: 0;
                    margin: 0;
                    overflow: inherit;
                    position: inherit;
                    height: inherit;
                    width: inherit;
                    clip: inherit;
                }
            }
        }
    }
}

.faq-overview {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{$indent__s} $indent__xxl;

    &__column {
        width: 100%;
        flex: 0 0 100%;
        padding: $indent__s;
        box-sizing: border-box;
    }

    &.faq-overview--2-columns {
        .faq-overview__column {
            @include min-screen($screen__s) {
                width: 50%;
                flex: 0 0 50%;
            }
        }
    }

    &__list {
        margin: 15px 0;
    }

    &__item-title {
        position: relative;
        padding: $indent__xs $indent__base $indent__xs 45px;
        margin-bottom: $indent__xs;
        border: 1px solid $border-color__secondary;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: $font-weight__light;

        &:before {
            content: '+';
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: $indent__s;
            left: $indent__base;
            width: 15px;
            height: 15px;
            line-height: 1;
            color: $color-white;
            background: $color-black;
            border-radius: 50%;
        }

        &[aria-expanded="true"] {
            &:before {
                content: '-';
            }
        }

        &:hover {
            background: $color-black;
            color: $color-white;

            &:before {
                background: $color-white;
                color: $color-black;
            }
        }
    }

    &__item-content {
        display: none;
        padding: $indent__s $indent__s $indent__base;

        &,
        p {
            font-size: 1.5rem;
        }
    }

    &__category {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__more {
        font-size: 1.4rem;
        float: right;
    }
}

.faq-article-index {
    .page-bottom {
        margin-top: $indent__xxl;
    }
}