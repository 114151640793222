// Page messages
.page {

    &.messages {
        @include layout-inner;
        padding-left: 0;
        padding-right: 0;
    }

    .message {
        color: black;
        border: 1px solid black;
        background-color: white;
        margin-bottom: 40px;

        > *:first-child:before {
            color: black;
        }

        a {
            color: black;

            &:hover {
                color: black;
            }
        }
    }
}
