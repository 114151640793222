//
//  Icons
//  ---------------------------------------------

@mixin icon(
    $icon,
    $scale: null,
    $display: inline-block,
    $load-icon: true
) {
    @if $load-icon == true {
        @extend .icon-#{$icon} !optional;
        @extend .icon-#{$icon}-dims !optional;
    }

    content: '';
    display: $display;

    @if $scale != null {
        transform: scale($scale);
    }
}

@mixin tooltip-icon(
    $width: $indent__base,
    $height: $indent__base,
    $border-width: 2px
) {
    width: $width;
    height: $height;
    border: $border-width solid $color-primary;
    color: $color-primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight__bold;
    cursor: pointer;
}
