.cav-mailplus-form {
    form {

        .mpErrorSummary {
            margin-bottom: $indent__base !important;

            label {
                font-weight: bold;
            }

            ul {
                list-style: inherit;
                color: red;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            @include max-screen($screen__m) {
                margin: 0 20px;
                padding: 0;

                .mpFormField ul {
                    margin: 0;
                }
            }

            @include min-screen($screen__m) {
                width: 600px;
            }
        }

        ul li {
            @include min-screen($screen__m) {
                &:nth-child(6),
                &:nth-child(7) {
                    ul {
                        padding: 0 !important;
                    }
                }
            }

            margin-bottom: $indent__base !important;

            .mpErrorRow {
                font-size: 12px;
                margin-top: 5px;
                color: red;
            }

            .sublabel {
                font-size: 12px;
                margin-top: 5px;
            }

            @include max-screen($screen__m) {
                margin-bottom: $indent__base !important;

                &:nth-child(6) {
                    margin-bottom: 0 !important;
                }
            }
        }

        .mpFormLabel {
            .descriptionLabel {
                font-weight: bold;
            }
        }

        .mpQuestionTable {
            .submitButton {
                @include btn-dark;
                padding: 12.5px 35px;
            }
        }

        @include min-screen($screen__m) {
            .mpFormLabel {
                margin-right: 20px;
                min-width: 120px;
            }

            .mpFormField {
                min-width: 400px;
            }
        }

    }
}

.btn-black-friday {
    .btn {
        &:hover {
            a {
                color: white;
            }
        }
        a {
            text-decoration: none;
        }
    }
}
