.post-author {
    display: flex;
    align-items: center;
}

.author-image-link {

    .author-image {
        width: 40px;
        border-radius: 100%;
        height: 40px;
        margin-right: 8px;
        @include object-fit(cover);
    }
}

.author-image-link,
.author-link,
.inline-by {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
}

.author-image-link {
    margin-right: 4px;
}

