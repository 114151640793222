$navigation__background: $color-white;

$navigation-desktop-level0-item__color: $color-white;
$navigation-desktop-level0-item__line-height: 60px;
$navigation-desktop-level0-item__margin: 0 16px 0 0;
$navigation-desktop-level0-item__hover__color: adjust-color($color-white, $alpha: -0.35) !important; // sass-lint:disable-line no-important
$navigation-desktop-level0-item__active__color: adjust-color($color-white, $alpha: -0.35) !important; // sass-lint:disable-line no-important
$navigation-desktop-level0-item__active__border-color: '';

$navigation-desktop__background: $color-primary;

$submenu-desktop-item__text-decoration: none;
$submenu-desktop-item__color: $color-primary;
$submenu-desktop-item__hover__background: '';
