// Custom pagebuilder styling for USP item
.pagebuilder-usp-item {
    [data-content-type="html"] {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        text-decoration: none;
    }

    p {
        max-width: 80%;
        margin: 0 auto;
    }
    @include max-screen($screen__m) {
        &:not(:last-child) {
            margin-bottom: $indent__base;
        }
    }
}
