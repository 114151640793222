.cms-sidebar {
    ul {
        list-style: none;
        padding: 0;
    }

    li {
        padding: 2px 0;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    h1, h2,
    h3, h4,
    h5, h6 {
        margin-top: 0;
    }
}
