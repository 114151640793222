//
//  Checkout
//  ---------------------------------------------
.checkout-index-index {
    @include max-screen($screen__m) {
        .modal-popup.modal-slide {
            left: 3rem !important;
        }

        .modal-popup.modal-slide .btn.btn-outline {
            padding: 10px 30px 10px 15px !important;
        }

        .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
            max-height: 100% !important;
        }
    }

    .page-main {
        margin-top: 0 !important;
    }

    &.is-header-sticky:not(.catalog-product-view)  {
        .page-main {
            margin-top: 0 !important;
        }
    }

    .page-header {
        margin-bottom: 0;
        position: relative !important;
        box-shadow: none !important;
        top: inherit !important;
        transform: none !important;
        height: inherit !important;
    }

    .checkout-usps.checkout-header {
        margin: 0 calc(50% - 50vw) 60px;
        padding: 1rem 0;
        border-bottom: 1px solid $color-gray-darker;

        @include max-screen($screen__m) {
            padding: 1rem;
            box-sizing: border-box;
            border-top: 1px solid $color-gray-darker;
            margin-bottom: 0;
        }

        ul {
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: block;
            width: 100%;
            max-width: 1400px;
            @include list-usps();

            li {
                text-align: center;
            }

            @include max-screen($screen__m) {
                li {
                    font-size: 1.2rem;
                }
            }

            @include min-screen($screen__l) {
                .slick-track {
                    display: flex !important;
                    justify-content: space-around !important;
                    width: 100% !important;
                }
            }
        }
    }

    .opc-wrapper {
        position: relative;
        width: 100%;

        .opc {
            position: relative;

            .authentication-wrapper {
                width: auto;
                max-width: none;
                position: absolute;
                top: 0;
                right: 0;
                left: auto;
                padding: 0;
                margin: 0;
                @include max-screen($screen__xs) {
                    top: 25px;
                }
                @include min-screen($screen__m) {
                    top: 40px;
                    right: 4rem;
                    left: 4rem;
                }
                @include min-screen($screen__l) {
                    top: 0;
                    right: 4rem;
                    left: 4rem;
                    max-width: 500px;
                }

                .action-auth-toggle {
                    > span  {
                        font-size: 1.4rem;
                        font-weight: $font-weight__light;
                        color: $color-black;
                        text-transform: none;
                    }

                    &:after {
                        display: none;
                    }
                }

                .authentication-dropdown {
                    @include min-screen($screen__m) {
                        width: auto;
                        min-width: 500px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .block-authentication {
                        .actions-toolbar {
                            > .secondary {
                                padding-top: 0;
                            }
                        }
                    }

                    + .dropdown-overlay {
                        background: rgba(51, 51, 51, 0.55);
                    }
                }
            }
        }
    }
}

.opc-progress-bar {
    margin-top: 20px;
    display: flex;
    width: 100%;

    @include min-screen($screen__m) {
        width: 60%;
        box-sizing: border-box;
        padding: 0 4rem;
    }
}

.opc-progress-bar-item {
    width: 50%;

    &._active {
        font-weight: 600;

        > span:before {
            background: $color-primary;
        }

        &:before {
            background: #e4e4e4;
        }
    }

    &._active > span,
    > span:after {
        color: $text__color;
    }

    &._complete {
        > span {
            color: white;
        }

        > span:after {
            @include icon('check');
            top: 15px;
            margin-left: -5px;
        }
    }

    &::before {
        height: 2px;
    }

    span {
        font-size: 0;
        color: $text__color !important;

        @include min-screen($screen__m) {
            font-size: 14px;
        }
    }

    &._active > span {
        font-size: 12px;

        @include min-screen($screen__m) {
            font-size: 14px;
        }
    }

    &._active > span:after {
        content: counter(i);
        font-size: 1.5rem;
        line-height: 1.8;
        background: $text__color;
        color: white !important;
        font-family: $font-family__base;
    }

    > span:after {
        background-color: #e4e4e4;
        font-size: 1.5rem;
        line-height: 1.8;
    }
}

.opc-wrapper .fieldset > .field > .label {
    font-weight: $font-weight__light;
}

@include min-screen($screen__m) {
    .opc-wrapper {
        .fieldset {
            > .field {
                &.required,
                &._required {
                    position: relative;

                    // postcode API fix
                    > label, > .label {
                        padding-right: 0;

                        &:after {
                            margin-left: 0;
                            position: relative;
                            top: -3px;
                        }
                    }
                }
            }
        }
    }
}

.checkout-index-index {
    .header.content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }

    .page-main {
        max-width: 1440px !important;
    }

    .fieldset > {
        .pc_postcode_fieldset {
            .mage-error {
                margin-top: 0;
            }
        }

        .field:not(.choice) {
            > .label {
                width: 100%;
                text-align: left;
                padding: 0;
            }
        }

        .field {
            margin: 0 0 15px;
        }
    }

    .column.main .block .title strong {
        font-size: 1.6rem;
        font-weight: $font-weight__regular;
    }

    .checkout-payment-method {
        .billing-address-same-as-shipping-block {
            & > label {
                font-size: 1.5rem;

                font-weight: $font-weight__light;
            }

            & > input {
                margin-bottom: 4px;
            }

        }

        .payment-method-title {
            padding: 16px 8px;

            .radio {
                margin: 0 10px;
            }

            > .label {
                display: inline-block;
                //width: calc(100% - 50px);
                width: 100%;
                margin-left: 0;
                bottom: -3px;
            }

            > .radio,
            > .label {
                cursor: pointer;
            }
        }

        .payment-method-content {
            padding: 0 0 10px 38px;

            .actions-toolbar {
                margin-top: 20px;
                margin-bottom: 10px;
                margin-left: 0;

                .action-cancel {
                    @include btn-outlined();
                    border-radius: $button__border-radius;
                    margin-right: 10px;
                    text-decoration: none;
                }

                button {
                    width: auto !important;
                }
            }
        }

        .payment-option-title {
            padding: 20px 0 10px;
        }

        .payment-option-content {
            padding: 0;
        }

        .fieldset .field {
            margin: 0;
            display: flex;
            align-items: center;
        }
    }
}

.opc-block-summary {
    > .title {
        border-bottom: 0;
    }

    .minicart-items-wrapper {
        margin: 0;
    }

    .product-item .price {
        font-family: $heading__font-family-name__base;
        font-weight: 300 !important; // sass-lint:disable-line no-important
        display: block;
    }

    .product-item .price::first-letter {
        visibility: hidden;
    }

    .product-item-details {
        .toggle, .toggle:after {
            display: none !important;
        }

        .content {
            display: block !important;
        }
    }

    .product-item-inner {
        .checkout-index-index & {
            position: static;
            opacity: 1;
            background: transparent;
            padding: 0;
            margin: 0;
        }
    }

    .product.options {
        .item-options {
            dt, dd {
                display: inline-block;
                width: auto;
                font-weight: $font-weight__light;
                font-size: 1.3rem;
            }

            dt:after {
                content: ':';
            }
        }
    }
}

.checkout-payment-method {
    @include min-screen($screen__m) {
        .actions-toolbar {
            .primary {
                margin-left: 20px !important;
            }
        }
    }
}

.opc-estimated-wrapper {
    padding: 0 0 5px;

    .estimated-block {
        font-weight: $font-weight__regular;

        .estimated-label {
            margin: 4px 0 -5px;
        }

        .estimated-price {
            display: block;
            margin-left: -18px;

            &::first-letter {
                visibility: hidden;
            }
        }
    }

    .product-item-details .content {
        .item-options {
            dd, dt {
                font-weight: $font-weight__light;
                display: inline-block;
            }
        }

    }

    .minicart-wrapper .action.showcart {
        background: transparent;

        &:before {
            color: $color-black;
        }
    }
}

.checkout-agreements-block {
    .checkout-agreement:before {
        width: inherit !important;
    }

    .action-show {
        text-align: left;
        text-transform: none;
    }
}

.checkout-success {

    .action.primary {
        @include btn();
        border-radius: $button__border-radius;
    }

}

//
//  Media queries
//  ---------------------------------------------

@include min-screen($screen__m) {
    .checkout-index-index {
        .fieldset {
            > .field:not(.choice) {
                > .control {
                    width: 100%;
                }
            }

            .field {
                margin: 0 0 15px;
            }
        }

        .checkout-payment-method {
            .payment-option-title {
                padding-left: 0;
            }
        }
    }

}

@include max-screen($screen__m) {
    .checkout-index-index {
        .opc-block-summary {
            padding: 18px 24px;

            .minicart-items-wrapper {
                max-height: none;
                padding: 15px 0;
            }
        }

        .payment-methods {
            margin: 0;
        }

        .checkout-payment-method {
            .payment-option {
                margin: 0;
            }

            .payment-method-content {
                padding: 0 0 15px 38px;
            }
        }

        .opc-summary-wrapper.custom-slide {
            left: 50px;
        }
    }

}

@include screen($screen__m, $screen__l) {

    .checkout-index-index {
        .opc-wrapper {
            width: 60%;
        }

        .opc-sidebar {
            width: 40%;
        }
    }

}

// General fixed related to welcome splash page
.page-layout-checkout {
    .checkout-container {

        li.checkout-shipping-address,
        li.checkout-shipping-method {
            .offscreen-nav {
                display: none;
            }

            .field--newsletter-subscribe .input-text {
                display: none;
            }


            .field--newsletter-subscribe .input-text + label {
                margin: 0;
                padding-left: 30px;
            }

            .field--newsletter-subscribe .input-text + label {
                position: relative;
                cursor: pointer;

                &::before {
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    box-sizing: border-box;
                    border: 1px solid $color-black;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    cursor: pointer;
                    background-color: transparent;
                    transition: background 250ms ease-in-out;
                }

                &::after {
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background-repeat: no-repeat;
                    background-position: center 5px;
                    background-image: url('../images/icons/check-white.svg');
                    transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
                    cursor: pointer;
                }
            }

            .field--newsletter-subscribe .input-text:checked + label {
                &::before {
                    background-color: $color-black;
                }

                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }

            // Styling inputs
            input[type="text"],
            input[type="password"],
            input[type="url"],
            input[type="tel"],
            input[type="search"],
            input[type="number"],
            input[type="datetime"],
            input[type="email"],
            textarea,
            select {
                min-height: 50px;
                border: 1px solid #8c8c8c !important; // sass-lint:disable-line no-important
                border-radius: 3px;
                color: $color-black !important;

                &.mage-error,
                &[aria-invalid="true"] {
                    border-color: red !important; // sass-lint:disable-line no-important
                }

                ._valid {
                    border: 1px solid #008a05 !important; // sass-lint:disable-line no-important

                    &.valid {
                        border: 1px solid #008a05 !important; // sass-lint:disable-line no-important
                    }
                }
            }

            .checkout-shipping-method {
                @include min-screen($screen__m) {
                    max-width: 500px !important; // sass-lint:disable-line no-important
                    margin: 0 4rem !important; // sass-lint:disable-line no-important
                }
            }

            #checkout-shipping-method .step-title,
            .opc-wrapper .step-title {
                font-size: 2rem;
                font-weight: 500;
                border-bottom: inherit;
                text-transform: uppercase;
            }

            .field._required,
            .field .control._with-tooltip input {
                width: 100%;
            }

            fieldset.field.street {
                width: 100% !important;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .checkout-b2c-switch {
                border: 0;
                margin: 0;
                margin-bottom: 20px;
                margin-top: 20px !important;
                padding: 0;

                .admin__field.admin__field-option {
                    line-height: 0;
                }


                legend {
                    display: none;
                }

                input[type="radio"] {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }

                label {
                    margin: 0;
                    text-decoration: underline;
                    font-size: 16px;
                    cursor: pointer;
                }

                .admin__field-option {
                    input:checked + label {
                        display: none;
                    }
                }
            }

            //fieldset.field.street.admin__control-fields.pc-postcode-show {
            //    justify-content: flex-start !important; // sass-lint:disable-line no-important
            //}
            //
            //.pc-postcode-hide {
            //    display: none !important;
            //}

            #customer-email-fieldset {
                .field {
                    display: flex;
                    flex-wrap: wrap;

                    .control {
                        width: 100%;
                    }

                    label.label {
                        width: 35%;
                    }

                    .field-tooltip.toggle {
                        width: 65%;
                        text-align: right;
                    }
                }
            }

            #customer-email-fieldset {
                @include max-screen($screen__l) {
                    .actions-toolbar {
                        display: flex;
                        flex-wrap: wrap;
                    }

                    .secondary {
                        width: 100%;
                        margin-top: 20px;
                    }
                }
            }

            .field {
                @include max-screen($screen__m) {
                    .label {
                        font-size: 14px;
                    }
                }

                @include max-screen(321px) {
                    .label {
                        font-size: 11px
                    }
                }

                &[name="shippingAddress.company"] {
                    width: 100% !important; // sass-lint:disable-line no-important
                }

                &[name="shippingAddress.telephone"] {
                    display: flex;
                    flex-wrap: wrap;

                    .control {
                        width: 100%;
                    }

                    label.label {
                        width: 45% !important;

                        //&:after {
                        //    content: "*";
                        //    position: relative;
                        //    top: -3px;
                        //    color: #e02b27;
                        //    font-size: 1.2rem;
                        //    margin: 0;
                        //}
                    }

                    .field-tooltip.toggle {
                        width: 55%;
                        text-align: right;
                        position: absolute;
                        top: -21px;
                        right: 0;
                    }
                }

                &[name="shippingAddress.street.1"],
                &[name="shippingAddress.street.2"] {
                    width: calc(30% - 10px) !important; // sass-lint:disable-line no-important

                    @include max-screen($screen__m) {
                        width: calc(30% - 10px) !important; // sass-lint:disable-line no-important
                    }
                }

                &[name="shippingAddress.street.1"] {
                    margin-right: 15px;

                    @include max-screen($screen__m) {
                        width: calc(35% - 10px) !important; // sass-lint:disable-line no-important
                    }
                }

                &[name="shippingAddress.street.0"] {
                    width: calc(40% - 10px) !important; // sass-lint:disable-line no-important
                    margin-right: 15px;

                    @include max-screen($screen__m) {
                        width: calc(40% - 10px) !important; // sass-lint:disable-line no-important
                    }
                }


                &[name="shippingAddress.street.2"] {
                    @include max-screen($screen__m) {
                        width: calc(25% - 10px) !important; // sass-lint:disable-line no-important
                    }
                }

                //&[name="shippingAddress.pc_postcode_disable"] {
                //    width: 100% !important; // sass-lint:disable-line no-important
                //    input {
                //        display: none;
                //        opacity: 0;
                //        visibility: hidden;
                //    }
                //
                //    label {
                //        display: none;
                //    }
                //
                //    .control label {
                //        display: block;
                //        text-decoration: underline;
                //    }
                //}
            }

            // Business order type
            .admin__field.checkout-b2c-switch {
                width: 100%;
                margin-bottom: 30px;
            }

            // Styling for postalcode and housenumber
            .pc_postcode,
            .pc_postcode_addition,
            .pc_postcode[style="display: block;"],
            .pc_postcode[style="display: block;"] + .pc_postcode_addition {
                width: calc(33% - 15px);
                display: inline-block !important;
            }

            .pc_postcode[style="display: none;"],
            .pc_postcode[style="display: none;"] + .pc_postcode_addition {
                display: none !important;
            }

            .pc_postcode[name="shippingAddress.pc_postcode_disable"] {
                width: 100% !important;

                input,
                > label span {
                    display: none;
                }

                label {
                    margin: 0;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            //.fieldset.pc_postcode_fieldset {
            //    display: flex;
            //    flex-direction: row;
            //    flex-wrap: wrap;
            //    justify-content: flex-start;
            //    margin-bottom: 0;
            //
            //    > strong {
            //        width: 100%;
            //    }
            //
            //    .field._required {
            //        width: calc(30% - 5px) !important; // sass-lint:disable-line no-important
            //    }
            //
            //    .field {
            //        &[name="shippingAddress.pc_postcode_housenumber"],
            //        &[name="shippingAddress.pc_postcode_postcode"] {
            //            margin-right: 15px !important; // sass-lint:disable-line no-important
            //        }
            //
            //        &[name="shippingAddress.pc_postcode_housenumber_addition"] {
            //            position: relative;
            //            top: 4px;
            //        }
            //
            //        &[name="shippingAddress.pc_postcode_housenumber_addition"],
            //        &[name="shippingAddress.pc_postcode_housenumber"] {
            //            width: calc(30% - 10px) !important; // sass-lint:disable-line no-important
            //        }
            //
            //        &[name="shippingAddress.pc_postcode_postcode"] {
            //            width: calc(40% - 10px) !important; // sass-lint:disable-line no-important
            //        }
            //    }
            //}


            .field {
                &[name="shippingAddress.telephone"] {
                    width: 100%;
                }
            }

            .fieldset.address {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .field._required:nth-child(1),
                .field._required:nth-child(2) {
                    width: calc(50% - 10px);
                }
            }

            .step-title {
                border: none;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: 1.3px;
            }

            .checkout-shipping-method .step-title {
                margin-bottom: 20px !important; // sass-lint:disable-line no-important
            }
        }
    }
}

.checkout-index-index {
    select {
        color: $color-black !important;
    }

    .fieldset > {
        .field:not(.choice) > .label {
            font-weight: bold;
        }
    }

    .checkout-shipping-method {
        .actions-toolbar {
            .action.primary {
                @include btn-dark();
                border-radius: $button__border-radius;

                &:after {
                    background-color: white !important;
                }

                &:hover {
                    &:after {
                        background-color: $color-black !important;
                    }
                }
            }
        }
    }

    .fieldset {
        .field {
            margin-bottom: 20px;
        }
    }
}

.opc-sidebar {
    .product-item {
        .product-item-name {
            text-align: left;
            margin-top: 1.5rem;
        }
    }
}

.opc-block-shipping-information {
    .shipping-information-title {
        .action-edit {
            &:after {
                display: none;
            }
        }
    }
}

.opc-wrapper {
    .form-discount {
        margin-bottom: 20px;
    }

    form {
        .actions-toolbar {
            width: auto;
            margin-left: 0 !important;
            margin-top: 0;
            margin-bottom: 20px;

            &#shipping-method-buttons-container {
                margin-top: 50px;
            }
        }
    }

    .actions-toolbar > .primary .action {
        @include btn-dark();

        &:after {
            background-color: white !important;
        }

        &:focus {
            color: $color-black !important;
        }

        &:hover {
            &:after {
                background-color: $color-black !important;
            }
        }
    }

    .actions-toolbar > .secondary .action {
        @include btn-outlined();

        &:after {
            background-color: $color-black !important;
        }

        &:hover {
            &:after {
                background-color: white !important;
            }
        }
    }
}

.opc-wrapper {
    .step-content {
        margin: 0;
    }

    .billing-address-details {
        .action.action-edit-address {
            margin-top: 20px;
        }
    }
}

.payment-method-content .billing-address-form {
    .fieldset.address {
        margin-top: 20px;
    }

    .field {
        margin-bottom: 20px !important;
    }
}

.payment-method-content .billing-address-form .field select,
.payment-method-content .billing-address-form .field input[type=checkbox],
#pc-postcode-fieldset .field {
    input {
        position: relative;
        top: 5px;
    }
}

#pc-postcode-fieldset {
    > strong {
        display: block;
        font-weight: 300;
        letter-spacing: 1.3px;
        margin-bottom: 20px;
    }
}

// Footer CMS Block
.checkout-footer {
    background: $color-gray;
    margin: 0 calc(50% - 50vw);
    padding-bottom: 4rem;

    > [data-content-type="row"] {
        max-width: 1440px !important;
        margin: 0 auto;
    }

    * > * {
        color: $color-black !important;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.checkout-onepage-success {
    a.action.primary {
        @include btn;
        @include btn-outlined;
    }
}

// Checkout modal
.checkout-index-index {
    .modal-popup {
        .checkout-b2c-switch {
            order: 0;
            margin: 0;
            margin-bottom: 20px;
            margin-top: 20px !important;
            padding: 0;
            border: none;
        }

        .checkout-b2c-switch input[type="radio"] {
            display: none;
            opacity: 0;
            visibility: hidden;
        }

        .checkout-b2c-switch .admin__field-option input:checked + label {
            display: none;
        }

        .checkout-b2c-switch label {
            margin: 0;
            text-decoration: underline;
            font-size: 16px;
            cursor: pointer;
        }

        .checkout-b2c-switch legend {
            display: none;
        }
    }

    // Billing address alignments
    .checkout-billing-address {
        @include max-screen($screen__m) {
            .billing-address-form {
                fieldset {
                    justify-content: space-between;
                }

                .field {
                    margin-right: 0 !important;
                }
            }

        }
    }

    .checkout-payment-method .payment-method-content .actions-toolbar {
        max-width: 500px;
        margin-right: 4rem !important;
    }

    @include max-screen($screen__m) {
        .checkout-payment-method .payment-method-content {
            padding-left: 15px !important;
        }

        .checkout-payment-method .payment-methods #buckaroo_magento2_afterpay20_DoB {
            width: 81%;
            margin-right: 0 !important;
        }

        .checkout-payment-method .payment-method .actions-toolbar .primary {
            display: flex;
            justify-content: flex-end;
            float: right;
        }

        .checkout-payment-method .payment-method-content .actions-toolbar .action-cancel {
            margin-left: 30px !important;
            margin-right: 0 !important;
        }

        .checkout-payment-method .payment-method-content .actions-toolbar {
            margin-right: 0 !important;
        }
    }


    .checkout-payment-method .payment-method-content .actions-toolbar .action-cancel {
        margin-right: 34px !important;

        @include max-screen($screen__m) {
            margin-right: 31px !important;
        }
    }

    //visible fee
    .buckaroo_magento2_afterpay20,
    .buckaroo_magento2_klarna {
        &.payment-method label.label > span {
            justify-content: flex-start;
        }

        .payment-fee {
            margin-left: 5px !important;
            display: inline-block !important;
        }
    }

    .checkout-payment-method .payment-method .actions-toolbar .primary {
        @include max-screen($screen__m) {
            margin: 0 !important;
            justify-content: center !important;
            width: calc(100% - 15px) !important;
            float: none !important;
        }
    }

    .checkout-payment-method .payment-method-content .actions-toolbar .action-cancel:after {
        background-color: black !important;
    }

    .checkout-payment-method .payment-method-content .actions-toolbar .action-update:hover:after {
        background-color: black !important;
    }
}

.opc-wrapper {
    .shipping-address-item {
        border: 1px solid #ccc;
        border-width: 1px;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }

        &.selected-item {
            border-width: 2px;
        }

        &:before {
            display: none;
        }
    }

    .new-address-popup .action-show-popup {
        border-color: $color-black;
    }
}

.bbd-checkout-shipping-method__price .price .price {
    white-space: nowrap;
}

html[lang="de"] {
    .bbd-checkout-shipping-method__price .price .price {
        display: inline-block;
        text-align: right;

        &::first-letter {
            visibility: hidden;
        }

        &::after {
            content: ' €';
        }
    }
}

.form.methods-shipping {
    button.action.continue.primary:hover:after {
        background-color: $color-black !important;
    }
}

#dhlparcel-shipping-servicepoint-info {
    p strong {
        display: block;
    }
}

#dhlparcel-shipping-servicepoint-button {
    border-color: $color-black !important;
}

#dhlparcel-shipping-deliveryoptions-info {

    select {
        color: $color-black !important;
    }

    .dhlparcel-shipping-delivery-service-option-price {
        background: none;
        padding: 0;
        font-weight: normal;
    }

    .dhlparcel-shipping-delivery-service-option-title {
        font-weight: normal !important;
    }

    .dhlparcel-shipping-delivery-service-option-icon {
        display: none;
    }

    .dhlparcel-shipping-delivery-service-option {
        label {
            margin: 0;
            cursor: pointer;
        }
    }
}

.checkout-index-index {
    @include min-screen($screen__m) {
        .modals-wrapper .modal-popup.dhlparcel-shipping-modal:not(.agreements-modal):not(.popup-authentication) .modal-inner-wrap {
            max-width: 600px !important;
        }
    }
}

.dhlparcel-shipping-modal {
    button:after {
        display: none;
    }
    .MuiIconButton-root:hover {
        background-color: white;
    }

    .MuiIconButton-root:not(.MuiIconButton-sizeLarge):hover,
    .MuiIconButton-root:not(.MuiIconButton-sizeLarge):focus {
        border: none;
        box-shadow: none;
    }

    #dhl-servicepoint-locator-component {
        button.MuiButtonBase-root:not(.MuiIconButton-sizeSmall):hover,
        button.MuiButtonBase-root:not(.MuiIconButton-sizeMedium):hover,
        button.MuiButtonBase-root:not(.MuiIconButton-sizeLarge):hover {
            background: rgb(255, 204, 0) !important;
        }

        button[data-test="filters-button"] {
            &.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeLarge {
                &:hover {
                    background: white !important;
                }
            }
        }

        button.MuiButton-text {
            &.MuiButtonBase-root {
                &:hover {
                    color: rgb(212, 5, 17) !important;
                    background: white !important;
                }
            }
        }

        button.MuiButton-contained {
            &.MuiButtonBase-root {
                box-shadow: none !important;

                &:hover {
                    background: rgb(212, 5, 17) !important;
                }
            }
        }

        .MuiGrid-container {
            button.MuiButtonBase-root:not(.MuiIconButton-sizeSmall):hover,
            button.MuiButtonBase-root:not(.MuiIconButton-sizeMedium):hover,
            button.MuiButtonBase-root:not(.MuiIconButton-sizeLarge):hover {
                background: none !important;
            }

        }
    }

    .mui-10dohqv {
        fill: $color-black !important;
    }

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium:hover,
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium:hover svg path {
        fill: $color-black !important;
    }
}

.checkout-payment-message {
    padding:10px;
    border: 1px solid #27ae60;
    background-color: rgba(39,174,96,0.1);
    font-size: 12px;
    color: #27ae60;
    text-align: center;
    display: none;
    width:100%;
    margin: 10px 0 20px;
    box-sizing:border-box;

    &.is-visible {
        display: block;
    }

    @media only screen and (min-width: 768px) {
        width: 40%;
        max-width: 500px;
        box-sizing: border-box;
        margin: 0 4rem 0 4rem;
    }
}

.postcodenl-autocomplete-menu,
input[class].postcodenl-autocomplete-address-input.postcodenl-autocomplete-address-input-blank {
    background-image: none !important;
}