.product-info-main {
    .price-box {
        margin-top: 5px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @include min-screen($screen__m) {
            margin-top: $indent__base;
        }

        .price-wrapper {
            .price {
                font-weight: $font-weight__light;
                color: $color-black;
                font-size: 1.5rem;

                @include min-screen($screen__l) {
                    font-size: 2.1rem;
                    letter-spacing: 2.1px;
                }
            }
        }

        .price-label {
            display: none !important; // sass-lint:disable-line no-important
        }

        .special-price {
            margin: 0;
        }

        .old-price {
            margin: 0 $indent__s 0 0;
            color: $color-gray-light;

            .price-wrapper {
                .price {
                    font-weight: $font-weight__light;
                    color: $color-gray-light;
                    font-size: 1.5rem;
                }
            }
        }
    }
}


.product-sale-price {
    .amount {
        color: #690A16;
    }
}

.product-sale-label {
    position: absolute;
    z-index: 2;
    right: 20px;
    left: auto;
    top: 0;
    background-color: #690A16;
    color: #ffffff;
    display: flex;
    letter-spacing: 2.5px;
    padding: 0 6px;
    pointer-events: none;
}

.price-with-label {
    display: flex;
    gap: 5px;
    align-items: baseline;
}

@include max-screen($screen__m) {
    .catalog-product-view .product-main .product-info-main {
        position: relative;
    }

    .product-sale-label--mobile {
        display: inline-flex;
    }

    .product-sale-label--desktop {
        display: none;
    }
}

@include min-screen($screen__m) {
    .product-sale-label--mobile {
        display: none;
    }

    .product-sale-label--desktop {
        display: flex;
    }
}
