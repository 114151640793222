.text-icon {

    &:before {
        display: inline-block;
        content: '';
        width: 28px;
        height: 28px;
        float: left;
        margin-right: 15px;
    }

    &.text-icon-mail {
        &:before {
            @include icon('envelope');
        }
    }

    &.text-icon-chat {
        &:before {
            @include icon('chat');
        }
    }

    &.text-icon-phone {
        &:before {
            @include icon('phone-small');
        }
    }

    &.text-icon-facebook {
        &:before {
            @include icon('facebook-alt');
            margin-right: 11px;
        }
    }

    &.text-icon-twitter {
        &:before {
            @include icon('twitter-alt');
        }
    }

    &.text-icon-whatsapp {
        &:before {
            background: url('../images/whatsapp.jpeg') no-repeat;
            background-size: contain;
        }
    }

    &.text-icon-instagram {
        &:before {
            background: url('../images/instagram.jpeg') no-repeat;
            background-size: contain;
        }
    }
}
