.cms-page-view {
    // If page is set to full width instead of 1 column
    .page-main-full-width {
        box-sizing: border-box;
        margin: 0 auto 40px;
        max-width: 1920px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        @include max-screen($screen__l) {
            margin: 0 auto;
            margin-top: 50px !important;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .pagebuilder-column-group {
        .product-items.widget-product-carousel {
            position: relative!important;
        }
    }

    //To keep absolute arrows with their slider within each row
    div[data-content-type=row] {
        position: relative;
    }

    .header.content {
        div[data-content-type=row] {
            position: static;
        }
    }
}

.shop-the-look {
    h2 {
        line-height: 1;
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: $h3__font-size;
        letter-spacing: 3.78px;
        color: $color-black;
        font-weight: $font-weight__regular;

        @include min-screen($screen__m) {
            margin-top: 0;
            font-size: 38px;
            letter-spacing: 5.33px;
        }

        @include min-screen($screen__l) {
            margin-top: 0;
            font-size: $h1__font-size;
            letter-spacing: $h1__letter-spacing;
        }
    }

    &:first-child {
        h2 {
            display: none;
        }
    }
}

.shop-the-look {
    .pagebuilder-column-group .pagebuilder-column-line {
        .pagebuilder-column {
            margin: 1rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @include min-screen($screen__l) {
               &.sticky {
                    position: sticky;
                    align-self: flex-start !important;
                    top: 0;
                }
            }
        }

        @include max-screen($screen__l) {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .pagebuilder-column {
                display: block !important;
                width: 100% !important;
                position: relative;
                //justify-content: center !important;
                align-items: center;
                margin: 0 0 20px;
                padding: 0;
                z-index: 1;
            }
        }
    }

    .pagebuilder-slider {
        [data-slide-name] {
            display: none;
        }

        [data-slide-name="shop-the-look"] {
            display: block;

            .pagebuilder-slide-wrapper {
                position: relative;
                height: 0;
                padding-top: 64.58%;
                width: 100%;
                //background-size: contain !important;
                background-position: center top !important;
                background-repeat: no-repeat;

                @include min-screen($screen__m) {
                    padding-top: 87.58%;
                }
            }
        }

        &.slick-initialized {
            .slick-slide {
                display: initial;
                width: initial;
                height: initial;
            }
        }
    }

    .pagebuilder-slider {
        position: relative!important;

        .slick-dots {
            position: absolute;
            bottom: 0;
            padding: 0;
            width: 100%;
            height: 60px;

            display: flex;
            justify-content: center;
            align-items: center;
            list-style: none;

            li {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            li button {
                display: inline-block;
                width: 15px;
                height: 15px;
                padding: 0;
                background: transparent;
                color: transparent;
                border: 2px solid #fff;
                overflow: hidden;
                text-indent: -999px;
                border-radius: 100%;
                box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
                margin: 0 3px;

                &:hover {
                    border: 2px solid #fff;
                    background: #fff;
                    cursor: pointer;
                }

                &:after {
                    display: none;
                }
            }

            & li.slick-active button {
                border: 2px solid #fff;
                background: #7e7e7e;
                box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
            }
        }

        .slick-arrow {
            display: none!important;

            @include min-screen($screen__s) {
                &.slick-prev {
                    left: 0;
                }

                &.slick-next {
                    right: 0;
                }
            }

            @include min-screen($screen__m) {
                display: inline-block!important;
            }
        }

        .slick-slide .pagebuilder-slide-wrapper {
            position: relative;
            height: 0;
            padding-top: 64.58%;
            width: 100%;
            background-size: cover !important;
            background-position: center top !important;
            background-repeat: no-repeat;

            @include min-screen($screen__m) {
                padding-top: 87.58%;
            }
        }
    }

    .block-products-list {
        .product-item {
            width: 100% !important;
        }

        .product-item-info {
            display: flex;
            position: relative;
            margin-bottom: 1rem;

            @include max-screen($screen__l) {
                justify-content: center;
            }

            .product-item-photo {
                align-items: flex-start;
                margin-bottom: 5px;
                width: 35%;
                max-width: 165px;
                margin-right: 2%;
            }

            .product-item-details {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 60%;

                .product-item-name {
                    margin-top: .5rem;
                    margin-bottom: 1rem;
                    text-align: left;

                    .product-item-link {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }

                .price-box {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    margin-bottom: 1rem;
                    width: auto;

                    .old-price {
                        margin-right: 10px;
                        order: 0;
                    }

                    .normal-price {
                        order: 1;
                        margin-right: 10px;
                    }

                    .product-sale-label {
                        order: 3;
                    }

                }

                .prices-wrapper {
                    align-items: center;
                    display: flex;
                    position: relative;
                    width: auto;

                    .product-sale-label {
                        position: relative;
                        top: -3px;
                        right: inherit;
                        height: 20px;
                    }
                }

                .swatch-attribute-options {
                    text-align: left;
                    max-width: 270px;
                }

                .stock {
                    opacity: 0.5!important;
                    color: #fff!important;
                    background-color: #000!important;
                    cursor: default;
                    pointer-events: none;
                    border: 1px solid #000;
                }
            }
        }

         .product-item div[class^='swatch-opt-'] {
            display: block;
            position: relative;
            padding: 0 .5rem;
        }

        .product-item-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            position: relative;
            bottom: initial;
            background-color: transparent;
            padding: 0;
            margin: 5px 0;
            opacity: 1;

            .product-item-actions {
                display: flex;
                justify-content: flex-start;

                .actions-primary,
                .actions-primary form {
                    opacity: 1;
                    visibility: visible;
                }

                button.tocart,
                .stock.unavailable {
                    @include btn-dark();

                    &:after {
                        background-color: #fff!important;
                    }

                    &:hover {
                        &:after {
                            background-color: #1D1C22 !important;
                        }
                    }
                }

                button.tocart.disabled {
                    @include btn-dark();
                    opacity: 0.5!important;
                    color: #fff!important;
                    background-color: #000!important;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }

        .products-grid .product-item:hover .swatch-attribute.full_size {
            display: block;
        }
    }
}
