$minicart__border-color: $color-gray;
$minicart-image-size: 156px;

.minicart-wrapper {
    @include min-screen($screen__m) {
        margin-left: 0;
    }

    @include max-screen($screen__m) {
        margin-top: -4px;
        margin-right: 10px;
    }

    .action {
        &.showcart {
            margin-bottom: 3px;
            position: relative;

            &.active:before,
            &:before {
                @include icon('cart');
                vertical-align: middle;
            }

            &:hover:before {
                opacity: 0.5;
            }

            .counter.qty {
                @include counter;
                position: static;

                @include max-screen($screen__m) {
                    position: absolute;
                }

                .loader > img {
                    right: auto;
                }
            }

            .loading-mask {
                right: auto;
            }

            .text {
                @include max-screen($screen__m) {
                    clip: unset;
                    height: auto;
                    overflow: auto;
                    width: auto;
                    position: absolute;
                    left: 0;
                    bottom: -12px;
                    font-size: 8px;
                    margin: 0 0 0 calc(-50% + -13px);
                    display: none;
                }
            }
        }

        &.viewcart {
            @include btn-outlined;
            @include lib-button-s;
            width: 100%;

            &:hover {
                text-decoration: none;
            }
        }

        &.close {
            top: 14px;
            right: 14px;

            &:hover {
                color: $color-black;
            }

            &:before {
                font-size: 3rem;
            }

            &:after {
                display: none;
            }
        }
    }

    &:not([data-count]), &[data-count='0'] {
        .block-minicart {
            &:before {
                right: 3px;
            }

            &:after {
                right: 2px;
            }

            @include min-screen($screen__m) {
                &:before {
                    right: 18px;
                }

                &:after {
                    right: 17px;
                }
            }
        }
    }

    .block-minicart {
        border: 1px solid $color-gray-darker;
        box-shadow: 0 40px 70px rgba(0, 0, 0, 0.15);
        margin-top: 17px;
        padding: $indent__xl $indent__base $indent__base;

        @include min-screen($screen__m) {
            margin-top: 32px;
            width: 420px;

            .headroom--not-top & {
                top: 20px;
            }
        }

        &:before {
            left: auto;
            right: 28px;
        }

        &:after {
            border-color: transparent transparent $color-gray-darker transparent;
            right: 27px;
        }

        @include min-screen($screen__m) {
            &:before {
                right: 43px;
            }

            &:after {
                right: 42px;
            }
        }

        .minicart-items-wrapper {
            border: none;
            height: auto !important;
            padding: 0 $indent__base;
            max-height: 300px;

            @include max-screen($screen__s) {
                max-height: calc(100vh - 415px);
            }

            @include min-screen($screen__s) {
                max-height: 380px;
            }
        }

        .minicart-items {
            .product-item-details {
                padding-left: $indent__s;

                .checkout-index-index & {
                    min-height: 100px;
                }

                .price {
                    font-family: $heading__font-family-name__base;
                    font-weight: 300;
                    font-size: 1.3rem;
                    display: inline-block;

                    &::first-letter {
                        visibility: hidden;
                    }
                }
            }

            .action {
                &.edit:before {
                    @include icon('pencil');
                }
            }
        }

        .subtitle {
            display: block;
            font-size: 1.3rem;
            font-weight: $font-weight__light;
            letter-spacing: 1px;
            margin-bottom: 1.8rem;
            margin-top: 0;
            line-height: 1;

            &.empty {
                line-height: $line-height__base;
            }
        }

        .product.actions {
            margin: 0;
            width: auto;
        }

        .items-total {
            display: none;
        }

        .subtotal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            padding-top: 0.2rem;
            padding-bottom: 1.4rem;

            .label {
                font-weight: $font-weight__light;
                font-size: 1.3rem;

                &:after {
                    display: none;
                }
            }

            .amount {
                .price {
                    font-family: $heading__font-family-name__base !important;
                    font-weight: $font-weight__regular;
                    font-size: 1.3rem;
                    display: inline-block;

                    &::first-letter {
                        visibility: hidden;
                    }
                }
            }
        }

        .actions {
            text-align: left;

            .action.checkout {
                @include btn-dark;
                text-align: left;

                &:after {
                    background-color: $color-white !important;
                }

                &:not(.slick-arrow):hover:after {
                    background-color: $color-black !important;
                }
            }
        }

        .product {
            display: flex;
            align-items: flex-start;
            width: 100%;
            padding-left: 0;
        }

        .product-item {
            .product-item-details {
                display: block;
                width: 100%;
            }

            .product-item-name {
                letter-spacing: 1.5px;
                margin-top: 0;
                text-align: left;

                @include max-screen($screen__s) {
                    min-height: 0;
                }

                a {
                    font-size: 1.3rem;
                }
            }

            .product-item-name__subtitle {
                color: $color-gray-dark;
                font-size: 1.2rem;
                font-weight: $font-weight__light;
                letter-spacing: 1.35px;
            }

            .product.options {
                .content {
                    display: block !important;
                    font-size: 1.5rem;
                }
            }
        }

        .block-content {
            > .actions {
                border-top: 1px solid $color-gray;
                padding-top: $indent__s;

                > .primary {
                    margin: 0;

                    .action.primary {
                        @include lib-button-s;
                        margin-bottom: $indent__s;
                    }
                }
            }
        }
    }
}

.checkout-cart-configure {
    .minicart-items .action.delete {
        display: none;
    }
}

.minicart-items {
    .cart.item.message.notice {
        color: $color-black;
        font-weight: $font-weight__semibold;
        padding: 10px 9px;
        background: none;
        margin-left: 80px;
    }

    .cart.item.message.notice>:first-child:before {
        display: none;
    }

    .product-item {
        &:not(:first-child) {
            border-top: 1px solid $minicart__border-color;
        }

        .product-item-photo {
            margin-bottom: 0;
        }
    }

    .product-item-details {
        .details-qty {
            display: inline-block;
            min-width: 2em;
            margin: 6px 0 0 auto;
            font-size: 1.3rem;
            font-weight: $font-weight__light;
            text-align: center;
        }
    }

    .product-item-pricing {
        display: flex;

        .label {
            display: none;
        }
    }

    .action.delete {
        display: block;

        &:before {
            @include icon('trash-light');
            position: relative;
            top: 1px;
            margin-right: 5px;
        }
    }
}

.minicart-usp {
    background-color: $color-gray;
    display: flex;
    padding: 10px 15px;
    position: relative;
    margin-bottom: 20px;

    &:before {
        @include icon('check');
        position: relative;
        top: 6px;
        margin-right: 5px;
    }

    span {
        font-size: 12px;
        margin-left: 5px;
    }
}
