.block-search {
    margin-right: $indent__base;

    @include max-screen($screen__s) {
        margin-top: 0;
    }

    .control {
        border-top: 0;
        padding: 0;
    }

    .field.search .label {
        @include max-screen($screen__m) {
            opacity: 0;
        }
    }

    .label:before {
        @include icon('search');
    }

    .label + .control input {

        @include max-screen($screen__m) {
            position: absolute !important;
            left: 20px;
            right: 20px;
            width: calc(100% - 40px);
            border: 1px solid #8c8c8c;
            border-radius: 3px;
            margin-top: 20px;
        }
    }

    .action {
        // hide buttons
        &.clear, &.close {
            display: none !important
        }

        &.search {
            display: block;
            background: transparent;
            border: none;
            position: absolute;
            right: 20px;
            padding: 10px;

            @include max-screen($screen__m) {
                top: 62px;
            }

            span {
                @extend .sr-only;
            }
        }

        &.search:before {
            @include icon('search');
            display: block;
            vertical-align: middle;
        }

        &.search:not([disabled]):hover:before {
            opacity: 0.5;
        }

        &.search {
            &:after {
                display: none;
            }
        }

        &.advanced {
            display: none;
        }
    }

    .aw-as-sas-popup {
        display: none !important;
    }

    input.input-text {
        &,
        &:focus {
            font-size: 1.4rem;
        }
    }

    @include min-screen($screen__m) {
        max-width: 120px;
        min-width: 160px;
        width: 20vw;

        input {
            width: 100%;
            padding: 0;
            max-width: 120px;
            float: right;
        }

        .action.search {
            padding: 6px 0;
            right: 0;

            &[disabled] {
                opacity: 1;
            }
        }
    }

    @include min-screen($screen__l) {
        width: 100%;
        max-width: 240px;

        input {
            max-width: 240px;
        }
    }
}
