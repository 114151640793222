.swiper-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-container {
    display: none;

    &.swiper-container-initialized {
        display: block;

        .product-items {
            margin: 0 -25px !important;
            @include max-screen($screen__m) {
                margin-left: -10px !important;
            }
        }

        .product-item {
            padding: 0 25px !important;
            // 2.5 products on mobile
            @include max-screen($screen__m) {
                padding: 0 10px !important;
                width: 45% !important;
            }
        }
    }
}

// fix pagebuilder swiper widget
.ves-widget {

    .swiper-slide .slide-inner {
        padding-right: 0 !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: $color-white;
    }
}

.swiper-pagination {
    position: relative;

    @include min-screen($screen__m) {
        display: none;
    }
}

.swiper-pagination-bullet-active {
    background: $color-primary;
}
