.am-tabs-view .product.data.items > .item.content {
    margin-top: 31px !important;
    padding: 25px 0 !important;
}


.amtab-table-container {
    .amtab-cell {
        .amtab-desc {
            color: $color-black !important;
        }
    }
}

.amtab-cell-select {
    .amtab-select {
        color: $color-black !important;
    }
}

.amtab-grid-wrapper.-small {
    .amtab-steps-container {
        .amtab-head {
            color: $color-black !important;
            font-weight: bold !important;
        }
    }
}


.amtab-grid-wrapper.-tops.-men {
    .amtab-step:nth-child(3) {
        right: 0 !important;
        bottom: 70px !important;
        left: auto !important;
        max-width: 176px !important;
    }
}

// Styling table
.amtabs-container {

    @include max-screen($screen__l) {
        .amtabs-container__content {
            &:nth-child(even) {
                margin-top: $indent__base;
            }
        }
    }
    @include min-screen($screen__l) {
        display: flex;
        justify-content: space-between;

        .amtabs-container__content {
            width: 100% !important;
            order: 2;

            &:nth-child(even) {
                width: calc(30% - 10px) !important;
                order: 0;
            }
        }
    }
}

.amtabs-table-container {
    .amtabs-table-wrapper {
        width: 100%;
        overflow-x: auto;
    }
}

.amtabs-table {
    margin-bottom: $indent__base;
    border: 1px solid #eff3f9;
    color: #41362f;

    tr {
        height: 64px;
    }

    tr td {
        font-size: 12px;
    }

    tr:nth-child(odd) {
        background-color: #f9fafc;
    }
}

