.usp-icon {
    margin: $indent__base 0;
    display: flex;

    @include min-screen($screen__m) {
        margin: $indent__xxl auto 0;
        display: block;
        text-align: center;
        max-width: 300px;
    }

    &__image-wrapper {
        flex: 0 0 50px;
        display: flex;
        align-items: flex-start;
        margin: $indent__s 0;

        @include min-screen($screen__m) {
            width: 90px;
            height: 90px;
            margin: 0 auto;
            align-items: center;
        }
    }

    &__image {
        @include object-fit(contain);
        margin: 0 auto;
    }

    &__content {
        @include max-screen($screen__m) {
            padding-left: 1.5rem;
        }
    }

    &__title {
        display: block;
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: $font-weight__heavier;
        font-family: $heading__font-family__base;
        text-transform: uppercase;

        @include min-screen($screen__m) {
            font-weight: $font-weight__regular;
            margin-top: 1.5rem;
            font-size: 2.5rem;
            text-transform: none;
        }
    }
}
