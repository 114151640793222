.column.main {
    .product-items {
        margin-left: 0;
    }
}

.products-grid {
    overflow: visible;

    &:not(.swiper-container) {
        margin: 0 -30px;

        @include max-screen($screen__m) {
            margin: 0;
        }

        /**
            Hack for iOS
         */
        @supports (-webkit-touch-callout: none) {
            margin: 0;
        }

        @include min-screen($screen__m) {
            .product-item {
                padding: 0 30px !important; // sass-lint:disable-line no-important
            }
        }

        .product-items {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .checkout-cart-index & {
                justify-content: center;
            }

            @include max-screen($screen__m) {
                justify-content: space-between !important;

                .product-item {
                    display: flex;
                    justify-content: space-between;

                    &--banner {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            .product-item {
                @include lib-layout-column(1, 0, 100%);
                position: relative;
                margin: 0;
                padding: 0;
            }
        }
    }

    .product-item--banner {
        margin: 10px 0 40px 0 !important; // sass-lint:disable-line no-important
        width: 100% !important; // sass-lint:disable-line no-important
        box-sizing: border-box;

        @include max-screen($screen__m) {
            margin: 20px 0 40px 0 !important; // sass-lint:disable-line no-important
            padding: 0 !important; // sass-lint:disable-line no-important
            display: block !important; // sass-lint:disable-line no-important

            [data-enable-parallax] {
                padding: 0 !important; // sass-lint:disable-line no-important
            }
        }

        ul:not(.slick-slider) {
            justify-content: space-between;

            li {
                width: 100%;
                padding-right: 0;
                height: 100%;
                min-height: 1px;
                font-size: 12px;
            }
        }

        ul,
        ul.slick-slider {
            display: flex;
            width: 100%;
            position: relative !important; // sass-lint:disable-line no-important
            margin: 0 auto;
            padding: 10px 0 !important; // sass-lint:disable-line no-important
            font-size: 1.2rem;
            text-align: center;
            border-top: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            @include list-usps();

            @include max-screen($screen__m) {
                font-size: 11px;
            }

            li {
                font-weight: 400;
                padding: 0 20px 0 0;

                @include max-screen($screen__m) {
                    padding: 0 !important; // sass-lint:disable-line no-important
                }
            }
        }
    }
}

.aw-acp-popup__related {
    .products-grid:not(.swiper-container) {
        .product-item {
            padding: 0 !important;
        }
    }
}

//
//  page-layout-1column
//  _____________________________________________

.page-layout-1column,
.page-layout-2columns-left,
.page-layout-2columns-right,
.page-layout-3columns {
    .block.block-viewed-products-grid {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        .action.tocart.primary {
            width: auto !important;
            @include btn;
            @include btn-dark;

            &::after {
                background-color: white !important;
            }
        }

        action.tocart.primary:hover {
            &::after {
                background-color: black !important;
            }
        }

        .block-title {
            text-align: center;
        }

        .block-title strong {
            font-weight: 400 !important;
            font-size: 20px !important;
            text-transform: uppercase;
            @include lib-heading($_heading_level: h3);

            letter-spacing: 3.78px;
            font-size: $h3__font-size-mobile;

            @include min-screen($screen__m) {
                letter-spacing: 4px;
                font-size: $h3__font-size;
            }

            &.h3-sub,
            em {
                display: block;
                margin-top: $indent__xs;
                color: $color-gray-dark;
                font-family: $heading_sub__font-family-name__base;
                font-weight: $heading_sub__font-weight__base;
                font-style: normal;
                font-size: 1.3rem;
                letter-spacing: 3px;

                @include min-screen($screen__m) {
                    font-size: 1.7rem;
                    letter-spacing: 3.5px;
                }
            }
        }

        .products-grid:not(.swiper-container) {
            .product-item {
                width: percentage(1/4);
                max-width: percentage(1/4);
            }
        }
    }

    .block.widget .products-grid,
    .products-grid:not(.swiper-container) {
        .product-item {
            width: calc(50% - 5px);
            margin: 0 0 $indent__m;
            padding: 0;

            &:nth-child(4n + 1) {
                margin-left: 0 !important; // sass-lint:disable-line no-important
            }

            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }
        }
    }
}

//
//  page-layout-2columns & 3columns
//  Media query > 768px
//  _____________________________________________

@include min-screen($screen__m) {

    .page-layout-1column {
        .columns .column.main {
            padding-bottom: 0;
        }

    }

    .page-layout-1column,
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .block.widget .products-grid,
        .products-grid:not(.swiper-container) {
            .product-item {
                width: percentage(1/2);
                margin-top: 0 !important; // sass-lint:disable-line no-important
            }
        }

        .block.related .products-grid,
        .block.upsell .products-grid {
            .product-item {
                width: percentage(1/4);
            }
        }
    }
}

//
//  page-layout-2columns & 3columns
//  Media query > 1024px
//  _____________________________________________

@include min-screen($screen__l) {

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .block.widget .products-grid,
        .products-grid:not(.swiper-container) {
            .product-item {
                width: percentage(1/3);

                .checkout-cart-index & {
                    width: percentage(1/4);
                }
            }
        }

        .block.related .products-grid,
        .block.upsell .products-grid {
            .product-item {
                width: percentage(1/4);
            }
        }
    }
}

//
//  page-layout-2columns & 3columns
//  Media query > 1440px
//  _____________________________________________

@include min-screen($screen__xxl) {

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .block.widget .products-grid,
        .products-grid:not(.swiper-container) {
            .product-item {
                width: percentage(1/3);

                .checkout-cart-index & {
                    width: percentage(1/4);
                }
            }
        }

        .block.related .products-grid,
        .block.upsell .products-grid {
            .product-item {
                width: percentage(1/4);
            }
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-items .product-item {
                width: percentage(1/3);
            }
        }

        .block.related .products-grid,
        .block.upsell .products-grid {
            .product-item {
                width: percentage(1/4);
            }
        }

        &.checkout-cart-index {
            .products-grid {
                .product-items .product-item {
                    width: 20%;
                }
            }
        }
    }
}


@include max-screen($screen__m) {
    .block.block-viewed-products-grid {
        .product-items {
            display: flex;
        }

        .block-title strong {
            font-size: 20px !important;
        }

        .products-grid:not(.swiper-container) {
            .product-item {
                width: percentage(1/2) !important;
                max-width: percentage(1/2) !important;
            }
        }
    }
}
