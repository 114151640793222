@font-face {
    font-family: 'Noto Serif JP';
    src: local('Noto Serif JP Light'), local('NotoSansJP-Light-Alphabetic'),
    url('../fonts/NotoSansJP-Light-Alphabetic.woff2') format('woff2'),
    url('../fonts/NotoSansJP-Light-Alphabetic.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif JP';
    src: local('Noto Serif JP Medium'), local('NotoSansJP-Medium-Alphabetic'),
    url('../fonts/NotoSansJP-Medium-Alphabetic.woff2') format('woff2'),
    url('../fonts/NotoSansJP-Medium-Alphabetic.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Serif JP';
    src: local('Noto Serif JP'), local('NotoSansJP-Regular-Alphabetic'),
    url('../fonts/NotoSansJP-Regular-Alphabetic.woff2') format('woff2'),
    url('../fonts/NotoSansJP-Regular-Alphabetic.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Prompt';
    src: url('../fonts/subset-Prompt-Light.woff2') format('woff2'),
    url('../fonts/subset-Prompt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('../fonts/subset-Prompt-Regular.woff2') format('woff2'),
    url('../fonts/subset-Prompt-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Prompt';
    src: url('../fonts/subset-Prompt-Medium.woff2') format('woff2'),
    url('../fonts/subset-Prompt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
