.filter-options {
    border-top: 1px solid $filter-options-item__border_bottom_color;
    padding-top: 20px !important;

    .swatch-attribute-options {
        max-height: unset;
    }
}

.filter-title {
    strong {
        font-size: 1.8rem;
        font-weight: $font-weight__regular;
        letter-spacing: 4px;
        text-transform: uppercase;
    }
}

.filter-content {
    .filter-subtitle {
        display: none;
    }
}

.filter-options {
    .aw-layered-nav-popover__action {
        display: none !important;
    }

    .swatch-attribute-options {
        input[type='checkbox']:checked + label .swatch-option {
            box-shadow: 0 0 0 1px #565458;
            outline: none !important;
            border: 3px solid #fff !important;

            &:before {
                content: '';
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2'%3E%3Ccircle id='Oval' fill='%23000000' cx='7' cy='7' r='7'%3E%3C/circle%3E%3Cg id='check-green' transform='translate(4.000000, 4.000000)' stroke='%23FFFFFF'%3E%3Cpolyline id='Path' points='0 3 2.3478 6 6 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-size: cover;
                top: 6px;
                left: 6px;
                width: 17px;
                height: 17px;
                opacity: 1;
                display: block;
                position: absolute;
            }

            &[option-tooltip-value="#000000"]:before,
            &[option-tooltip-value="#17417b"]:before {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='14px' viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='7' cy='7' r='7'%3E%3C/circle%3E%3Cg id='check-green' transform='translate(4.000000, 4.000000)' stroke='%23000000'%3E%3Cpolyline id='Path' points='0 3 2.3478 6 6 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }

        display: flex;
        flex-wrap: wrap;

        .swatch-option-link-layered {
            display: inline-block;

            @include max-screen($screen__m) {
                width: 100%;
            }

            @include min-screen($screen__m) {
                width: 50%;
            }

            label {
                display: inline-block;
                width: calc(50% - 10px);
                margin: 0;
            }
        }
    }
}

.filter-options-item {
    border-color: $color-gray-darker;
    padding-bottom: $indent__base;
    margin-bottom: $indent__base;

    .filter-options-title {
        padding: 0;
        font-size: 1.3rem;
        font-weight: $font-weight__heavier;
        letter-spacing: 2.5px;

        &,
        &:hover {
            .arrow {
                background: none;

                &:after {
                    @include icon('plus');
                    position: relative;
                    top: 2px;
                }
            }
        }

        .reset {
            display: none;
        }

        .tooltip {
            display: inline-block;
            float: none;
            border: 1px solid $color-gray-darker;
            width: 16px;
            height: 16px;
            line-height: 16px;
            font-size: 1.2rem;
            font-weight: $font-weight__light;
            border-radius: 50%;
            text-align: center;
            text-transform: none;
            color: $color-gray-medium;
            letter-spacing: 0;

            &:hover:before {
                bottom: calc(100% + 16px);
            }
        }
    }

    .count {
        font-size: 1.2rem;
    }

    &.active {
        .filter-options-title {
            &,
            &:hover {
                .arrow {
                    background: none;

                    &:after {
                        @include icon('minus');
                        position: absolute;
                        top: 8px;
                    }
                }
            }
        }
    }

    .filter-options-content {
        padding: 0;

        &.filter-options-content--table .items {
            display: flex;
            flex-wrap: wrap;

            .item {
                width: calc(50% - 30px);

                @include screen($screen__m, $screen__l) {
                    width: calc(50% - 15px);
                }
            }
        }

        @include min-screen($screen__m) {
            max-height: 300px;
            overflow: auto;
            overflow-x: hidden;
            padding: 0 5px;
            box-sizing: border-box;
        }

        label {
            font-size: 1.3rem;
            font-weight: $font-weight__light;
            letter-spacing: 1.5px;
        }

        .toggle-list-items {
            display: block;
            font-size: 11px;
            text-decoration: underline;
            cursor: pointer;

            @include min-screen($screen__m) {
                display: none;
            }

            span:last-child {
                display: none;
            }

            &.is-active {
                span:first-child {
                    display: none;
                }

                span:last-child {
                    display: block;
                }
            }
        }

        .items-minimize-filters,
        .items-minimize-swatches-filters {
            @include max-screen($screen__m) {
                max-height: 170px;
                margin-bottom: 5px;
                overflow: hidden;
            }
        }

        .items-minimize-swatches-filters {
            @include max-screen($screen__m) {
                max-height: 195px;
            }
        }

        .items {
            @include lib-clearfix;
            margin-top: 15px;

            .show-more {
                margin-top: $indent__s;
                color: $color-gray-dark !important;
                text-decoration: underline;
                font-size: 1.1rem !important;

                &:hover {
                    text-decoration: none;
                }
            }

            .shaded {
                background-image: none !important;

                &,
                .count {
                    color: inherit !important;
                }
            }
        }

        .item {
            position: relative;

            a {
                text-decoration: none;

                &:hover,
                &:focus {
                    input[type="radio"],
                    input[type="checkbox"] {
                        + label:after {
                            @include icon('check-thicker');
                            position: absolute;
                            left: 3px;
                            top: 6px;
                        }
                    }
                }
            }

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                + label {
                    padding-left: 30px;
                    margin-left: 0;
                    font-weight: $font-weight__light;
                    cursor: pointer;

                    &:before {
                        content: '';
                        width: 16px;
                        height: 16px;
                        background: white;
                        border: 1px solid $color-gray-darker;
                        position: absolute;
                        left: 0;
                        top: 2px;
                    }
                }

                &:checked + label {
                    font-weight: $font-weight__light;

                    &:before {
                        border-color: $color-gray-dark;
                    }

                    &:after {
                        @include icon('check-thicker');
                        position: absolute;
                        left: 3px;
                        top: 6px;
                    }
                }
            }
        }
    }

    &#aw-filter-size {
        .filter-options-content {
            .items {
                margin-top: 20px;
                margin-bottom: 5px;
            }

            .item {
                width: percentage(1/3);
                float: left;
                margin: 5px 0;

                .count {
                    display: none;
                }
            }
        }
    }

    .aw-ln-range-slider-wrapper {
        margin: 25px 0 20px;

        .aw-ln-slider-button {
            @include btn;
            border-color: $color-black;
            margin-top: $indent__base;
        }

        .ui-slider-range {
            background: $color-gray-dark;
        }

        .aw-ln-inputs-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .ui-slider-horizontal {
            height: 4px;
        }

        .ui-slider-handle {
            top: -9px;
            border-radius: 100%;
            background: $color-black;
            border-color: white;
            border-width: 3px;
            box-shadow: 0 0 0 1px $color-black;

            &:before {
                display: none !important;
            }
        }

        .aw-ln-field-label {
            width: auto;
            margin-right: $indent__xs;
        }

        .aw-ln-field-value-wrapper {
            &:last-child {
                justify-content: flex-end;
            }
        }
    }
}

.filter-title {
    margin-bottom: 0 !important;

    &:after {
        @include icon('close-white');
        display: none;
        transition: 0.2s transform linear;
        transform: scale(1.5);
    }

    &:hover {
        &:after {
            transform: rotate(180deg) scale(1.5);
        }
    }
}

.filter-shadow {
    display: none;

    .filters-active & {
        @include max-screen($screen__m) {
            display: block;
        }
    }
}

@include max-screen($screen__m) {
    .sidebar-main {
        display: block;
        position: fixed;
        background: white;
        height: 100%;
        left: 0;
        top: -120vh;
        right: 0;
        z-index: 99999;
        overflow-y: auto;
        overflow-x: hidden;
        transition: 0.2s top linear;

        .filter-title {
            padding: $indent__s $indent__base;
            background: $color-black;
            color: white;
            cursor: pointer;
            position: relative;

            &:hover {
                background: $color-gray-dark;
            }

            &:after {
                display: inline-block;
                position: absolute;
                right: 15px;
                top: 20px;
            }
        }

        .filter-options {
            padding: 0 $indent__base;

            @include max-screen($screen__m) {
                padding-bottom: 44px !important;
            }
        }
    }

    html.filters-active {
        overflow: hidden;

        body {
            overflow-y: hidden;
        }

        .page-header {
            display: none !important;
        }

        .sidebar-main {
            top: 0;
        }
    }
}

@include max-screen($screen__m) {
    .filter-current,
     .filter-actions {
         padding: 0 20px;
     }

    .filter-current {
        margin-top: 20px;
    }
}

.block-apply-filter-button {
    width:  100%;
    background-color: black;
    color: white;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    padding: 15px 5px;
    text-align: center;
    display: none;
    min-height: 34px;
    text-transform: inherit;
    font-weight: 300;
    z-index: 1;

    .filters-active & {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    @include min-screen($screen__m) {
        display: none !important;
    }

    span {
        pointer-events: none;
    }

    &::after {
        display: none;
    }
}

.filter-content {
    .current-min-value,
    .current-max-value {
        display: none;
    }
}

