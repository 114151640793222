.size-table {
    table {
        font-size: 1.6rem;
        border-width: 0;
        height: auto !important; // sass-lint:disable-line no-important

        @include max-screen($screen__s) {
            display: block;
            overflow-x: auto;
        }

        tr {
            border-width: 0;
            white-space: nowrap;

            &:first-child {
                background: $color-black;
                color: $color-white;
                white-space: normal;
            }
        }

        td {
            border-width: 0;
            border-bottom: 1px solid $color-black;
        }
    }
}