.mw-store-locator-location__wrapper {
    margin-top: 10px;
    padding: 30px 20px;
    background-color: $color-gray-lightest;
}

.mw-store-locator-location__title {
    margin-top: 0;
}

.mw-store-locator-location__button,
.mw-sl__store__select {
    margin-bottom: 0 !important;
    border-width: 1px !important;
    font-weight: 500;
    transition: background-color 200ms ease-in-out;
    &::before {
        display: none;
    }

    &:after {
        background-color: $color-black !important;
    }

    &:hover {
        background-color: $color-black !important;;
        color: white !important;
    }

    &:hover {
        &:after {
            background-color: white;
        }
    }
}

.bbd-checkout-shipping-method__container.mageworxpickup + .row-error .message.error {
    background: $color-gray-lightest;
    color: black;
    padding: 10px 20px 10px 40px;

    div:before {
        color: #999;
    }
}
