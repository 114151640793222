.blog-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 1em;
}

.blog-pagination-item {
    display: inline-block;
    padding: 0.15em 0.5em;
}

.blog-pagination-page-current {
    font-weight: bold;
    text-decoration: none;
}
