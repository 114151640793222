.ampromo-popup-container {
    .ampromo-gallery {
        margin: 0;
    }

    .slick-track {
        display: flex;
    }

    .slick-slider .slick-arrow.slick-next {
        right: 0px !important;
        background-image: none !important;
    }

    .slick-slider .slick-dots {
        display: none !important;
    }

    .slick-slider .slick-arrow.slick-prev {
        left: 0px !important;
        background-image: none !important;
    }

    .slick-slider .slick-arrow.slick-next {
        right: 0px !important;
        background-image: none !important;
    }

    .ampromo-item-buttons {
        .action.tocart.primary {
            @include btn-dark();
            font-size: 12px;
            padding: 10px 20px;
        }
    }

    .ampromo-title {
        a {
            text-decoration: none;
            font-weight: 600;
        }
    }

    .ampromo-item.-selected,
    .ampromo-item:hover {
        background: transparent !important;
    }

    .ampromo-item .fieldset .field .label {
        width: auto;
        text-align: left;
        display: flex;
    }

    .ampromo-item .fieldset .field {
        display: flex;
        flex-direction: column;
    }

    .ampromo-item .fieldset .field .control {
        width: 100% !important;
    }

    .ampromo-item .fieldset .field .control select {
        font-size: 14px;
    }
}
