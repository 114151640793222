// Specific Pagebuilder styling
div[class^='pagebuilder-'] {
    // Headings
    h1 + h1,
    h1 + h2,
    h2 + h2,
    h2 + h3,
    h3 + h3 {
        margin: -$indent__s/2 0 $indent__xl 0;
    }

    // Buttons
    [data-content-type="buttons"] {
        margin-bottom: $indent__xl !important;

        @include max-screen($screen__m) {
            margin-bottom: $indent__base !important;
        }

        .btn-icon-link {
            margin-bottom: $indent__xl !important;
        }
    }

    .btn:not(:last-child) {
        margin-right: $indent__base;
        @include max-screen($screen__m) {
            margin-right: 6px !important;
        }

        @media only screen and (max-width: 348px) {
            margin-right: 0 !important;
        }
    }

    .btn + .btn {
        margin-left: $indent__base;
        @include max-screen($screen__m) {
            margin-left: 6px !important;
        }

        @media only screen and (max-width: 348px) {
            margin-left: 0 !important;
        }
    }

    // Images
    img {
        max-width: 100%;
        box-sizing: border-box;
    }

    // Embeds
    iframe {
        max-width: 100%;
    }

    .btn {
        &:hover {
            a {
                color: $color-white;
            }
        }

        a {
            text-decoration: none;

            .pagebuilder-hero-large-row & {
                @include max-screen($screen__m) {
                    color: $color-white;
                }
            }
        }

        a:hover {
            color: $color-white;
        }
    }
}

.pagebuilder-full-img {
    img {
        width: 100%;
    }
}

@include min-screen($screen__m) {
    .pagebuilder-hero-white-bg {
        background: white;
        padding: 40px !important;
        position: relative;
        top: 70px;
    }
}

@include max-screen($screen__m) {
    .pagebuilder-column-group,
    .pagebuilder-column-line {
        flex-direction: column;
    }

    .pagebuilder-column {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        box-sizing: border-box;

        .paragraph {
            word-break: break-word;
        }
    }
}

@include screen($screen__m, $screen__l) {
    .pagebuilder-column-tablet {
        width: 100% !important;
    }
}

// Create a list of images inside a ul tag
.column-image-list {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0 -$indent__base;
        padding: 0;

        li {
            margin: 0 $indent__base;

            img {
                height: auto !important;
            }
        }
    }
}

// always center align content;
.row-inner {
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;

    > div {
        display: flex;
        flex: 0 1 auto;
        flex-flow: row wrap;
        min-width: 0;
        align-items: center;

        & > div {
            flex: 0 1 auto;
            min-width: 0;
            width: 100%;
        }
    }
}

// Border-styling
.border-top {
    border-top: 1px solid $color-gray-darker !important;
}

.border-bottom {
    border-bottom: 1px solid $color-gray-darker !important;
}

.container-wide {
    margin-left: -#{$indent__base};
    margin-right: -#{$indent__base};
    overflow: hidden;

    @include min-screen($screen__m) {
        margin-left: 0;
        margin-right: 0;
    }
}

.container-top {
    margin-top: -#{$page-main__offset-top};

    @include min-screen($screen__m) {
        margin-top: -#{$page-main__offset-top-desktop};
    }
}

.align-items-center {
    @include min-screen($screen__m) {
        display: flex;
        align-items: center;

        .block-content {

            > h1, > h2, > h3, > h4, > h5, > h6 {

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

// Visibility utilities
.pagebuilder-mobile-hidden {
    @include max-screen($screen__m) {
        display: none;
    }
}

.pagebuilder-mobile-only {
    display: none;

    @include max-screen($screen__m) {
        display: block;
    }
}

.pagebuilder-row-divider {
    text-align: center;
}

@for $i from 0 to 10 {
    .pagebuilder--margin-top-#{$i*10} {
        margin-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }

    .pagebuilder--margin-bottom-#{$i*10} {
        margin-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }

    .pagebuilder--padding-top-#{$i * 10} {
        padding-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }

    .pagebuilder--padding-bottom-#{$i * 10} {
        padding-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
    }
}

@include max-screen($screen__m) {
    @for $i from 0 to 10 {
        .pagebuilder--margin-top-#{$i * 10}-mobile {
            margin-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pagebuilder--margin-bottom-#{$i * 10}-mobile {
            margin-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pagebuilder--padding-top-#{$i * 10}-mobile {
            padding-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
        .pagebuilder--padding-bottom-#{$i * 10}-mobile {
            padding-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
        .pagebuilder--padding-left-#{$i * 10}-mobile {
            padding-left: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
        .pagebuilder--padding-right-#{$i * 10}-mobile {
            padding-right: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
    }
}

@include min-screen($screen__m) {
    @for $i from 0 to 10 {
        .pagebuilder--margin-top-#{$i * 10}-desktop {
            margin-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pagebuilder--margin-bottom-#{$i * 10}-desktop {
            margin-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pagebuilder--padding-top-#{$i * 10}-desktop {
            padding-top: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }

        .pagebuilder--padding-bottom-#{$i * 10}-desktop {
            padding-bottom: #{$i * 10}px !important; // sass-lint:disable-line no-important
        }
    }
}

div[data-content-type="row"] {
    &[data-appearance="full-width"],
    &[data-appearance="full-bleed"] {
        position: relative;
    }
}

@include min-screen($layout__max-width) {
    div[data-content-type="row"] {
        &[data-appearance="full-width"],
        &[data-appearance="full-bleed"] {
            display: block !important;
            width: 100vw;
            left: 50%;
            right: 50%;
            margin-left: -50vw !important;
            margin-right: -50vw !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &[data-appearance="full-width"] {
            .row-full-width-inner {
                @include layout-inner;
            }
        }
    }
}

@include screen($screen__xl, 1920px) {
    div[data-content-type="row"] {
        &[data-appearance="full-width"] {
            .row-full-width-inner {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
            }
        }
    }
}
