// Product tiles
.product-item {
    .price-box {
        .symbol {
            display: none;
        }
    }
}

.catalog-product-view {
    .price-box {
        .symbol {
            display: none;
        }
    }

}


.minicart-items {
    .product-item-pricing {
        margin-left: -13px;
    }
}

//html:not([lang="de"]) {
//    .minicart-items {
//        .product-item-pricing span::first-letter {
//            opacity: 0 !important;
//        }
//    }
//
//    p.aw-acp-popup__result-price:first-letter {
//        opacity: 0;
//        margin-left: -25px;
//    }
//
//    .table-wrapper {
//        td.col.price:first-letter {
//            opacity: 0;
//        }
//
//        td.amount:first-letter,
//        td.col.subtotal:first-letter {
//            opacity: 0;
//        }
//    }
//
//    .price-including-tax::first-letter,
//    .amount.price-container:first-letter {
//        opacity: 0;
//    }
//}
