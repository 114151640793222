//
//  Fonts
//  ---------------------------------------------

//  Font families
$font-family-name__base			: 'Prompt';
$font-family__base				: $font-family-name__base, $font-family__sans-serif;
$heading__font-family-name__base: 'Prompt';
$heading__font-weight__base     : 300;
$heading__font-family__base		: $heading__font-family-name__base, $font-family__base;


$heading_sub__font-family-name__base: 'Noto Serif JP';
$heading_sub__font-weight__base: 300;

$font-size__base: 15px;
$font-size__l: 17px;
$font-size__s: 13px;

//  Weights
$font-weight__light             : 300;
$font-weight__regular           : 400;
$font-weight__heavier           : 500;
$font-weight__semibold          : 600;
$font-weight__bold              : 700;

// Line heights
$line-height__base              : 1.6;

//  Colors
$text__color                    : $color-black;

//  Indents
$indent__xxl                    : $indent__base * 3; // 60px

//
//  Borders
//  ---------------------------------------------
$border-color__base             : $color-gray-dark;
$border-color__secondary        : $color-gray;
$tooltip__border-color          : $border-color__secondary;

//
//  Links
//  ---------------------------------------------

$link__color                    : $color-primary;
$link__text-decoration          : underline;

$link__visited__color           : $link__color;
$link__visited__text-decoration : none;

$link__hover__color             : $link__color;
$link__hover__text-decoration   : none;

$link__active__color            : $link__color;
$link__active__text-decoration  : underline;

//
//  List
//  ---------------------------------------------

$list__margin-bottom: 0;

// quick fix
ul > li {
    margin-bottom: 0 !important;
}

//
//  Headings
//  ---------------------------------------------

$heading__color__base           : $color-primary;

$h1__font-size                  : 4rem;
$h1__font-color                 : $heading__color__base;
$h1__font-family                : $heading__font-family__base;
$h1__letter-spacing             : 11.6px;
$h1__margin-bottom              : $indent__base;
$h1__font-weight                : $font-weight__light;
$h1__font-size-desktop          : $h1__font-size;
$h1__font-size-mobile           : 2.7rem;
$h1__line-height                : $line-height__s;

$h2__font-size                  : 2.8rem;
$h2__font-color                 : $heading__color__base;
$h2__font-family                : $heading__font-family__base;
$h2__font-weight                : $font-weight__regular;
$h2__font-size-mobile           : 2rem;
$h2__margin-bottom              : $indent__base;
$h2__line-height                : $line-height__s;

$h3__font-size                  : 1.8rem;
$h3__font-color                 : $heading__color__base;
$h3__font-family                : $heading__font-family__base;
$h3__font-weight                : $font-weight__regular;
$h3__font-size-mobile           : 1.7rem;
$h3__margin-bottom              : $indent__base;
$h3__line-height                : $line-height__s;

$h4__font-size                  : 1.7rem;
$h4__font-color                 : $heading__color__base;
$h4__font-family                : $heading__font-family__base;
$h4__font-weight                : $font-weight__regular;
$h4__font-size-mobile           : $h4__font-size / 1.5;
$h4__margin-bottom              : $indent__s;
$h4__line-height                : $line-height__s;

$h5__font-size                  : 1.65rem;
$h5__font-color                 : $heading__color__base;
$h5__font-family                : $heading__font-family__base;
$h5__font-weight                : $font-weight__regular;
$h5__font-size-mobile           : $h5__font-size / 1.5;
$h5__margin-bottom              : $indent__s;
$h5__line-height                : $line-height__s;

$h6__font-size                  : 1.6rem;
$h6__font-color                 : $heading__color__base;
$h6__font-family                : $heading__font-family__base;
$h6__font-weight                : $font-weight__regular;
$h6__font-size-mobile           : $h6__font-size / 1.5;
$h6__margin-bottom              : $indent__s;
$h6__line-height                : $line-height__s;
