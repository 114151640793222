span.product-label {
    background-color: transparent;
    color: white;
    display: flex;
    letter-spacing: 2.5px;
    padding: 0;
    pointer-events: none;

    .product-label-discount {
        background-color: $color-black;
        padding: 0 6px;
    }

    .product-label-attribute--text {
        padding: 0 6px;
    }
}

.product-label__content {
    font-family: $heading__font-family-name__base;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 300;
}

.product-item {
    .product-label {
        left: $indent__base;
        right: auto;
        top: 0;

        @include max-screen($screen__s) {
            left: 0;

            .product-label__content {
                font-size: 11px;
            }
        }
    }
}

.product.media {
    position: relative;

    .product-label {
        position: absolute;
        left: $indent__base;
        right: auto;
        top: 0;
        z-index: 10;
    }
}

.product.details.product-item-details {
    .product-item-name {
        margin-top: 0.6rem;
        font-size: 1.5rem;

        @include max-screen($screen__s) {
            min-height: 40px;
        }
    }

    .product-label {
        display: inline-flex;
        position: relative;
        left: 0;
        min-height: inherit;
    }
}
.product-item-details__row {
    .product-label__content {
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.catalog-category-view {
    .product-item-details__row {
        .product-label__content {
            max-width: 100%;

            @include min-screen($screen__l) {
                max-width: 10vw;
            }
        }
    }

}
