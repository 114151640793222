.headroom {
    will-change: transform, box-shadow;
    transition: transform, box-shadow 200ms linear;
}

body.offscreen-nav-open .headroom,
.headroom--pinned {
    transform: translateY(0%);
}

.headroom--unpinned {
    transform: translateY(0);
}

.is-header-sticky:not(.catalog-product-view):not(.checkout-cart-index):not(.catalog-category-view):not(.catalogsearch-result-index):not(.checkout-index-index) {
    .page-main {
        margin-top: calc(#{$header-height-mobile-searchbar-sticky} + 35px) !important;

        @include min-screen($screen__m) {
            margin-top: calc(#{$header-height-desktop-sticky} + 35px) !important;
        }
    }
}

.catalog-category-view.is-header-sticky,
.catalogsearch-result-index.is-header-sticky {
    .page-main {
        margin-top: calc(#{$header-height-desktop-sticky} + 35px) !important;
    }
}

.is-header-sticky  {
    // PDP
    &.catalog-product-view {
        .page-main {
            //Height + Include the 10px padding + the uppsell bar of 35px
            margin-top: calc(#{$header-height-mobile-searchbar-sticky} + 45px) !important;

            @include min-screen($screen__m) {
                margin-top: calc(#{$header-height-desktop-sticky} + 35px) !important;
            }
        }
    }

    //Checkout
    &.checkout-cart-index {
        .page-main {
            margin-top: calc(#{$header-headroom-category-view-mobile} + 35px) !important;

            @include min-screen($screen__m) {
                margin-top: calc(#{$header-height-desktop-sticky} + 35px) !important;
            }
        }
    }

    &.checkout-index-index {
        .page-main {
            margin-top: 0 !important;
        }
    }
}
