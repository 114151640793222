.product-combinations-section {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 30px 0;
    box-sizing: border-box;
    position: relative;

    @include min-screen($screen__m) {
        width: 100%;
        max-width: $screen__fhd;
        margin: 0 auto;
        padding: 50px 0;
    }
}

.product-combination-section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include min-screen($screen__m) {
        margin: 0 50px 20px;
    }
}

.product-combinations-section__next {
    @include btn-dark;
    @include landingpage-button-after;
    position: relative;
    margin-top: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;

    @include min-screen($screen__m) {
        position: absolute;
        right: 15vw;
        bottom: 0px;
        z-index: 1;
    }
}

.product-combination-section__product-information {
    display: none;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    z-index: 3;
    transition: transform 250ms ease-in-out;

    p {
        margin: 0;
        text-align: right;
        transform: translate3D(10px, 25px, 0);
        text-transform: uppercase;
    }

    span {
        display: block;
        background-color: white;
        padding: 5px;
        transition: transform 250ms ease-in-out;

        &:last-child {
            @include landingpage-subtitle;
            display: inline-block;
            margin-top: 5px;
        }
    }

    &:focus {
        text-decoration: none;
    }

    &:hover {
        transform: translateY(-10px);

        span {
            transform: translateY(-5px);
        }
    }

    @include min-screen($screen__m) {
        display: flex;
    }
}

.product-combinations-section__title {
    color: #615E63;
    transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    transition-delay: 450ms;
    text-align: center;

    @include min-screen($screen__m) {
        font-size: 30px;
        line-height: 40px;
        max-width: 50%;
        transform: translateX(-50px);
        opacity: 0;
        margin-top: 0;
        text-align: left;
        font-weight: 600;
    }

    @include min-screen($screen__xl) {
        font-size: 40px;
        line-height: 50px;
    }
}

.product-combinations-section__list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    flex-wrap: wrap;

    @include min-screen($screen__m) {
        justify-content: center;
    }
}

// Modifier if section is inview
.section-inview {
    .product-combinations-section__title {
        transform: translateX(0);
        opacity: 1;
    }

    .product-combinations-section__next {
        visibility: visible;
        opacity: 1;
    }
}
