#blog-related-posts {
    margin-top: 40px;

    .blog-block-title {
        margin-bottom: 40px;
        display: block;
        @extend .h3;
    }
}

.block-related-posts {
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: ($indent__s * -1);
    margin-left: ($indent__s * -1);

    .block-related-posts-item {
        box-sizing: border-box;
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: $indent__s;
        padding-left: $indent__s;
        padding-bottom: 15px;

        .block-related-post-link {
            @extend .h4;
            text-decoration: none;
        }

        .related-post-featured-image-link {
            height: 0;
            overflow: hidden;
            padding-top: (9/16) * 100%;
            position: relative;
            display: block;

            & > img {
                display: block;
                transition: transform .3s ease;
                @include object-fit(cover);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &:hover {
            .related-post-featured-image-link {
                & > img {
                    transform: scale(1.05);
                }
            }
        }

    }
}

@include min-screen($screen__l) {
    .block-related-posts {
        .block-related-posts-item {
            flex-basis: (100% / 3 * 1);
            max-width: (100% / 3 * 1);
        }

    }
}
