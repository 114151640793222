.offscreen-nav,
.page-header,
.page-footer,
{
    .switcher {

        strong {
            text-transform: uppercase;
            vertical-align: middle;
            font-size: 1.5rem;
        }

        .options .action.toggle {

            &, &.active {
                &::after {
                    @include icon('chevron-down');
                    vertical-align: middle;
                    will-change: transform;
                }
            }

            &.active::after {
                transform: rotate(180deg);
            }
        }

    }

}

.offscreen-nav .switcher {
    width: 100%;
}

.geoip-popup {
    background: $color-white;
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
    left: 50%;
    max-width: 80rem;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    z-index: 99999;

    .geoip-popup_content {
        background: $color-white;
        max-width: calc(100% - 40px);
        padding: 60px 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .btn {
            margin-top: 2rem;
        }
    }

    .geoip-popup_close {
        color: white;
        display: block;
        font-size: 4rem;
        font-weight: 300;
        height: 30px;
        line-height: 1;
        position: absolute;
        right: 1.5rem;
        text-decoration: none;
        top: 1.5rem;
        width: 30px;
        z-index: 1;
        cursor: pointer;

        svg {
            bottom: 0;
            fill: currentColor;
            filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }

    .geoip_popup_image {
        display: none;
    }

    @media (min-width: $screen__m) {

        .geoip_popup_image {
            display: block;
            bottom: 0;
            max-width: 30rem;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: -1;

            img {
                height: 100%;
                position: absolute;
                right: 0;
            }
        }

        .geoip-popup_content {
            max-width: 41.5rem;
            min-height: 37rem;
        }

    }

}
