$socials: facebook, instagram, pinterest, youtube, linkedin, twitter; /* Used in @each */

.social-link {
    .pagebuilder-button-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        background: $color-white;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.12);
        border-radius: 18px;
        margin: 0 6px;

        &:hover {
            background-color: $color-gray-dark;
        }
    }

    @each $social in $socials {
        &.social-link--#{$social} {
            .pagebuilder-button-link {
                &:before {
                    @include icon($icon: $social);
                }

                &:hover {
                    &:before {
                        @include icon($icon: #{$social}-hover);
                        animation: shake-top 0.5s linear both;
                    }
                }
            }

            span {
                display: none;
            }
        }
    }

    &.social-link--tiktok {
        .pagebuilder-button-link {
            &:before {
                background: url('../images/tiktok.svg') no-repeat;
                content: '';
                display: block;
                width: 19px;
                height: 20px;
            }

            &:hover {
                &:before {
                    background: url('../images/tiktok-hover.svg') no-repeat;
                    animation: shake-top 0.5s linear both;
                }
            }

            span {
                display: none;
            }
        }
    }
}
