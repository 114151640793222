//
//  Grid
//  ---------------------------------------------

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .account.page-layout-2columns-left,
    .account.page-layout-2columns-right,
    .page-layout-customer-service-sidebar {
        .sidebar-main,
        .sidebar-additional {
            width: 35%;
            float: left;
            order: 1;
        }

        .column.main {
            width: 65%;
            float: right;
            order: 2;
        }
    }

    .page-layout-customer-service-sidebar {
        .sidebar-additional {
            margin: 0;
            padding: 0;
            clear: none;
        }
    }
}

@include min-screen($screen__l) {
    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .account.page-layout-2columns-left,
    .account.page-layout-2columns-right,
    .page-layout-customer-service-sidebar {
        .sidebar-main,
        .sidebar-additional {
            width: 25%;
            float: left;
        }

        .column.main {
            width: 75%;
            float: right;
        }
    }

}
