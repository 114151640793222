
@mixin dropdown-defaults() {
    right: 0;

    li {
        font-size: 1.3rem;
    }

    a {
        display: block;
        padding: 8px;
        text-decoration: none;
    }
}

@mixin dropdown-defaults-right($offset: 10px) {
    @include dropdown-defaults;

    &:before {
        right: $offset;
        left: auto;
    }

    &:after {
        right: $offset - 1;
        left: auto;
    }
}