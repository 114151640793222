.search-autocomplete {
    transform: translateY(37px);

    @include max-screen($screen__m) {
        transform: translateY(62px);
        left: 0;
    }

    .qs-option-info-container {
        .qs-option-name {
            font-size: 1.4rem;
            font-weight: $font-weight__light;
            line-height: $line-height__s;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $color-black;
        }
    }

    .product-item .price-box {
        justify-content: flex-end !important;
        margin-top: 5px;
        font-family: $font-family-name__base;
        font-weight: 300;
        font-size: 14px;
    }

    @include min-screen($screen__m) {
        width: 340px !important;
        max-height: 500px;
        overflow: auto;
    }

    #qs-option-1 {
        border-top: 1px solid $color-black;
    }

    ul {
        li.qs-suggestion-heading {
            pointer-events: none;
        }
    }
}
