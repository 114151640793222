.block.aw_blog_related_products {

    .title {
        margin-bottom: 40px;

        strong {
            @extend .h3;
        }
    }

    .product-item-info{
        max-width: 100% !important;

        .actions-primary{
            margin-bottom: 0;
            margin-top: 10px;
            width: 100%;
        }
    }


    .products.product-items {
        margin: 0 -30px;
    }

}
