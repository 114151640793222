.product-landing-indicator {
    position: fixed;
    bottom: calc(50vh - 80px);
    right: 20px;
    display: none;
    flex-direction: column;
    text-align: center;
    z-index: 3;
    display: none;

    @include min-screen($screen__m) {
        display: flex;
        opacity: 1;
        transform: translateX(0px);
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }

    &.is-hidden {
        transform: translateX(100px);
        opacity: 0;
    }
}

.product-landing-indicator__label {
    display: block;
    margin: 10px 0;
}

.product-landing-indicator__button {
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;
    transition: opacity 250ms ease-in-out;

    svg {
        transition: transform 250ms ease-in-out;
    }

    &:hover {
        &:first-child {
            svg {
                transform: translateY(-5px);
            }
        }

        &:last-child {
            svg {
                transform: translateY(5px);
            }
        }
    }

    &.is-hidden {
        pointer-events: none;
        opacity: 0;
    }
}
