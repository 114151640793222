.nav-sections {
    @include min-screen($screen__m) {
        margin-bottom: 0;
    }
}

.nav-sections-item-content > .navigation {
    display: none !important;
}

.header.nav {
    display: none;

    .dropdown-widget {
        .action.toggle {
            &:after {
                display: none;
            }
        }

        &:hover {
            .action.toggle {
                color: $color-primary-lighter;
            }
        }

        ul.dropdown {
            li {
                display: block;
                padding: 0;

                span a,
                a {
                    display: block;
                    padding: $indent__xs $indent__s;
                    font-weight: $font-weight__regular;
                }
            }

            &:before,
            &:after {
                display: none;
            }
        }
    }

    @include min-screen($screen__xl) {
        display: inline-block;
        margin-right: auto;
        font-size: 1.5rem;

        ul {
            list-style: none;
            margin-bottom: 0;
            padding: 0;
        }

        li {
            float: left;
            font-weight: $font-weight__heavier;
            height: $header-height-desktop-sticky;
            letter-spacing: 2px;
            line-height: $header-height-desktop-sticky;
            margin-bottom: 0;
            padding-right: 30px;
            text-transform: uppercase;

            span a,
            a {
                text-decoration: none;
                color: $color-primary;
                transition: color 200ms ease-in-out;
                position: relative;
                font-size: 1.3rem;

                &:after {
                    width: 0%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-bottom: 1px solid $color-primary-lighter;
                    content: '';
                    transition: width 300ms ease-in-out;
                }
            }

            &:hover {
                > span a,
                > a {
                    color: $color-primary-lighter;

                    &:after {
                        width: 100%;
                    }
                }

                .block-static-block {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .block-static-block {
            background-color: white;
            box-shadow: 0 26px 40px 5px rgba($color-primary, 0.1);
            color: $text__color;
            display: none;
            visibility: hidden;
            left: -20px;
            margin: 0;
            padding: 30px 0;
            position: absolute;
            right: -20px;
            transition: opacity 1.3s ease-in-out 0.3s, visibility 1s ease-out;
            opacity: 0;

            @include screen($screen__xl, 1920px) {
                padding-left: 20px;
                padding-right: 20px;
            }

            @include min-screen($screen__xl) {
                max-height: calc(100vh - 80px); //height of $header-height-desktop-sticky
                overflow-y: auto;
            }

            &:before {
                content: '';
                background-color: white;
                box-shadow: 0 26px 40px 5px rgba($color-primary, 0.1);
                bottom: 1px;
                left: 50%;
                margin-left: -50vw;
                position: absolute;
                width: 100vw;
                z-index: -1;
            }

            li {
                color: $text__color;
                display: block;
                float: none;
                font-weight: 300;
                height: auto;
                line-height: $line-height__base;
                text-decoration: none;
                text-transform: none;
            }

            a {
                color: $text__color;
                display: inline-block;
                margin-bottom: 6px;
            }

            [data-content-type="heading"] {
                color: $text__color;
                font-weight: 300;
                letter-spacing: 3px;
                margin-top: 0;
                margin-bottom: 18px;
                position: relative;
            }

            [data-content-type="image"] {
                position: relative;

                [data-element="link"] {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin-bottom: 0;

                    &:hover {
                        text-decoration: none;
                    }

                    &:after {
                        display: none;
                    }
                }

                img {
                    align-self: flex-end;
                }
            }

            [data-element="caption"] {
                @include btn;
                position: absolute;
                bottom: 0;
                margin: 20px;
                right: 0;
                pointer-events: none;
                cursor: pointer;
                border-color: #222128 !important;
            }
        }
    }
}
