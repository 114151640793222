.toolbar {

    .limiter,
    .modes {
        display: none !important;
    }

    &.toolbar-products {
        padding: 4rem 0 6rem 0;
        margin: 0;

        @include min-screen($screen__m) {
            padding: 2rem 0;
        }

        @include max-screen($screen__m) {
            .toolbar-amount {
                position: absolute;
                display: block !important;
                left: 0;
            }
        }

    }

    .products + .toolbar-products {
        position: relative;
        justify-content: center;
        margin-top: 0;

        .pages-item-previous {
            position: absolute;
            left: 0;
        }

        .pages-item-next {
            position: absolute;
            right: 0;
        }
    }

    .pages {

        .items {
            font-weight: $font-weight__light;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .item {
            line-height: inherit;
            font-size: 1.5rem;
            font-weight: $font-weight__light;

            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }

            &.current {
                font-weight: $font-weight__light;
            }

            @include max-screen($screen__m) {
                display: none !important;


                &.pages-item-previous,
                &.pages-item-next {
                    display: block !important;

                    .action {
                        margin: 0 !important;
                        width: 140px !important;
                    }
                }
            }
        }

        a.page,
        a.page:visited {
            padding: 0 10px;
            color: $color-black;
            text-decoration: underline;
        }

        a.page:hover {
            color: $color-black;
            text-decoration: none;
        }

        strong.page {
            padding: 0 5px;
            font-size: 1.5rem;
            font-weight: $font-weight__light;
        }

        .action {
            width: auto;

            span {
                clip-path: none;
                clip: unset;
                position: relative;
                width: auto;
                display: block;
                height: auto;
                margin: 0;
                overflow: unset;

                &.label {
                    @extend .sr-only;
                }
            }


            &.next,
            &.previous {
                @include btn;
                @include btn-dark;
                width: 180px;

                &:hover:after {
                    background-color: $color-black !important;
                }
            }

            &:before {
                display: none;
            }

            &:after {
                background-color: white !important;
            }

            &.previous {
                padding: 12.5px 20px 12.5px 35px;
                margin-right: 2em;

                &:after {
                    left: 0;
                    right: auto !important;
                }
            }

            &.next {
                margin-left: 2em;
            }
        }
    }

    .filter.toggle {
        @include min-screen($screen__m) {
            display: none;
        }
    }

    .sorter {
        display: inline-flex;
        align-items: center;
        position: relative;
        float: right;

        .sorter-label {
            display: inline-block;
            margin-right: 25px;
            font-size: 1.3rem;

            @include max-screen($screen__m) {
                display: none;
            }
        }

        .sorter-options {
            display: block;
            padding-left: 0;
            color: $color-gray-dark;
            border-bottom-color: $color-gray-dark;
            min-width: 140px;
        }

        .sorter-action {
            margin-left: 24px;

            &:before {
                font-family: $font-family-name__base;
                content: '';
                @include icon($icon: 'arrow-sort');
                display: none;
            }
        }

        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-top-color: $color-black;
            position: absolute;
            top: 16px;
            right: 14px;

            @include max-screen($screen__s) {
                display: none;
            }
        }
    }

    .toolbar-amount {
        font-size: 1.3rem;
        display: inline-block;

        @include max-screen($screen__m) {
            display: none;
        }
    }
}

// bottom toolbar (specific)
.products.wrapper ~ .toolbar {
    .filter.toggle {
        display: none !important;
    }

    &.toolbar-products {
        @include min-screen($screen__m) {
            display: flex;
            flex-wrap: wrap;
        }
        @include min-screen($screen__xl) {
            position: relative;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .toolbar-amount {
        text-align: center;
        margin: 0 auto !important;
        display: block;
        position: relative !important;

        @include min-screen($screen__m) {
            text-align: center;
            display: block;
            width: 100%;
        }

    }
}

.toolbar-products {
    padding: 0;
    text-align: left !important;
}

.page-products {

    .vertical-note {
        display: none;
    }

}

@include min-screen($screen__m) {

    .products.wrapper ~ .toolbar .limiter {
        padding-top: 0;
    }

    .products.wrapper ~ .toolbar .pages {
        float: none;
        text-align: center;
    }


}

.toolbar .pages .action.next:not(.btn-icon):not(.btn-dark-icon):not(.btn-outline-icon):not(.btn-link):not(.slick-arrow):hover:after,
.toolbar .pages .action.previous:not(.btn-icon):not(.btn-dark-icon):not(.btn-outline-icon):not(.btn-link):not(.slick-arrow):hover:after {
    background: $color-black !important;
}

.toolbar-products .toolbar-sorter .sorter-action {
    display: inline-block !important;
}

.filter-current.cloned-filter-section {
    margin-top: 0;
    padding: 0;

    a * {
        pointer-events: none;
    }

    @include min-screen($screen__m) {
        display: none;
    }
}


.cloned-filter-container {
    display: flex;

    @include max-screen($screen__m) {
        flex-wrap: wrap;
    }
}

.toolbar.toolbar-products {
    @include max-screen($screen__m) {
        min-height: 80px;
    }
}

.catalog-category-view:not(.category-dames):not(.category-heren) {
    .toolbar.toolbar-products {
        @include max-screen($screen__m) {
            min-height: 80px;
            padding-top: 0.7rem;
            padding-bottom: 0;
        }
    }
}

.block-actions.cloned-reset-filters-button {
    margin: 0 !important;
    a * {
        pointer-events: none;
    }

    @include max-screen($screen__m) {
        width: 100%;
        padding: 0;
    }
}
