.blog-post-share {
    .blog-post-share-title {
        @extend .h4;
        margin-bottom: 1rem;
    }
    .blog-post-share-content{
        & > .container {
            padding: 0;
            width: 100%;
        }
    }
}
