.category-dames,
.category-heren {
    .page-title-wrapper {
        display: block;

        h1 {
            font-size: 28px;
            font-weight: 300;
        }
    }

    @include max-screen($screen__m) {
        .toolbar.toolbar-products {
            padding-top: 0;
        }
    }
}

.subcategory-nav {
    margin-bottom: 2rem;

    @include min-screen($screen__m) {
        margin-bottom: 4rem;
    }
}

.subcategory-nav__item-wrapper {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.subcategory-nav__items {
    display: flex;
    list-style: none;
    padding: 0 0 1rem;
    margin: 0;
}

.subcategory-nav__item {
    min-width: 150px;
    margin: 0 1rem 0 0;
    background: rgb(247, 247, 247);
    padding: .25rem 1rem;
    scroll-snap-align: start;
    transition: background .2s;

    @include min-screen($screen__m) {
        min-width: 300px;
        padding: 1rem;
    }

    &:hover {
        background: #e3e3e3;
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 100%;
    }
}

.subcategory-nav__item-image {
    width: 40px;
    height: auto;
    margin-right: 1rem;

    @include min-screen($screen__m) {
        width: 90px;
        height: auto;
    }
}

.subcategory-nav__item-name {
    font-size: 1rem;
    text-transform: uppercase;

    @include min-screen($screen__m) {
        font-size: 1.525rem;
    }
}

