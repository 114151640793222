.block.related {
    margin: $indent__m 0;

    @include min-screen($screen__m) {
        margin: $indent__xxl 0;
    }
}

.block.widget.block-viewed-products-grid {
    margin-top: $indent__xl;
}
