.opc-wrapper .fieldset > .field > .label {
    font-size: 1.5rem;
}

.field.street {
    margin-bottom: 0 !important;

    .field {
        .label {
            font-weight: $font-weight__bold;
        }

        &.additional {
            margin-top: 0;
            padding-left: 10px;

            .label {
                clip: auto;
                height: auto;
                margin: 0;
                overflow: visible;
                position: static;
                width: auto;
            }
        }

        &._required {
            label:after {
                content: '*';
                color: $color-red10;
                font-size: 1.2rem;
                margin: 0 0 0 5px;
            }
        }
    }

    > legend.label {
        display: none;
    }

    > .control {
        .control {
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
        }
    }
}

.field.street > .control {
    display: -ms-flexbox;
    display: flex;

    .customer-address-form & {
        flex-direction: column;

        .nested {
            margin-top: 15px !important;
        }

        .field {
            padding-left: 0;
            margin-bottom: 15px;
        }

        .field .label {
            font-weight: 300;
            line-height: 1.6;
            font-size: 1.5rem;
            letter-spacing: 1.5px;
        }
    }

    [name*=".street.0"] {
        -ms-flex: 10 5 auto;
        flex: 10 5 auto;
    }

    [name*=".street.1"] {
        -ms-flex: 0 10 auto;
        flex: 0 10 auto;
        max-width: 80px;
    }

    [name*=".street.2"] {
        -ms-flex: 0 10 auto;
        flex: 0 10 auto;
        max-width: 80px;
    }
}

.checkout-index-index .form-shipping-address input[type="number"] {
    border: 1px solid #8c8c8c;
    border-radius: 3px;
    min-height: 50px;
}
