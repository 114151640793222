.product-variants {
    .product-variants__label {
        display: block;
        font-weight: 400;
        margin-top: 2rem;

        @include max-screen($screen__m) {
            font-size: 1.3rem;
            font-weight: 300;
        }

        &:after {
            display: none;
        }
    }

    @include max-screen($screen__m) {
        margin-bottom: 10px;
    }

    @include min-screen($screen__m) {
        &__items {
            max-width: 400px;
        }
    }

    @include min-screen($screen__l) {
        &__items {
            max-width: 100%;
        }
    }

    &__label {
        @include product-label;
    }

    &__items {
        @include lib-clearfix;
        margin: 4px 0;
    }

    &__item {
        @include lib-tooltip(
            $_tooltip-position: top,
            $_tooltip-selector-content: '.product-variants__tooltip',
            $_tooltip-selector-toggle: '.product-variants__image-wrapper'
        );

        display: block;
        width: 48px;
        height: 62px;
        float: left;
        margin: 6px 15px 6px 0;
        border: 2px solid white;
        box-shadow: 0 0 0 1px $border-color__secondary;

        .product-variants__image-wrapper {
            cursor: pointer;
        }

        .product-variants__tooltip {
            min-width: 0;
            width: 120px;
            white-space: normal;
            transform: translateX(-50%);
            margin-left: 32px;
            bottom: calc(100% + 2px);
            text-align: center;
            font-size: 15px;
            padding: 5px;
            font-weight: 300;
            color: #1d1c22;

            &:before,
            &:after {
                left: 50%;
            }

            @include max-screen($screen__m) {
                display: none !important; // sass-lint:disable-line no-important
            }
        }

        &:hover,
        &.active {
            box-shadow: 0 0 0 1px $color-gray-light;
        }

        &.active {
            pointer-events: none;

            &:after {
                @include icon('check-thicker-white');
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    &__image {
        @include object-fit(cover);
        width: auto;
        height: 100%;
    }

    &__hex {
        width: 100%;
        height: 100%;
    }
}

.product-giftcard {
    .product-variants {
        display: none;
    }
}
