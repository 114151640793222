//
//  Buttons
//  ---------------------------------------------

button,
.btn,
a.btn,
.action.primary {
    @include btn;

    &.btn-dark {
        @include btn-dark;
    }

    &.btn-dark-icon {
        @include btn-dark-icon;
    }

    &.btn-icon {
        @include btn-icon;
    }

    &.btn-icon-link {
        @include btn-icon-link;
    }

    &.btn-outline {
        @include btn-outlined;

    }
    &.btn-outline-icon {
        @include btn-outlined-icon;
    }

    &.btn-icon-only {
        @include btn-icon-only;
    }

    &.btn-cta {
        @include btn-cta;
    }
}

a.btn {
    @include lib-link-as-button;
}

button.btn-link {
    @include lib-button-as-link;
    text-transform: inherit;
    appearance: none;
    font-weight: inherit;
}
