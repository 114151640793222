.product-addto-links {
    .action {
        &,
        &.towishlist,
        &.tocompare {
            &,
            &:hover {
                background: none;
                font-weight: $font-weight__regular;
                border: 0;
                color: $color-black;
                text-decoration: underline;
                font-size: 1.3rem;
                padding: 0;
            }

            &:hover {
                text-decoration: none;

                &:before {
                    animation: shake-top 0.5s linear both;
                }
            }
        }

        &.towishlist {
            &:before {
                @include icon($icon: 'heart');
                margin-right: $indent__s;
                position: relative;
                top: $indent__xs;
            }
        }
    }
}
