body.catalog-product-view {
    ._112o7an,
    ._1r0y13z,
    ._1peeabz {
        @include max-screen($screen__m) {
            bottom: 125px!important; //prevent from appearing on bottom addtocart & above send us mmessage btn
        }

        bottom: 160px!important; //prevent from appearing on bottom addtocart & above send us mmessage btn
    }
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
    &:not(.checkout-onepage-success) {
        @include max-screen($screen__m) {
            z-index: 95!important; //prevent from appearing on menu
        }
    }
}

