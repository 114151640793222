.ui-slider-horizontal {
    height: 1px;
    margin: 30px 0;
    background: color(black);
}

.ui-slider-handle {
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid $color-black;
    border-radius: 50%;
    margin-top: -10px;
    cursor: grab !important;

    + .ui-slider-handle {
        margin-left: -20px;
    }
}

.slider-attribute {
    padding: 20px 0 10px;

    .labels {
        display: none;
    }

    .labels + .slider + .labels {
        display: block;
    }

    .slider-inputs {
        margin-top: 15px !important;
        display: flex;
        justify-content: space-between;

        .slider-min-wrapper,
        .slider-max-wrapper {
            display: inline-flex;
            align-items: center;
            width: 85px !important;
            max-width: 45% !important;

            & label {
                padding-right: 5px;
            }
        }
    }

    .ui-slider-handle:hover {
        background: #fff !important;
        box-shadow: 0 0 0 1px #dadada;
    }
}
