@mixin welcome-container-item() {
    display: flex !important;
    margin-bottom: 20px !important;

    @include min-screen($screen__m) {
        width: calc(50% - 20px);
    }

    a {
        margin-left: 10px !important;
        text-decoration: none !important;
    }

    &:before {
        display: block;
        background-size: 25px;
        position: relative;
        content: '';
        width: 25px;
        height: 25px;
    }
}

.welcome-splash-container {
    margin: 0;
    padding: 0;
    height: 100vh;
}

.welcome-splash-container__content {
    background-color: white;
}

// General fixed related to welcome splash page
.page-layout-welcome {
    .page-main {
        margin: 0;
        padding: 0;
        max-width: inherit;
    }

    .columns .column.main {
        padding-bottom: 0 !important;
    }

    .offscreen-nav {
        display: none;
    }
}

.page-layout-welcome {
    background: url("../images/welcome.jpg") no-repeat center;
    background-size: cover;
    height: 100%;

    @include max-screen($screen__m) {
        background: $color-gray-darker;
    }

    .page-wrapper {
        height: 100%;
    }

    .pagebuilder-column-group {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }

    .welcome-container {
        width: calc(100% - 30px) !important;
        background: white;
        padding: 20px;
        border: 1px solid rgba(86,84,88,0.20);
        box-shadow: 0 40px 70px 0 rgba(0,0,0,0.15);
        box-sizing: border-box;


        [data-content-type="html"] {
            svg {
                display: block;
                margin: 0 auto;
            }
        }

        @include max-screen($screen__m) {
            padding: 0 0 20px 0 !important;
        }

        @include min-screen($screen__m) {
            width: 700px !important;
        }

        .welcome-container__item-nl {
            @include welcome-container-item();

            &:before {
                background: url("../images/logo-nl.svg") no-repeat left;
            }
        }

        .welcome-container__item-be {
            @include welcome-container-item();

            &:before {
                background: url("../images/logo-be.svg") no-repeat left;
            }
        }

        .welcome-container__item-de {
            @include welcome-container-item();

            &:before {
                background: url("../images/logo-de.svg") no-repeat left;
            }
        }

        .welcome-container__item-en {
            @include welcome-container-item();

            &:before {
                background: url("../images/logo-en.svg") no-repeat left;
            }
        }

        .welcome-container__item-uk {
            @include welcome-container-item();

            &:before {
                background: url("../images/logo-uk.svg") no-repeat left;
            }
        }

        ul,
        .welcome-container__buttons {
            margin-top: 20px !important;

            @include max-screen($screen__m) {
                padding-left: 40px !important;
            }

            @include min-screen($screen__m) {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-top: 40px !important;
                padding-left: 40px !important;
            }
        }
        ul li {
            display: flex;
            align-items: center;
            margin-bottom: 20px !important;
            box-sizing: border-box;

            @include min-screen($screen__m) {
                width: calc(50% - 20px);
            }

            &:before {
                display: block;
                background-size: 25px;
                position: relative;
                content: '';
                width: 25px;
                height: 25px;
            }
        }

        ul li a {
            margin-left: 10px;
            text-decoration: none;
        }

        ul li:nth-child(1) {
            &:before {
                background: url("../images/logo-nl.svg") no-repeat left;
            }

        }

        ul li:nth-child(2) {
            &:before {
                background: url("../images/logo-be.svg") no-repeat left;
            }
        }

        ul li:nth-child(3) {
            &:before {
                background: url("../images/logo-en.svg") no-repeat left;
            }
        }

        ul li:nth-child(4) {
            &:before {
                background: url("../images/logo-uk.svg") no-repeat left;
            }
        }

        @include max-screen(479px) {
            width: 100%;
            max-height: 80vh;
            overflow-y: auto;
        }

        .logo {
            display: flex;
            width: 100%;
            max-width: none;
            margin: 0 auto;
            justify-content: center;
        }
    }
}
