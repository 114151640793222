.footer {
    &__columns {
        @include layout-inner;
        padding: 50px 0;

        @include max-screen($screen__m) {
            padding: $indent__base;
        }

        @include min-screen($screen__m) {
            display: flex;

            .footer__column {
                flex: 3;
                padding: 0 $indent__base;
            }

            .footer__tabs {
                display: flex;
                width: 100%;
                flex: 7;
                justify-content: space-between;
            }

            .footer__newsletter {
                width: 310px;
            }
        }

        @include screen($screen__m, $screen__l) {
            flex-direction: column;
        }
    }

    &__column {
        color: $color-gray-darker;
        font-weight: $font-weight__light;
        font-size: 1.3rem;

        p {
            font-size: 1.3rem;
        }

        strong,
        .footer__tab-title {
            display: block;
            margin-bottom: 15px;
            font-size: 1.7rem;
            font-family: $heading__font-family__base;
            font-weight: $font-weight__regular;
            text-transform: uppercase;
            color: #fff;

            @include min-screen($screen__m) {
                margin-bottom: $indent__base;
                font-size: 1.9rem;
            }
        }

        @include screen($screen__m, $screen__l) {
            @include lib-clearfix();
            display: flow-root;

            .footer__column--contact {
                margin-bottom: $indent__base;
            }
        }

        li {
            line-height: $indent__l;
        }
    }

    &__column--contact {

        @extend %phone-links;

        ul {
            margin: $indent__base 0 !important;
        }

        li {
            line-height: 2;
        }
    }

    &__column-links {
        a {
            color: $color-gray-darker;
            text-decoration: none;
            font-weight: $font-weight__light;

            &:hover {
                color: $color-gray-darker;
                text-decoration: underline;
            }
        }
    }
}
