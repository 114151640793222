.dropdown-widget {
    @include lib-dropdown;

    &.dropdown-widget--hover {
        &:hover {
            ul.dropdown {
                display: block;
                margin-top: 0;

                @include min-screen($screen__m) {
                    overflow: auto;
                    max-height: 200px;
                }
            }
        }
    }
}
