.fotorama--fullscreen {
    z-index: 99999 !important; // sass-lint:disable-line no-important
}

.product.media {
    .gallery-placeholder._block-content-loading {
        padding-top: 100%;
        height: 0;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .gallery-placeholder__image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            @include object-fit(contain);
            object-position: center;
            height: 100%;
        }
    }

    .fotorama__stage__frame {
        .fotorama__img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            height: auto;
            width: auto;
            max-width: 100%;
        }

        .product-video, .video-thumb-icon:after {
            bottom: auto !important;
        }
    }
}

.fotorama__wrap {
    @include max-screen($screen__l) {
        .fotorama__fullscreen & {
            height: 100% !important; // sass-lint:disable-line no-important
        }
    }
}

.fotorama__stage {
    margin: 0 auto;

    @include min-screen($screen__m) {
        margin-left: $indent__xs;
    }

    @include max-screen($screen__l) {
        .fotorama__fullscreen & {
            height: calc(100% - 45px) !important; // sass-lint:disable-line no-important
        }
    }
}

.fotorama__stage__frame {
    .fotorama__img {
        width: 100%;
    }
}

.fotorama__nav-wrap {
    .fotorama_vertical_ratio {
        display: flex;

        .fotorama__img {
            width: 80px;
            height: 105px;
            object-fit: cover;
            position: relative;
            top: auto;
            transform: none;
        }
    }
}

.fotorama__dot {
    left: 4px;
    width: 8px;
    height: 8px;
}

.fotorama__active {
    .fotorama__dot {
        background-color: $color-black;
        border-color: $color-black;
    }
}

.fotorama__thumb {
    background-color: transparent;
    cursor: pointer;
    width: 80px;
    height: 105px;
}

.fotorama__thumb__arr {
    background-color: hsla(0, 0%, 100%, 0.9);
}

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    border-bottom: 1px solid rgba($color-black, 0.5);
}

.fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    border-top: 1px solid rgba($color-black, 0.5);
}

.fotorama__thumb-border {
    display: none;
}

.fotorama__nav__frame {
    position: relative;
    margin-bottom: 15px;

    @include min-screen(1025px) {
        &.fotorama__active {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 0;
                bottom: 0;
                right: 0;
                width: 78px;
                height: 103px;
                border: 1px solid $color-black;
                background: transparent;
                z-index: 99;
            }
        }

        &.fotorama__nav__frame--thumb {
            width: 80px !important;
            height: 105px !important;
        }
    }
}

.fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    position: relative;
}

.fotorama-video-container {
    &:after {
        @include icon($icon: 'video');
    }
}

.video-thumb-icon {
    &:after {
        @include icon($icon: 'video');
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -40%, 0);
    }
}

.fotorama__nav__frame {
    &.fotorama__active:before {
        top: 0;
        height: 110px;
        padding: 10px;
        box-sizing: border-box;
        border-color: #AAA9AB;
    }
}

.fotorama__arr--prev .fotorama__arr__arr,
.fotorama__arr--next .fotorama__arr__arr {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;

    svg {
        transition: transform 250ms ease-in-out;

        &:hover {
            transform: translateX(3px);
        }
    }
}

.fotorama__arr--prev .fotorama__arr__arr {
    svg {
        &:hover {
            transform: translateX(-3px);
        }
    }
}

.fotorama__arr.fotorama__arr--prev,
.fotorama__arr.fotorama__arr--next {
    background-color: transparent;
}


.model-label {
    position: absolute;
    bottom: 6px;
    left: 0;
    display: block;
    background: rgba(86, 84, 88, 0.75);
    color: rgb(255, 255, 255);
    font-size: 12px;
    padding: 0 6px;
}

@media only screen and (max-width: 1280px) {
    .model-label {
        display: none;
    }
}
