.personalisation-index-index {
    transition: background-color 650ms ease-in-out;

    .page-main {
        max-width: 100% !important;
        margin: 0;
        padding: 0;
        position: relative;
    }
}

.landing-section-overlay {
    display: none;
    width: 100%;
    height: 100vh;
    background-color: white;
    position: absolute;
    right: 0;
    bottom: 0;
    transition: width 1000ms ease-in-out;
    z-index: 2;

    .section-inview & {
        width: 0%;
    }

    @include min-screen($screen__m) {
        display: block;
    }
}

.product-landing-container {
    min-height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}
