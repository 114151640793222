//
//  Account sidebar
//  ---------------------------------------------
.account-nav .item.current a,
.account-nav .item.current strong {
    border-color: $color-black;
}

@include max-screen($screen__m) {
    .account .columns {
        .sidebar-main {
            position: static;
            top: 0;
            z-index: 1;
            order: 1;
        }

        .column.main {
            order: 2;
        }
    }
}

//
//  Forms
//  ---------------------------------------------
.account .column.main,
.login-container,
.customer-account-create {
    @include form-reset;

    .block:not(.widget) {
        .block-title {
            > strong {
                font-weight: $heading__font-weight__base;
                font-family: $heading__font-family__base;
                text-transform: uppercase;
                font-size: $h5__font-size;
            }
        }

        .box-title {
            > span {
                text-transform: uppercase;
                font-size: 1.5rem;
            }
        }
    }

    .fieldset {
        .legend {
            @include lib-heading($_heading_level: h5);
            text-transform: uppercase;
        }
        .required-info {
            &::before {
                content: '*';
                color:red;
            }
            margin: 10px 0 0;
            display: block;
            letter-spacing: normal;
            word-spacing: normal;
            font-size: 1.2rem;
            color: #1d1c22;
        }
        &::after {
            content: '';
        }
    }

    .box-actions {
        .action {
            margin-right: $indent__s;
        }
    }

    .page-title-wrapper {
        .page-title {
            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
                margin-right: 0;
                font-size: $h4__font-size;
            }
        }
    }

    .page.messages {
        @include max-screen($screen__m) {
            margin-bottom: $indent__s;
        }
    }
}

.customer-account-login {
    .page-wrapper {
        .page-title-wrapper {
            .page-title {
                @include max-screen(1000px) {
                    width: 13em;
                }
                @include max-screen(767px) {
                    width: 13em;
                }
                @include max-screen(520px) {
                    font-size: 2rem;
                    width: 17em;
                }
            }
        }
    }

}

.form.password.reset,
.form.send.confirmation,
.form.password.forget,
.form.create.account {
    @include min-screen($screen__m) {
        width: 100%;
        min-width: unset;
    }
}

// Create account page
.form-create-account {
    width: 100%;

    .create-account-wrapper {
        width: 100%;
        order: 2;
    }

    .create-account-title,
    .field:not(.choice) > .control,
    .field.choice.newsletter,
    .field.email.required {
        width: 100% !important;
    }
}

.customer-account-create {
    .page-title-wrapper {
        padding: 0 4rem;
    }

    .create-account-sidebar {
        width: 100%;
        order: 1;

        // Hide duplicate image
        figure,
        figure .pagebuilder-mobile-hidden,
        figure .pagebuilder-mobile-only,
        p img,
        img {
            display: none;
        }

        ul {
            margin-inline-start: 0;
            padding-inline-start: 0;

            & > li {
                position: relative;
                left: 20px;
                margin-bottom: 1rem !important;

                &::marker {
                    content: '';
                    display: none;
                }

                &:before {
                    @include icon('check');
                    position: absolute;
                    top: 9px;
                    left: -20px;
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .customer-account-create {
        .create-account-wrapper {
            box-sizing: border-box;
            padding: 0 4rem;
            width: 60%;
            float: left;
            order: 1;
        }

        .create-account-sidebar {
            width: 40%;
            float: right;
            margin: 2rem 0 1rem;
            order: 2;

            figure,
            figure .pagebuilder-mobile-hidden,
            p img,
            img {
                display: block;
            }

            figure .pagebuilder-mobile-only {
                display: none;
            }
        }
    }

    .customer-account-create .fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .field-name-firstname,
        .field-name-lastname {
            width: calc(50% - 10px);
            position: relative;
        }

        &.create.account {
            .field.password.required,
            .field.confirmation.required {
                width: calc(50% - 10px);
                position: relative;
            }

            .password-strength-meter {
                min-height: 32px;
                height: auto;
            }
        }
    }
}

//
//  Typography
//  ---------------------------------------------
.login-container,
.account .columns {
    font-weight: $font-weight__light;
}

.login-container {
    .form-address-edit {
        .action.submit.primary {
            @include btn;
            @include btn-dark;
        }
    }
}

//
//  Account
//  ---------------------------------------------
.account {

    //
    //  GDPR
    //  ---------------------------------------------
    .mp-gdpr-delete-customer-account {
        margin-top: $indent__base;

        &,
        .col-mp {
            padding: 0;
        }

        .gdpr-delete-message {
            margin-bottom: $indent__base;
        }
    }

    //
    //  Orders
    //  ---------------------------------------------
    .items.order-links {
        display: none;
    }

    .order-details-items {
        .order-title {
            > strong {
                margin-top: 0;
                font-size: 2rem;
                text-transform: uppercase;
            }
        }
    }

    //
    //  Wishlist
    //  ---------------------------------------------
    &.form-wishlist-items {
        margin-top: 0;
    }

    .toolbar.wishlist-toolbar {
        display: none;

        &:last-of-type {
            display: block;
        }
    }

    .form-wishlist-items {
        margin-top: 0;

        .actions-toolbar {
            .primary {
                .action {
                    margin-bottom: $indent__xs;
                }

                @include min-screen($screen__l) {
                    display: flex;
                    float: none;
                }
            }
        }
    }

    .products-grid.wishlist {
        .product-item {
            margin-bottom: $indent__base !important; // sass-lint:disable-line no-important

            .field {
                &.qty,
                &.comment-box {
                    display: none;
                }
            }

            .product-item-actions {
                margin-top: $indent__xs !important; // sass-lint:disable-line no-important

                .action.delete {
                    float: right;
                }
            }

            @include max-screen($screen__xs) {
                width: 100% !important; // sass-lint:disable-line no-important
            }

            @include min-screen($screen__m) {
                margin-top: 0;

                .actions-primary {
                    opacity: 1;
                    visibility: visible;
                }

                &:hover {
                    .product-item-info {
                        top: 0;
                    }
                }
            }
        }
    }

    //
    //  Override mobile wishlist
    //  ---------------------------------------------
    @include max-screen($screen__s) {
        .products-grid.wishlist {
            .product-item {
                border-bottom: none !important; // sass-lint:disable-line no-important
                &:first-child {
                    border-top: none !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

    @include max-screen($screen__m) {
        .products-grid.wishlist {
            .product {
                &-item {
                    &-photo {
                        float: none !important; // sass-lint:disable-line no-important
                        margin-right: 0 !important; // sass-lint:disable-line no-important
                    }

                    &-name {
                        margin-left: 0 !important; // sass-lint:disable-line no-important
                        font-size: 1.8rem !important; // sass-lint:disable-line no-important
                    }

                    &-name,
                    &-description,
                    .price-box,
                    &-tooltip {
                        margin-left: 0 !important; // sass-lint:disable-line no-important
                    }

                    .box-tocart {
                        float: none !important; // sass-lint:disable-line no-important
                        margin-right: 0 !important; // sass-lint:disable-line no-important
                    }
                }

                &-image-container {
                    max-width: none !important; // sass-lint:disable-line no-important
                }
            }
        }
    }
}

.password-strength-meter {
    @include max-screen($screen__s) {
        height: auto;
    }
}

.form.password.reset,
.customer-address-form,
.form-create-account {

    .action.submit.primary {
        @include btn;
        @include btn-dark;

        &::after {
            background-color: white !important;
        }

        &:hover:after {
            background-color: black !important;
        }
    }
}

.form-edit-account {
    .actions-toolbar > .primary {
        .action {
            @include btn;
            @include btn-dark;

            &::after {
                background-color: white !important;
            }

            &:hover:after {
                background-color: black !important;
            }
        }
    }
}

.block-authentication {
    .block-new-customer,
    .block-customer-login {
        @include min-screen($screen__s) {
            &::before {
                left: -18px !important;
            }
        }
        ul{
            li{
                display: flex;
            }

            list-style:none;
            li::before{
                background: url("/media/wysiwyg/check-transparent.png") no-repeat;
                margin-right: 8px;
                content: '';
                display: inline-block;
                width: 18px;
                height: 13px;
                margin-top: 6px;
            }
        }
        .primary {
            .action {
                @include btn;
                @include btn-dark;

                &::after {
                    background-color: white !important;
                }

                &:hover:after {
                    background-color: black !important;
                }
            }
        }
    }
}

.block-new-customer,
.block-customer-login {
    ul{
        list-style:none;
        padding: 0;
        li::before{
            background: url("/media/wysiwyg/check-transparent.png") no-repeat;
            margin-right: 8px;
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
        }
    }
    .primary {
        .action {
            @include btn;
            @include btn-dark;

            &::after {
                background-color: white !important;
            }

            &:hover:after {
                background-color: black !important;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid.wishlist {
        .product-item {
            border-bottom: 1px solid $secondary__color;

            &:first-child {
                border-top: 1px solid $secondary__color;
            }
        }
    }
}

.form-create-account {
    margin-bottom: $indent__l;
}

.column {
    .block-addbysku {
        .block-content {
            .actions-toolbar {
                .primary .action {
                    width: auto;
                }
            }
        }
    }
}


.login-container {
    @include min-screen($screen__m) {
        .actions-toolbar {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .secondary {
                float: inherit;
                margin-left: 20px;
            }
        }
    }
}


.form.password.forget {
    @include form-reset;

    .action.reload.captcha-reload {
        @include btn-outlined;
    }

    @include min-screen($screen__m) {
        width: 48.8%;
        margin-bottom: 60px;

        .actions-toolbar {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .secondary {
                float: inherit;
                margin-left: 20px;
            }
        }
    }
}
