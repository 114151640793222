button.filter.toggle {
    position: relative;
    strong {
        display: block;
    }

    .filter__counter {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $color-gray;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -10px;
        right: 10px;
        color: black;
        font-size: 11px;
        text-align: center;
        line-height: normal;

        span {
            margin-left: 2px;
            margin-top: 2px;
        }
    }
}
