.header.sticky {
    box-shadow: 0 26px 40px 5px rgba($color-primary, 0);
    border-bottom: 1px solid #f0f0f0;

    @include max-screen($screen__m) {
        width: 100%;
        height: $header-height-mobile-searchbar-sticky;
    }
}

.page-header {
    transition: transform .1s;

    @include max-screen($screen__md) {
        height: $header-height-mobile-searchbar-sticky;
    }
}

body.offscreen-nav-open .headroom,
.headroom--not-top {
    .header.sticky {
        background-color: $color-white;
        //box-shadow: 0 26px 40px 5px rgba($color-primary, .1);
    }
}

body.offscreen-nav-open .page-header.headroom,
.headroom--not-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 26px 40px 5px rgba($color-primary, .1);
    background: white;
}

.headroom--not-top.header.sticky {
    height: $header-height-desktop-sticky;

    .block-static-block {
        border-top: 1px solid $color-gray-darker;
        left: 0;
        right: 0;
        top: $header-height-desktop-sticky;
    }
}

@include max-screen($screen_fhdplus) {
    .headroom--not-top.page-header {
        height: calc(#{$header-height-desktop-sticky} + 35px);

        .block-static-block {
            border-top: 1px solid $color-gray-darker;
            top: $header-height-desktop-sticky;
            left: -20px;
            right: -20px;
        }
    }
}

@include max-screen($screen__m) {
    .header.sticky:not(.headroom--unpinned) {
        background-color: $color-white;
        display: block;
        position: fixed;
        z-index: 99;
    }

    .headroom--not-top.page-header {
        //header-height should be 155px total due to searchbar
        height: calc(#{$header-height-mobile-searchbar-sticky} + 35px);
    }
}

.header-is-hidden {
    .page-header.headroom--pinned,
    .page-header.headroom--unpinned {
        height: $header-height;
        transform: translateY(-100%);

        // zoom out duration
        transition-duration: 250ms;

        @include min-screen($screen_fhdplus) {
            height: 80px;
        }

        .panel.wrapper {
            transform: translateY(-90px);

            .switcher-dropdown {
                transform: translateY(-100%);
            }
        }

        .header.sticky {
            transform: translateY(-55px);

            .guestMessage {
                margin-top: -50px;
            }
        }

        .is-header-top & {
            height: 100px;
        }
    }
}

.header-is-pinned {
    .page-header.headroom--pinned {
        // zoom in of headroom
        // delay to prevent quick up/down movement appearance
        transition-delay: 300ms;
        transition-duration: 250ms;

        .panel.wrapper {
            transform: translateY(0);
            position: relative;
            z-index: 99;

            .switcher-dropdown {
                transform: unset;
            }
        }

        .header.sticky {
            transform: translateY(0);
        }
    }
}
