%phone-links {
    a[href^="tel:"] {
        display: block;
        font-size: 1.7rem;
        font-weight: $font-weight__heavier;
        text-decoration: none;
        text-decoration: none;
        margin-bottom: 5px;

        &:hover {
            text-decoration: underline;

            &:before {
                animation: shake-top 0.5s linear both;
            }
        }

        &:before {
            @include icon(
                $icon: 'phone'
            );
            position: relative;
            top: 3px;
            margin-right: 10px;
        }
    }

    li {
        &:last-child {
            a[href^="tel:"] {
                &:before {
                    @include icon(
                        $icon: 'whatsapp'
                    );
                }
            }
        }
    }
}

%phone-links-white {
    @extend %phone-links;

    a[href^="tel:"] {
        &:before {
            @include icon(
                $icon: 'phone-white'
            );
        }
    }

    li {
        &:last-child {
            a[href^="tel:"] {
                &:before {
                    @include icon(
                        $icon: 'whatsapp-white'
                    );
                }
            }
        }
    }
}