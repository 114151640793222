.product-items,
.products-grid {
    @include min-screen($screen__m) {
        .checkout-cart-index & {
            .slick-track {
                text-align: center !important;
            }
        }
    }

    .product-item {
        padding-top: $indent__base;

        .product-image-wrapper {
            transition: padding 0.3s ease;
        }

        .product-item-info {
            width: 100%;
            position: relative;
            top: 0;
            //transition: top 0.1s linear;
        }

        .product-image-container {
            width: 100% !important;

            [data-content-type="products"] & {
                max-width: 50vw;
            }
        }

        .product-item-photo {
            margin-bottom: 0;
        }

        .product-image-photo {
            width: 100%;
            margin-top: 0;

            @include min-screen($screen__m) {
                width: 100%;
            }
        }

        .product-item-actions {
            display: none;
            margin: 0 !important;
            text-align: left;

            @include min-screen($screen__m) {
                display: block;
            }
        }

        &:hover {
            @include min-screen($screen__m) {
                .product-image-wrapper {
                    position: relative;
                    overflow: hidden;
                }

                .product-image-photo {
                    height: 100% !important;
                    width: auto !important;
                }
            }
        }
    }
}

.page-products {
    .products-grid {
        .product-item {
            @include min-screen($screen__m) {
                margin-top: -#{$indent__l} !important;
            }
        }
    }
}

.product-item {
    .product-item-name {
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: $line-height__s;
        letter-spacing: 2px;
        text-transform: inherit;
        color: $color-black;
        text-align: left;
        font-weight: 300 !important;

        a {
            font-weight: 300 !important;
        }

        @include min-screen($screen__m) {
            font-size: 1.8rem;
            margin-top: 3.6rem;
        }

        @include max-screen($screen__s) {
            display: block;
            //min-height: 55px;
        }

        em {
            font-size: 1.3rem;
            letter-spacing: 2.89px;

            @include max-screen($screen__s) {
                font-size: 11px;
            }
        }
    }

    .product-item-color {
        display: block;
        font-family: $heading_sub__font-family-name__base;
        font-weight: $font-weight__regular;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: $color-gray-dark;
        margin-top: 3px;

        @include min-screen($screen__m) {
            font-size: 1.3rem;

            &:before {
                content: '- ';
            }

            &:after {
                content: ' -';
            }
        }
    }

    .actions-secondary {
        flex-basis: 22px;
        max-width: 22px;
        align-self: flex-end;
        margin-top: 3px;

        .action {
            &.towishlist,
            &.tocompare {
                display: none;
            }
        }
    }

    .actions-primary {
        text-align: center;

        .stock {
            @include btn();
            pointer-events: none;
            width: 100%;
            text-align: center;
        }
    }

    .product-item__btn {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .price-box {
        width: 100%;
        margin-bottom: $indent__s;
        margin-top: 5px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        font-size: 1.5rem;

        .price-box__label,
        .price-label {
            display: none !important;
        }

        .price {
            font-family: $heading__font-family-name__base;
            font-weight: $font-weight__light;
        }

        .old-price {
            margin-right: $indent__s;
            color: $color-gray-light;

            .price {
                color: $color-gray-light;
                font-size: 12px;
            }
        }
    }

    .product-item-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $indent__base;

        .product-label {
            display: none;
        }
    }

    .product-item-inner {
        width: 100%;

        @include min-screen($screen__m) {
            position: absolute;
            bottom: 89px;
            z-index: 1;
            padding: 20px 5px;
            background-color: rgba(black, 0.5);
            box-sizing: border-box;
            transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
            opacity: 0;

            .swatch-select {
                color: white;
            }

            .slick-slide & {
                bottom: 63px;
            }
        }

        .actions-primary {
            display: block;
        }
    }

    .tocart {
        width: 100%;
        white-space: normal;
    }

    .swatch-attribute.full_size .swatch-attribute-label {
        @extend .sr-only;
    }

}

.product-image-photo {
    margin-bottom: 0;
}

// Products Slider
body.checkout-cart-index {
    .pagebuilder-row-divider {
        display: none !important;
    }

    .slick-slider {
        @include min-screen($screen__m) {
            .products-slider__item {
                max-width: 400px !important;
                margin: 0 20px;
            }
        }
    }
}

body .slick-slider {
    &.products-slider {
        position: relative !important;
        padding: 0;

        @include max-screen($screen__m) {
            margin: 0 30px;
        }

        @include min-screen($screen__m) {
            width: 100%;
            max-width: 100% !important;
            margin: 0;
            padding: 0 30px !important;
        }

        .product-item-info {
            width: 100%;
            text-align: center;
            margin: 0 auto;


            .special-price .price-label,
            .normal-price .price-label,
            .old-price .price-label {
                display: none;
            }
        }

        .products-slider__item {
            padding: 0 20px;
            box-sizing: border-box;

            @include max-screen($screen__m + 1) {
                padding-right: 10px !important;
                padding-left: 10px !important;
            }

            @include max-screen($screen__s) {
                padding-right: 5px !important;
                padding-left: 5px !important;
            }

            img {
                 width: 100%;
            }
        }
    }
}

@include min-screen($screen__m) {
    .product-items,
    .products-grid {
        .product-item,
        .products-slider__item {
            .product-item__btn-wrapper,
            .product-item-actions .actions-secondary,
            .product-item-actions .actions-primary form,
            .actions-primary,
            .swatch-attribute.size {
                display: block;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }

            &:hover {
                .product-item-inner,
                .product-item__btn-wrapper,
                .product-item-actions .actions-secondary,
                .product-item-actions .actions-primary form,
                .actions-primary {
                    visibility: visible;
                    opacity: 1;
                }

                .actions-primary {
                    height: auto;
                    max-height: inherit;
                }
            }

            .actions-primary {
                .stock {
                    margin-left: auto;
                    margin-right: auto;
                    width: auto;
                }
            }

            [data-button-add-to-cart] {
                display: none;
                margin-left: auto;
                margin-right: auto;
                width: auto;

                &.disabled {
                    color: black !important;
                    background-color: white !important;
                    opacity: 1 !important;

                    &:focus {
                        color: black !important;
                        background-color: white !important;
                        opacity: 1 !important;
                    }

                    &:hover {
                        color: black !important;
                        background-color: white !important;
                        opacity: 1 !important;
                    }
                }
            }
        }
    }
}

.product-item {
    strong.product-item-name {
        margin-top: 1.5rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: $line-height__s;
        letter-spacing: 2px;
        color: $color-black;
        text-align: left;

        @include min-screen($screen__m) {
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        @include max-screen($screen__s) {
            display: block;
            min-height: 0;
        }
    }

    .product-recent-item-color {
        margin-bottom: 0;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: #1D1C22;
        text-align: center;
        display: block;
        margin-top: 5px;

        em {
            color: #666666;
            font-family: "Noto Serif JP";
            font-weight: 300;
            font-style: normal;
            font-size: 1.3rem;
            letter-spacing: 2.89px;

            @include max-screen($screen__s) {
                font-size: 11px;
            }
        }
    }
}

// Overwrite for swatch overlay
.product-item {
    &.slick-slide {
        .product-item-inner {
            @include min-screen($screen__m) {
                bottom: 63px;
            }
        }
    }
}
