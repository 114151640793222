.aw_blog-post-view {

    .blog-post-featured-image-wrapper {
        display: none;
    }

}

.blog-post-info {
    margin: 0 0 0.5em;
    padding: 0  0 0.5em;
    list-style: none;
    align-items: center;
}

.blog-post-info-item {
    margin: 0 1em 0 0;
    display: inline-flex;
}

.author-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        border-radius: 50%;
        max-width: 30px;
        height: auto;
        margin: 0 0.3em;
    }
}

@include min-screen($screen__m) {

    .aw_blog-post-view {

        .blog-posts-post,
        .breadcrumbs {
            max-width: ($layout__max-width / 3 * 2);
            margin: 0 auto;
        }

    }

    .blog-post-info {
        display: flex;
        flex-direction: row;
    }
}
