.pdp-gallery-navigation {
    width: 68px;
    min-width: 150px;

    .slider-nav.slick-initialized {
        display: flex !important;
        justify-content: center;

        &.has-arrows {
            align-items: center;
        }
    }

    .slider-nav:not(.has-arrows) {
        .slick-track {
            transform: none !important;
        }
    }

    .slick-arrow + .slick-list {
        margin-top: 10px;

        @include max-screen(1441px) {
            margin-top: 92px;
            margin-bottom: 80px;
        }
    }

    .slick-list {
        width: 68px;
    }

    @include min-screen($screen__l) {
        margin-right: -4px;
        display: flex;
        justify-content: center;
    }

    .slick-track {
        display: flex;
        flex-direction: column;
    }

    @include max-screen($screen__l) {
        display: none;
    }
}

.pdp-gallery-navigation__item {
    display: flex !important;
    margin-bottom: 10px;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid transparent;
    min-height: 90px;
    height: 90px !important;
    cursor: pointer;
    overflow: hidden;
    max-width: 74px;
    justify-content: center;

    &:last-child {
        margin-bottom: 0;
    }

    &.slick-current {
        border: 1px solid $color-gray-darker;
    }
}

.pdp-gallery-navigation__item--video {
    position: relative;
    align-items: center;
    justify-content: center;

    img {
        height: 100%;
        max-width: 62px !important;
    }

    &::after {
        position: absolute;
        content: '';
        width: 29px;
        height: 29px;
        background-image: url('../images/icons/icon-video.svg');
    }
}

.cavallaro-270-container {
    display: inline-block;

    &.reload {
        width: 100px !important;
        height: 100px !important;
    }
}
