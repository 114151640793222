//
//  Buttons
//  _____________________________________________

@mixin btn-defaults() {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-family: $font-family__base;
    text-decoration: none;
    position: relative;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;
}

@mixin btn(
    $size: default
) {
    @if $size == large {
        @include lib-button-l();
    } @else if $size == small {
        @include lib-button-s();
    } @else {
        @include lib-button();
    }

    &:hover {
        background-color: $color-black;
        color: $color-white;
    }

    @include btn-defaults;

    &:not(.btn-icon):not(.btn-dark-icon):not(.btn-outline-icon):not(.btn-link):not(.slick-arrow) {
        &:after {
            content: '';
            width: 25px;
            height: 1px;
            background-color: $color-black;
            position: absolute;
            top: 50%;
            right: 0;
            transition: background-color 250ms ease-in-out;
        }


        &.btn-dark {
            &:after {
                background-color: $color-white;
            }
            &:hover:after {
                background-color: $color-black !important;
            }
        }

        &.btn-cta {
            &:after {
                background-color: $color-white;
            }
            &:hover:after {
                background-color: $color-black !important;
            }
        }

        &:hover,
        &:focus {
            &:after {
                background-color: $color-white;
            }
        }
    }
}

@mixin btn-override(
    $size: default,
    $color: $color-slate-gray,
    $background: $color-light-gray
) {
    @include btn($size);
    background: $background;
    border-color: $background;

    &,
    &:visited {
        color: $color;
    }

    &:before {
        color: $color !important;
    }

    &:hover,
    &:active,
    &:focus {
        background: lighten($background, 2%);
        border-color: lighten($background, 2%);

        &,
        &:visited {
            color: $color;
        }

        &:before {
            color: $color !important;
        }
    }
}

@mixin btn-secondary(
    $size: default,
    $color: $color-slate-gray,
    $background: $color-light-gray
) {
    @include btn-override(
        $size,
        $color,
        $background
    )
}

@mixin btn-primary() {
    @include lib-button-primary();
    @include btn-defaults;
}

@mixin btn-outlined() {
    @include lib-button(
            $_button-background: transparent,
            $_button-color: $color-black,
            $_button-background-hover: $color-black,
            $_button-color-hover: $color-white
    );

    &:visited {
        color: $color-black;

        &:hover,
        &:focus
        &:active {
            color: $color-white;
        }
    }

    &:focus {
        color: $color-black;

        &:after {
            background-color: $color-black !important;
        }
    }


    @include btn-defaults;
    border-color: lighten($color-black, 2%);
}

@mixin btn-dark() {
    @include btn-defaults;
    @include lib-button(
        $_button-background: $color-black,
        $_button-color: $color-white,
        $_button-background-hover: $color-white,
        $_button-color-hover: $color-black
    );

    &:focus,
    &:hover,
    &:active {
        color: $color-black;

        &:after {
            background-color: $color-black !important;
        }
    }

    border-color: lighten($color-black, 2%);
}

@mixin btn-dark-icon() {
    @include btn-dark();
    @include btn-icon(
        $icon: 'chevron-right-white',
        $icon-hover: 'chevron-right-white'
    );

    &:after {
        background-color: transparent !important;
    }
}

@mixin btn-outlined-reverse() {
    @include lib-button(
            $_button-background: $color-black,
            $_button-color: $color-white,
            $_button-background-hover: $color-white,
            $_button-color-hover: $color-black,
            $_button-border: 1px $color-white solid
    );

    &:visited {
        color: $color-white;

        &:hover,
        &:focus
        &:active {
            color: $color-black;
        }
    }

    @include btn-defaults;
}

@mixin btn-outlined-icon() {
    @include btn-outlined();
    @include btn-icon(
            $icon: 'chevron-right-black',
            $icon-hover: 'chevron-right-white',
    );
}

@mixin btn-icon-only(
    $button-fixed-height: 35px
) {
    @include btn();
    position: relative;
    border-radius: 50%;
    height: $button-fixed-height;
    width: $button-fixed-height;
    padding: 0;

    &:after {
        position: absolute;
        top: 10px;
        left: 50%;
        margin: 0 -50% 0 0;
    }
}

@mixin btn-icon(
    $icon: 'chevron-right-white',
    $icon-hover: 'chevron-right-black',
    $icon-margin: 0 0 0 $indent__s,
    $icon-animation: shake-right 0.3s linear both
) {
    &:after {
        @include icon(
            $icon: $icon
        );
        margin: $icon-margin;
    }

    &:hover {
        &:after {
            @include icon(
            $icon: $icon-hover,
            );
            animation: $icon-animation;
        }
    }
    @include btn-defaults;
}

@mixin btn-icon-link(
    $icon-margin: 0 0 0 $indent__s,
    $icon-animation: shake-right 0.3s linear both
) {
    &:after {
        display: none;
    }

    &:hover {
        &:after {
            display: none;
        }
    }

    background: transparent;
    padding: 0;
    border: none;
    color: $color-white;
    border-bottom: 1px solid $color-white;
}

@mixin btn-outlined-gray() {
    @include btn;
    border: 1px solid rgba($color-gray-dark, 0.2);

    &:after {
        background-color: rgba($color-gray-dark, 0.2) !important;
    }

    &:hover {
        border: 1px solid $color-black;

        &:after {
            background-color: $color-black !important;
        }
    }
}

@mixin btn-cta() {
    @include btn-defaults;
    @include lib-button(
        $_button-background: $color-cta !important,
        $_button-color: $color-white !important,
        $_button-border: 1px solid $color-cta !important,

        $_button-background-hover: $color-white !important,
        $_button-color-hover: $color-cta !important,
        $_button-border-hover: 1px solid $color-cta !important,

        $_button-background-active: $color-white !important,
        $_button-color-active: $color-cta !important
    );

    &:after {
        content: '' !important;
        width: 25px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        transition: background-color 250ms ease-in-out;
        background-color: $color-black !important;
    }




}
