//
//  Breadcrumbs
//  ---------------------------------------------

.breadcrumbs {
    padding: 1.875rem 0 2rem 0;
    margin: 0;

    .catalog-product-view & {
        padding-bottom: 5rem;
    }

    @include max-screen($screen__m) {
        padding-top: 3.875rem;
    }

    .page-layout-nobreadcrumbs & {
        display: none;
    }

    .items {

        & > li {
            font-size: 1.2rem;
            line-height: 2.4;
            letter-spacing: 1px;
        }

        a {
            text-decoration: none;
            // fix whitespace bug
            display: inline-block;
            font-weight: $font-weight__light;
            color: $color-gray-dark;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    strong {
        font-weight: $font-weight__regular;
        color: $color-black;
    }

    .item:not(:last-child):after {
        content: '\2014';
        vertical-align: middle;
        margin: 0 4px;
    }

    // only remove margin when title is set (pagebuilder)
    & + .page-title-wrapper {
        margin-top: -20px;
    }
}

@include max-screen($screen__m) {
    .catalog-category-view:not(.category-dames):not(.category-heren) {
        .breadcrumbs {
            display: none;
        }
    }
}
