#CybotCookiebotDialog a, #CybotCookiebotDialog br, #CybotCookiebotDialog div, #CybotCookiebotDialog td {
    position: relative;
    margin-left: auto;
    padding-left: 8px;
    padding-right: 15px;
}
/* Changes the img source to: https://content.mailplus.nl/m4/images/user70515/logo_v2.jpg */
#CybotCookiebotDialogPoweredbyLink {
    z-index: 2147483646;
    width: 48px;
    height: 36px;
    margin-top: 20px;
    margin-right: 57%;
    width: initial !important;
    height: initial !important;
    text-align: center !important;
    display: block !important;
}
#CybotCookiebotDialogPoweredbyImage {
    content:url(https://www.cavallaronapoli.com/static/frontend/BluebirdDay/Cavallaro/nl_NL/images/logo.svg)!important;
    width: unset !important;
    max-width: 200px;
}
#CybotCookiebotDialogBodyContentTitle {
    font-size: 18pt;
    font-weight: 700;
    line-height: 110%;
    margin: 0;
    margin-top: 25px;
    padding-left: 8px !important;
    padding-right: 15px !important;
    font-size: 22px !important;
}
#CybotCookiebotDialogBodyButtonAccept {
    text-align: center;
    position: relative !important;
}
#CybotCookiebotDialogBodyButtonDecline {
    color: lightgrey !important;
    position: relative !important;
    background-color: transparent !important;
    border: 1px solid #d4d4d4 !important;
}
#CybotCookiebotDialogBodyContentText p
{
    line-height: 1.5;
    margin-bottom: 15px;
}
#CybotCookiebotDialogFooter {
    .CybotCookiebotDialogBodyButton {
        font-size: 12px;
        text-transform: none;
    }

    #CybotCookiebotDialogBodyLevelButtonCustomize {
        .CybotCookiebotDialogArrow {
            padding: 0 !important;
        }
    }
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    button {
        &:after {
            display: none;
        }
    }
}

@media only screen and (max-width: 450px) {
    #CybotCookiebotDialogPoweredbyLink
    {
        margin-right: 61%;
    }
    #CybotCookiebotDialogBodyButtonAccept
    {
        margin-left: 10px !important;
        line-height: 30px !important;
    }

    #CybotCookiebotDialogBodyButtonDetails
    {
        padding-top: 7px !important;
    }

}

.cookie-status-message {
    display: none;
}
