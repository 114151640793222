.aw-acp-popup {
    max-width: 1030px;

    .aw-acp-popup__image-wrapper {
        text-align: center;
    }

    //temp: display shoe sizes in popup
    .product-item .swatch-attribute.size {
        display: block;
    }

    @include min-screen($screen__m) {
        .aw-acp-popup__columns {
            justify-content: space-between;
        }

        .aw-acp-popup__column:first-child {
            width: 50%;
            min-width: 250px;
        }

        .aw-acp-popup__column:nth-child(2) {
            flex-grow: 0;
            width: 50%;
            height: 100%;
        }

        .aw-acp-popup__result {
            background: $color-gray-light01;
            padding: 2rem 4rem;
        }

        .aw-acp-popup__related .products-grid.products-acp-related .product-item {
            width: 25% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            .product-item-info {
                padding: 0 5px;
            }
        }
    }

    .aw-acp-popup__result,
    .aw-acp-popup__reviews-wrapper,
    .aw-acp-popup__image-wrapper {
        text-align: left;
        line-height: 0.75;
    }

    .aw-acp-popup__result-title {
        font-weight: $font-weight__bold;
    }

    .aw-acp-popup__message {
        text-align: center;
    }

    @include min-screen($screen__m) {
        .aw-acp-popup--result .aw-acp-popup__columns {
            min-height: 240px;
            align-items: stretch;
        }

        .products-grid:not(.swiper-container) .product-items {
            margin: 30px -5px 0;
            justify-content: flex-start;
        }
    }

    .product-item .tocart {
        background: $color-black;
        color: $color-white;
        font-size: 1.1rem;

        &:after {
            background: $color-white;
        }
    }
}
