.product.data.items {
    max-width: 1112px;
    margin: 0 auto;

    @include max-screen($screen__m) {
        margin-top: $indent__l;
    }

    .attribute.description {
        @include max-screen($screen__m) {
           font-size: 13px;
        }
    }

    > .item.title:not(.disabled),
    > .item.title {
        @include max-screen($screen__m) {
            margin: 0;
        }

        > .switch {
            width: 100%;
            padding: 0 !important; // sass-lint:disable-line no-important
            font-size: 1.6rem;
            font-family: $heading__font-family__base;
            font-weight: 500;
            cursor: pointer;
            letter-spacing: 2px;
            display: flex;
            justify-content: space-between;
            border: 0;
            border-bottom: 1px solid $color-gray-darker !important; // sass-lint:disable-line no-important

            &,
            &:visited {
                color: $color-gray-dark;
            }

            &:after {
                content: '';
                float: right;
                position: relative;
                top: 0;
                background-image: url('../images/icons/icon-tab-plus.svg');
                background-repeat: no-repeat;
                background-size: cover;
                height: 11px;
                width: 11px;
                transform: translateY(-5.5px) translateX(-5px) scale(1.2);
                top: 50%;

                @include min-screen($screen__m) {
                    font-size: 18px;
                    font-weight: 300;
                }
            }

            @include max-screen($screen__m) {
                font-size: 15px !important;

                &,
                &:focus,
                &:hover {
                    color: $color-black !important; // sass-lint:disable-line no-important
                    background: transparent;
                }
            }

            @include min-screen($screen__m) {
                display: block;
                background: transparent;
                margin-right: 40px;
                padding-top: $indent__s !important; // sass-lint:disable-line no-important
                padding-bottom: $indent__s !important; // sass-lint:disable-line no-important

                &,
                &:focus,
                &:hover {
                    background: transparent;
                }

                &:hover {
                    color: $color-black;
                }
            }
        }

        &.active {
            > .switch {
                @include min-screen($screen__m) {
                    color: $color-black;
                }

                &:after {
                    content: '';
                    background-image: url('../images/icons/icon-tab-min.svg');
                    background-size: cover;
                    height: 1px;
                    width: 11px;
                    transform: translateY(2.5px) translateX(-5px) scale(1.2);
                    top: 50%;
                }
            }

            + .item.content {
                display: block !important; // sass-lint:disable-line no-important
                margin-top: 5px !important; // sass-lint:disable-line no-important
                padding: 5px 0 !important; // sass-lint:disable-line no-important
                border-top: 0 !important; // sass-lint:disable-line no-important
            }
        }
    }

    > .item.content {
        margin-top: 0 !important; // sass-lint:disable-line no-important

        @include min-screen($screen__m) {
            margin-left: 0;
            float: none;
            margin-top: 0 !important; // sass-lint:disable-line no-important
        }

        &,
        p {
            font-size: 1.4rem;
            font-weight: $font-weight__light;
            letter-spacing: 1.5px;
        }

        @include max-screen($screen__m) {
            padding: $indent__s 0 $indent__base;
            border: 0;
            height: auto !important; // sass-lint:disable-line no-important
        }

        @include min-screen($screen__m) {
            display: none;
            border-top: 0;
            padding: 25px 0;
            border-top: 1px solid $color-gray-darker !important;
            position: relative;
            top: -1px;
        }
    }
}


// Redesign 2021
.product-details-tabs {
    @include max-screen($screen__m) {
        max-width: inherit;
    }

    @include max-screen($screen__l) {
        max-width: 100%;
    }

    .product.data.items {
        @include max-screen($screen__m) {
            margin-top: 10px;
        }
    }

    .data.item.content {
        .product.attribute.description {
           font-size: 1.4rem;

            p {
               font-size: 1.4rem !important;
           }
        }
    }

    .additional-attributes-wrapper {
        margin-top: 0 !important;
    }

    .additional-attributes {
        tbody {
            display: flex;
            flex-direction: column;
        }

        tr {
            width: 100% !important;
            padding: 4px 0 !important;
        }
    }
}
