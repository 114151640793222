.widget-product-carousel .product-item,
.products-grid .product-item {
    div[class^='swatch-opt-'] {
        width: 100%;
        min-height: 60px;
        display: none;
        z-index: 19;
        box-sizing: border-box;
    }

    .product-item-details {
        position: relative;

        @include max-screen($screen__s) {
            .primary.btn.btn-dark,
            .btn.btn-dark,
            .stock.unavailable{
                display: none !important;
            }
        }
    }

    .product-image-container {
        overflow: hidden;
    }

    &:hover {
        .swatch-attribute.full_size {
            display: none;

            @include min-screen($screen__l + 1) {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }

        div[class^='swatch-opt-'] {
            @include min-screen($screen__l + 1) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.product-item {
    .swatch-attribute.full_size {
        height: auto;
        overflow: hidden;

        &.is--truncated {
            height: 30px;
            margin-bottom: $indent__s * 1.25 !important;
        }
    }

    .swatch-attribute.full_size .swatch-option,
    .swatchtoggle {
        box-sizing: border-box;
        display: inline-block;
        float: none;
        min-width: 28px;
        height: 28px;
        background: $color-white;
        border: 1px solid $color-border;
        color: $color-gray-dark;
        padding: 0 4px;
        text-align: center;
        line-height: 28px;
        font-size: 1.3rem;
        margin-bottom: 0;

        &[option-empty="true"] {
            display: none;
        }

        &:hover {
            border-color: $color-gray-dark;
            outline: none;
        }

        &.disabled {
            @extend .sr-only;
            color: $color-gray-light;

            &:hover {
                border-color: $color-border;
            }

            &:after {
                display: none;
            }
        }

        &.selected,
        &.selected:hover {
            background: $color-black !important;
            color: $color-white !important;
            outline: none !important;
            border-color: $color-black !important;
        }
    }

    .swatchtoggle {
        display: inline-block;
        align-items: center;
        justify-content: center;
        overflow: hidden; // fix inline flex element drop

        &::after {
            @include icon('plus');
            position: relative;
            bottom: -2px;
        }

        &.is--active::after {
            @include icon('minus');
            position: relative;
            bottom: 3px;
        }
    }

    .swatch-attribute-options {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        display: block;
        text-align: center;
        max-height: unset;
    }
}

.swatch-more {
    position: relative;
    top: -8px;
}

.product-item-details__row {
    display: flex;
    position: relative;
    align-items: center;

    @include max-screen($screen__m + 1) {
        flex-direction: column;
        align-items: flex-start;
    }

    @include screen(1024px, 1118px) {
        flex-direction: column;
        align-items: flex-start;

        .product-sale-label {
            margin-left: 0 !important;
        }
    }

    @include screen(768px, 835px) {
        flex-direction: column;
        align-items: flex-start;

        .product-sale-label {
            margin-left: 0 !important;
        }
    }

    .price-box {
        width: auto;
    }

    .product-sale-label {
        position: relative;
        top: -3px;
        right: inherit;
        margin-left: 10px;

        @include max-screen($screen__m + 1) {
            margin: 0;
        }
    }
}
