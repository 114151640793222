
.product.info.detailed {

    // Tabs accordion sidebar
    .item.title {
        margin: 0;
        float: none;
        display: block;
        width: 100%;
        max-width: 100%;
    }

    .additional-attributes {
        width: 100%;
        font-weight: $font-weight__light;
        font-size: 1.4rem;
        letter-spacing: 1.5px;

        > tbody {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;

            > tr {
                flex: 0 0 100%;
                width: 100%;
                box-sizing: border-box;
                padding: 1px 10px;
                border: none;
                position: relative;
                top: 1px;

                @include min-screen($screen__s) {
                    flex: 0 0 percentage(1/2);
                    width: percentage(1/2)
                }

                @include min-screen($screen__l) {
                    flex: 0 0 percentage(1/3);
                    width: percentage(1/3)
                }
            }
        }
    }

    .additional-attributes-wrapper {
        margin-top: 4rem;
    }

    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
        &.additional-attributes {
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th {
                        font-weight: $font-weight__light;
                        padding: 4px 30px 4px 0;
                        color: $color-gray-dark;
                        width: 180px;
                        max-width: 180px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 400;

                        &:after {
                            content: '' !important;
                        }

                    }

                    > td {
                        font-weight: $font-weight__light;
                        padding: 4px 5px 4px 5px;
                        color: $color-black;

                        @include max-screen($screen__s) {
                            padding: 0;
                            display: flex;

                            &:before {
                                font-weight: $font-weight__light;
                                color: $color-gray-dark;
                                width: 150px;
                                max-width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                content: attr(data-th) " " !important;
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .product.attribute {
        ul {
            font-weight: $font-weight__light;
        }
    }
}

.product.attribute.description {
    margin-bottom: $indent__s;
}

.icon-tooltip-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;

    @include max-screen($screen__s) {
        gap: 3px;
    }

    .icon-tooltip {
        @include max-screen($screen__l) {
            position: relative;
        }

        &:first-child {
            .tooltip {
                @include max-screen($screen__s) {
                    left: auto;
                    right: auto;
                    margin-left: -100px;
                    bottom: 37px;
                }
            }
        }
    }

    .icon {
        cursor: pointer;
        width: 28px;
        height: 28px;
        display: block;
    }

    .tooltip {
        position: absolute;
        bottom: 37px;
        right: 0;
        background: $color-gray;
        border: 1px solid $color-gray-light;
        padding: 5px;
        font-size: 14px;
        display: none;
        z-index: 10;
        width: 200px;
        box-shadow: 1px 1px 4px 0 $color-gray-medium;

        @include max-screen($screen__l) {
            left: auto;
            right: 0;
            bottom: 37px;
        }
    }

    .icon-tooltip:hover {
        .tooltip {
            display: block;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            margin-top: -10px;
            left: auto;
            width: 15px;
            z-index: 11;
            margin-left: 7px;
            height: 8px;
            background-position: bottom;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='svg2' version='1.1' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:cc='http://creativecommons.org/ns%23' xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cg id='background'%3E%3Crect fill='none' height='32' width='32'/%3E%3C/g%3E%3Cg id='arrow_x5F_full_x5F_down' fill='%238c8c8c'%3E%3Cpolygon points='29.998,16 22,16 22,2 9.999,2 9.999,16 1.999,16 15.999,30 '/%3E%3C/g%3E%3C/svg%3E");        }
    }
}
