.product-usps {
    margin-bottom: 10px;
    font-size: 1.3rem;
    letter-spacing: 1.5px;
    background-color: $color-gray;
    padding: 20px 30px 0;
    box-sizing: border-box;
    overflow: hidden;

    @include max-screen($screen__l) {
        max-width: 100% !important;
        margin: 0 !important;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0;
    }

    @include max-screen($screen__m) {
        width: calc(100% + 40px);
        max-width: calc(100% + 40px)!important;
        margin: 10px -20px 0 !important;
        padding: 1.5rem 20px !important;
    }

    .usp-list__title {
        margin-bottom: 20px;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-gray-dark;
    }

    ul {
        margin-bottom: $indent__base;
        margin-left: 0;
        padding: 0;

        @include max-screen($screen__m) {
            margin-bottom: 0;
        }

        li {
            position: relative;
            font-size: 12px !important;
            color: $color-gray-dark;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            padding-left: 4rem;
            margin-bottom: 15px !important;
            margin-left: 0;
            min-height: 25px;

            @include max-screen($screen__m) {
                font-size: 1rem !important;
                background-size: 21px;
                padding-left: 3rem !important;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.time {
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='0 -2.0083179297597056 53 53' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M27,29H17v2h12V13h-2V29z M27,5C14.8,5,5,14.8,5,27c0,6.2,2.6,11.8,6.7,15.8l-5.5,5.5l1.4,1.4l5.5-5.5 C17,47.2,21.8,49,27,49c5.5,0,10.5-2,14.3-5.3l6,6l1.4-1.4l-5.9-5.9c3.9-4,6.2-9.4,6.2-15.3C49,14.8,39.1,5,27,5z M27,47 c-11,0-20-9-20-20s9-20,20-20s20,9,20,20S38,47,27,47z M50.9,3c-3.6-3.6-9-4-12.7-1.1l0,0L37,3l13.9,14l0.4-0.4l0,0c0,0,0,0,0,0 l0.7-0.7l0,0C54.9,12.1,54.5,6.6,50.9,3z M50.9,14.6L39.4,3c3.2-2.3,7.4-1.9,10.4,1.1C52.9,7.2,53.3,11.4,50.9,14.6z M15.8,1.8 L15.8,1.8C12-1,6.6-0.6,3,3c-3.6,3.6-4,9.1-1.1,12.8l0,0l0.7,0.7c0,0,0,0,0,0l0,0L3,17L16.9,3L15.8,1.8z M3,14.6 C0.7,11.4,1.1,7.2,4.2,4.2C7.2,1.1,11.3,0.7,14.6,3L3,14.6z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            &.pickup {
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='0 -5.0016077170418 51 51' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath d='M31,23h-8v8h8V23z M29,29h-4v-4h4V29z M41,23h-8v8h8V23z M39,29h-4v-4h4V29z M47,6V0H4v6H0v14h4v21h43V20h4V6H47z M6,2h39 v4H6V2z M19,39h-7V25h7V39z M45,39H21V23H10v16H6V20h39V39z M49,18H2V8h47V18z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            &.delivery {
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='0 -3.9991568296795954 51 51' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath d='M42.9,0H8.5L0,13.3V43h51V13.3L42.9,0z M41.7,2l6.7,11H29.9L29.1,2H41.7z M22.9,2h4.1l0.8,11h-5.8L22.9,2z M22,15h6v9h-6 V15z M9.6,2h11.3l-0.8,11H2.6L9.6,2z M49,41H2V15h18v11h10V15h19V41z M39,31h7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-7 c-0.6,0-1,0.4-1,1C38,30.6,38.4,31,39,31z M32,35h14c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H32c-0.6,0-1,0.4-1,1C31,34.6,31.4,35,32,35z M46,37h-9c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h9c0.6,0,1-0.4,1-1C47,37.4,46.6,37,46,37z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            &.send {
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='-0.5130000000000017 0 54 54' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath d='M52,44H8V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1s0.4,1,1,1h5v43c0,0.6,0.4,1,1,1h45c0.6,0,1-0.4,1-1C53,44.4,52.6,44,52,44z M13.5,47c-1.9,0-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5C17,48.6,15.4,47,13.5,47z M13.5,52 c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C15,51.3,14.3,52,13.5,52z M46.5,47c-1.9,0-3.5,1.6-3.5,3.5 c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5C50,48.6,48.4,47,46.5,47z M46.5,52c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5 s1.5,0.7,1.5,1.5C48,51.3,47.3,52,46.5,52z M50,26h-5V11H27V3H9v40h41V26z M27,13h16v13H27V13z M25,41H11V5h14V41z M48,41H27V28 h21V41z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            &.return {
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='-3.496000000000002 0 46 46' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath d='M37.3,28.1c-0.8,9-8.5,16.2-17.8,16.2c-9.8,0-17.8-8.1-17.8-17.9c0-9.6,7.3-17.4,17-17.9l0,6.1l12.5-7.3L18.7,0l0,6.7 C8.1,7.2,0,15.8,0,26.3C0,37.1,8.8,46,19.5,46c10.2,0,18.6-7.9,19.5-17.9H37.3z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            &.payment {
                background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' overflow='visible' preserveAspectRatio='none' viewBox='0 -0.5127576054955831 55 55' width='24' height='24'%3E%3Cg%3E%3Cg%3E%3Cpath d='M52.1,20h-3.6l3.2-3.2L36.1,1.2l-2.7,2.7L27.1,0L14.8,20H2.9C1.3,20,0,21.3,0,22.9v28.2C0,52.7,1.3,54,2.9,54h49.2 c1.6,0,2.9-1.3,2.9-2.9V22.9C55,21.3,53.7,20,52.1,20z M36.1,4.1l12.7,12.7L45.7,20H20.2L36.1,4.1z M27.8,2.8l4.1,2.6L17.3,20 h-0.1L27.8,2.8z M53,40H38c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1h15V40z M53,30H38c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h15v9.1 c0,0.5-0.4,0.9-0.9,0.9H2.9C2.4,52,2,51.6,2,51.1V22.9C2,22.4,2.4,22,2.9,22h49.2c0.5,0,0.9,0.4,0.9,0.9V30z M39.8,37.6 c1.9,0,1.9-3,0-3S37.9,37.6,39.8,37.6z' style='fill: rgb(18, 18, 18);' vector-effect='non-scaling-stroke'/%3E%3C/g%3E%3C/g%3E%3Cg/%3E%3C/svg%3E");
            }

            span {
                font-weight: 500;
                margin-right: 7px;
            }

            div {
                margin-left: auto;
            }
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    // Extra information for delivery time
    .usp-tooltip {
        max-height: 0;
        height: 0;
        opacity: 0;
        transition: opacity 250ms ease-in-out, max-height 250ms ease-in-out;

        &.active {
            max-height: 1000px;
            height: auto;
            opacity: 1;


            @include max-screen($screen__m) {
                margin-top: 10px !important;
            }
        }

        * {
            font-size: 12px;
        }
    }
}

body[class*="giftcard"] .product.attribute.product-intro-text li {
    width: 100%;
    padding-left: 20px;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
    }
}
