//
//  Color variables
//  _____________________________________________

// Theme Colors

// General colors
$color-black: #1D1C22;
$color-gray: #EAEAEB;
$color-gray-light: #8c8c8c;
$color-gray-darker: #DDDDDE;
$color-gray-dark: #565458;
$color-gray-medium: #7d7d7d;
$color-gray-light: #AAA9AB;
$color-gray-lighter: #e4e4e4;
$color-gray-lightest: #F5F5F5;
$color-white: #FFF;
$color-red: #C53500;
$color-orange: #e2812f;
$color-green: #64C864;
$color-border: #DDDDDE;
$color-cta: #138A36;

// Primary and secondary colors
$color-primary: $color-black;
$color-secondary: $color-white;

$color-primary-lighter: rgba($color-primary, .5);
