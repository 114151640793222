.pdp-gallery {
    position: relative;
    z-index: 1;

    @include max-screen($screen__s) {
        width: initial !important;
        margin: 0 -20px !important;
    }

    @include max-screen($screen__m) {
        width: 100%;
        display: block !important;
        align-items: center;
        justify-content: center;
        margin: 0;
    }
}

.pdp-gallery-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 101;

    &.is--active {
        display: block
    }
}

body {
    @include max-screen($screen__m) {
        .pdp-gallery-navigation,
        .pdp-gallery-slider {
            .slick-arrow {
                overflow: inherit;
                cursor: pointer;

                @include max-screen(1441px) {
                    margin-top: -50px !important;
                }

                &::before {
                    background-color: black;
                    box-shadow: none;
                }

                &.slick-prev {
                    left: 30px;

                    &::before {
                        left: 0;
                    }

                    &::after {
                        left: 14px;
                        width: 18px;
                        height: 10px;
                        background-image: url('../images/icons/arrow-left-variant.svg');
                    }
                }

                &.slick-next {
                    right: 30px;

                    &::before {
                        right: 0;
                    }

                    &::after {
                        right: 14px;
                        width: 18px;
                        height: 10px;
                        background-image: url('../images/icons/arrow-right-variant.svg');
                    }
                }
            }
        }
    }

    // If popup is active
    &.overlay--active {
        overflow: hidden;
        padding-bottom: 0 !important;
        max-height: 100%;
    }

    &.is-header-sticky.catalog-product-view {
        .header.sticky {
            @include max-screen($screen__m) {
                position: relative !important;
                box-shadow: none !important;
            }
        }
    }

    &.is-header-sticky.catalog-product-view {

        .breadcrumbs {
            @include max-screen($screen__m) {
                margin-top: -58px;
            }
        }
    }

    &.catalog-product-view {
        .breadcrumbs {
            @include max-screen($screen__m) {
                padding-bottom: 1rem;
                padding-top: 5.5rem;
            }
        }

        .mfp-iframe {
            background: transparent;
            box-shadow: none;
        }

        .mfp-image-holder .mfp-close,
        .mfp-iframe-scaler .mfp-close {
            width: 20px;
            margin: 0;
            padding: 0;
            background: transparent;
            border: 0;
            color: white;
            position: fixed;
            top: 20px;
            right: 20px;
            font-size: 10px;
            text-indent: -9999px;

            &::after {
                content: '';
                background-image: url('../images/icons/icon-modal-close.svg');
                background-repeat: no-repeat;
                width: 20px;
                height: 20px !important;
                position: absolute !important;
                top: 0 !important;
                left: 0 !important;
                background-color: transparent !important;
            }
        }

        .mfp-container.mfp-image-holder,
        .mfp-container.mfp-iframe-holder {
            background-color: white;
            margin: 0;
            padding: 0 10px;
            width: 100%;

            .mfp-content {
                height: 85vh !important;
                padding: 0 !important;
            }

            img.mfp-img {
                max-height: 85vh !important;
                padding: 0 !important;
            }

            .mfp-description {
                text-align: center;
                font-size: 11px;
                position: relative;
                z-index: 12;
                margin-top: 15px;
            }

            .mfp-figure {
                position: relative;
                margin: 20px 0;
            }

            .mfp-figure-container {
                cursor: grabbing;
                max-height: 85vh;
            }

            .mfp-figure:after {
                box-shadow: none;
            }

            button.mfp-arrow {
                position: fixed;
                top: 50%;
                background-color: black;
                border-radius: 50%;
                width: 46px;
                height: 46px;
                transform: translateY(26px);
                opacity: 1;

                &:before {
                    display: none !important;
                }

                &:not(.btn-icon):not(.btn-dark-icon):not(.btn-outline-icon):not(.btn-link):not(.slick-arrow):after {
                    background-color: transparent !important;
                }

                &::after {
                    top: 18px !important;
                    margin: 0;
                    padding: 0;
                    background-color: transparent !important;
                    height: 10px !important;
                    left: 14px;
                    width: 18px;
                    background-image: url('../images/icons/arrow-left-variant.svg');
                    background-repeat: no-repeat;
                    border: 0 !important;
                }
            }

            button.mfp-arrow-left {
                left: 40px;

                @include max-screen($screen__xxl) {
                    left: 20px;
                }
            }

            button.mfp-arrow-right {
                right: 40px;

                @include max-screen($screen__xxl) {
                    right: 20px;
                }

                &::after {
                    right: 14px;
                    background-image: url('../images/icons/arrow-right-variant.svg') !important;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}


.pdp-gallery-slider__item {
    margin-bottom: 20px;
    margin-left: 20px;
    overflow: hidden;
    position: relative;

    &:last-child {
        margin-bottom: 20px;
    }

    @include max-screen($screen__m) {
        img {
            width: inherit;
        }

        &:last-child {
            margin-right: 0;
        }

    }

    @include min-screen($screen__m) {
        width: calc(50% - 15px);
        margin-bottom: 5px;
        margin-left: 5px;
        display: inline-block;

        img {
            width: 100%;
        }
    }

    @include min-screen($screen__xl) {
        width: calc(50% - 40px);
        margin-bottom: 20px;
        margin-left: 20px;

        img {
            width: 100%;
        }
    }

}

.pdp-gallery-slider__item--video {
    &.slick-active {
        display: flex !important;
        justify-content: center;
    }

    iframe {
        max-width: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100% !important;
        transform: scale(1.1);
    }

    .pdp-gallery-slider__iframe {
        display: inline-block;
        max-width: 100%;
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 131.6%;
    }
}

.pdp-iframe-playbutton {
    // video play icon incase autoplay is turned off
    @include min-screen($screen__m) {
        &::after {
            position: absolute;
            content: '';
            left: 46%;
            top: 45%;
            opacity: 0.5;
            transform: scale(3.5);
            width: 29px;
            height: 29px;
            background-image: url('../images/icons/icon-video.svg');
        }
    }
}

.pdp-gallery-item-wrapper {
    width: calc(100% - 100px);
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    @include max-screen($screen__m) {
        width: 100%;
        padding-bottom: 86.538%;
        height: 0;
        overflow: hidden;
    }

    @include min-screen($screen__m) {
        width: 100%;
        margin: 0;
    }
}

// Zoom
.pdp-gallery-slider-zoom {
    display: none;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-image: url('../images/icons/icon-zoom.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    background-color: white;
    z-index: 1;
    transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
    box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.1);
    left: inherit;
    right: 20px;
    bottom: 20px;

    &.is--hidden {
        opacity: 0;
        visibility: hidden;
    }

    @include max-screen($screen__m) {
        width: 40px;
        height: 40px;
        right: 12px;
        bottom: 12px;
    }
}

.pdp-gallery-slider__item:hover {
    .pdp-gallery-slider-zoom {
        display: block;
    }
}
