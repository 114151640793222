.cart-container {
    @include max-screen($screen__m) {
        display: flex;
        flex-direction: column-reverse;
    }

    // Giftcard options
    .gift-item-block {
        .gift-wrapping-preview {
            @include min-screen($screen__m) {
                max-width: 300px;
            }
        }

        .gift-wrapping-title {
            display: inline-flex;
            flex-direction: column;
            margin-top: 20px;

            @include max-screen($screen__m) {
              margin-top: 20px;
            }

            @include min-screen($screen__m) {
                position: absolute;
                left: 350px;
                top: 35%;
            }

            .gift-wrapping-name {
                font-weight: bold;
                font-size: 2rem;
            }

            .regular-price {
                margin-bottom: 20px;
            }
        }

        .gift-summary .actions-toolbar>.secondary {
            display: inline-flex;
            justify-content: space-between;
        }

        .gift-summary .actions-toolbar>.secondary .action {
            @include btn-dark;
            margin-top: 10px;
            width: calc(50% - 10px);
        }

        .gift-wrapping-item {
            border: 1px solid #ececec;
        }

        .gift-wrapping-item._active span:before {
            border-width: 2px !important; // sass-lint:disable-line no-important
        }

        .gift-options .actions-toolbar .secondary .action {
            @include btn-outlined;
            width: auto !important; // sass-lint:disable-line no-important

            &.action-update {
                order: 2;
                margin-left: 20px !important;
                @include btn-dark();

                &:after {
                    background-color: white !important;
                }

                &:hover {
                    &:after {
                        background-color: $color-black !important;
                    }
                }
            }
        }

        .gift-options .actions-toolbar .secondary {
            display: flex;
            justify-content: flex-end;

            @include max-screen($screen__m) {
                justify-content: space-between;
            }
        }

         .title {
            border: none;
            outline: none;
            font-size: 1.5rem;
            box-shadow: none;
            margin-top: 0;

            &::after {
                position: relative;
                top: 3px;
            }
        }

        .gift-options .gift-wrapping-title .action-remove {
            @include btn-outlined;
        }
    }

    // make all prices light + correct font
    span.price {
        font-family: $heading__font-family-name__base;
        font-weight: 300 !important; // sass-lint:disable-line no-important
        display: block;
    }

    span.price::first-letter {
        visibility: hidden;
    }

    //Make sure text doesn't get cut off for some reason
    span.summary_totals_price {
        display: flex;
        justify-content: end;
        font-weight: 400;
    }

    .checkout-methods-items .action.primary {
        @include btn-dark();

        &:after {
            background-color: white !important;
        }

        &:hover {
            &:after {
                background-color: $color-black !important;
            }
        }
    }

    .cart.table-wrapper {

        .col {
            &.price {
                text-transform: capitalize;
            }

            &.item {
                padding-top: $indent__s;
                padding-bottom: $indent__xs;

                @include min-screen($screen__m) {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }

            @include max-screen($screen__m) {
                padding-top: 0;

                &.qty:before,
                &.price:before,
                &.subtotal:before,
                &.msrp:before {
                    font-size: 1.5rem;
                    padding-bottom: $indent__xs;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .cart-price {
                    .price {
                        font-weight: $font-weight__semibold;
                        font-size: 1.5rem;
                    }
                }
            }
        }

        thead {
            span {
                font-family: $heading__font-family__base;
                font-weight: $font-weight__heavier;
                line-height: $line-height__s;
                font-size: 2rem;
            }
        }

        .product-item-photo {
            line-height: 0;
        }

        .product-item-details {
            line-height: $line-height__s;

            .cart.item.message.notice {
                padding: 0;
                color: $color-black;
                font-weight: $font-weight__semibold;
                background: none;
            }

            .cart.item.message.notice>:first-child:before {
                display: none;
            }
        }

        .product-item-name {
            font-weight: $font-weight__bold;
            margin-bottom: 0;

            a {
                color: $color-black;
            }
        }

        .product-item-subtitle {
            margin-bottom: $indent__xs;
        }

        .item-options {
            margin-bottom: 0;
            font-size: inherit;

            dt {
                font-weight: $font-weight__regular;
            }
        }

        .item-actions {
            td {
                padding: 0;
            }

            @include max-screen($screen__m) {
                .actions-toolbar {
                    text-align: left;
                }

                .action {
                    margin: 0 !important;
                }
            }
        }

        .actions-toolbar {
            float: right;

            @include min-screen($screen__m) {
                margin-top: -35px;
            }

            @include max-screen($screen__m) {
                margin: 15px 0 10px 0;
                width: 100%;

            }

            > .action {
                @include lib-button-as-link;
                appearance: none;
                text-transform: none;
                letter-spacing: normal;
                margin-left: $indent__s;
                text-decoration: none;

                &.action-edit {
                    display: none;
                }

                span {
                    text-decoration: underline;
                }

                &:hover {
                    span {
                        text-decoration: none;
                    }
                }

                &:after {
                    display: none;
                }

                &.action-edit {
                    &:before {
                        @include icon('icon-pencil-gray');
                        position: relative;
                        top: 4px;
                        margin-right: 3px;
                    }
                }

                &.action-delete {
                    &:before {
                        @include icon('trash-light');
                        position: relative;
                        top: 4px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    .cart-gift-item {
        @include min-screen($screen__l) {
            width: 67%;
        }

        @include screen($screen__s, $screen__m) {
            width: 100%;
        }
    }


    .cart-summary .actions-toolbar > .primary button {
        @include btn-outlined-gray;
        border-color: white;

        &:after {
            color: $color-black;
        }
    }

    .form-cart {
        margin-bottom: $indent__xxl;

        @include max-screen($screen__m) {
            margin-bottom: 0;
        }

        @include screen($screen__m, $screen__l) {
            width: 100% !important;
            float: none;
        }

        @include min-screen($screen__l) {
            width: 67%;
        }

        .main.actions .action.clear,
        .main.actions .action.update {
            &:after {
                display: none !important;
            }
        }

        .main.actions {
            @include max-screen($screen__m) {
                display: flex;
                flex-direction: column;

                .action.update {
                    order: 1;
                }

                .action.continue {
                    order: 2;
                }

                .action.clear {
                    order: 3;
                }
            }

            .action {
                margin-bottom: $indent__s !important; // sass-lint:disable-line no-important
                @include min-screen($screen__m) {
                    margin-bottom: $indent__xs !important; // sass-lint:disable-line no-important
                }

                &.continue {
                    appearance: none;
                    display: block;
                    text-decoration: none;
                    font-weight: $font-weight__regular;

                    @include min-screen($screen__m) {
                        @include lib-button-as-link;
                    }

                    span {
                        text-decoration: underline;
                    }

                    &:hover {
                        span {
                            text-decoration: none;
                        }
                    }

                    &:before {
                        position: relative;
                        top: 3px;
                    }

                    @include max-screen($screen__m) {
                        @include btn-outlined;

                        span {
                            text-decoration: none;
                        }

                    }
                }

                &.update,
                &.clear {
                    @include btn-outlined;

                    @include min-screen($screen__m) {

                        @include lib-button-as-link;
                        appearance: none;
                        text-transform: none;
                        font-size: 1.4rem;
                        letter-spacing: 0;
                        font-weight: $font-weight__regular;
                        font-family: $font-family__base;

                        &:before {
                            line-height: 20px;
                        }
                    }
                }
            }

            @include min-screen($screen__m) {
                display: flex;
                justify-content: flex-start;

                .action.clear {
                    order: 3;
                    margin-left: $indent__s;

                    &::before {
                        content: '|';
                        display: inline-block;
                        position: relative;
                        margin-right: $indent__s;
                    }
                }

                .action.update {
                    order: 2;
                    margin-left: auto;
                }
            }

            @include screen($screen__m, $screen__l) {
                margin-top: $indent__m;
            }

        }
    }

    .cart-summary {
        top: 0 !important; // sass-lint:disable-line no-important
        padding-bottom: 0;
        margin-bottom: 0;

        .cart-summary__footer {
            margin: 25px -15px 0 -15px;
            background: white;
            padding: 15px 0;
        }

        @include screen($screen__m, $screen__l) {
            width: 100% !important;
            float: none;
            position: inherit;
        }

        @include min-screen($screen__l) {
            width: 30%;
        }

        @include screen($screen__s, $screen__m) {
            width: 100%;
            margin-top: 25px;
        }

        > .title {
            font-size: 2rem;
            margin-top: 1.9rem;
        }

        .block {
            &.discount {
                border-top: 1px solid $color-black;

                > .title {
                    border-top: 0;
                    margin-top: $indent__xs;
                    font-size: 1.6rem;
                    padding-left: $indent__xs;

                    &:after {
                        transition: transform 0.25s ease;
                    }
                }

                .label {
                    display: none;
                }

                .input-text {
                    background: transparent;
                }

                &.active {
                    > .title {
                        &:after {
                            transform: rotate(180deg);
                            content: $icon-down;
                        }
                    }
                }

                .actions-toolbar {
                    .primary {
                        &,
                        .action {
                            width: 100%;
                        }

                        .action {
                            padding-top: $indent__xs;
                            padding-bottom: $indent__xs;
                        }
                    }
                }

                .fieldset {
                    margin: 0 5px 25px 5px;

                    @include screen($screen__s, $screen__m) {
                        .field {
                            width: calc(60% - #{$indent__s});
                            display: inline-block;
                        }

                        .actions-toolbar {
                            width: 40%;
                            display: inline-block;
                            float: right;
                        }
                    }
                }
            }
        }

        .cart-totals {
            .totals-tax,
            .mark > .value {
                display: none;
            }

            .grand.totals.excl {
                display: none;
            }
        }

        .block.discount {
            .fieldset {
                .actions-toolbar {
                    width: 100%;
                    margin-bottom: 30px;
                    margin-top: 10px;
                    > .primary {
                        > .action {
                            @include btn-dark();
                            padding-top: 12.5px !important;
                            padding-bottom: 12.5px !important;

                            &:after {
                                background-color: white !important;
                            }

                            &:hover {
                                &:after {
                                    background-color: $color-black !important;
                                }
                            }
                        }
                    }
                }
            }

            .fieldset .field {
                width: 100%;
            }
        }

        .show_order_summary {
            margin-top: -2rem;
            margin-bottom: 2rem;
        }

        .cart_mobile_anchor {
            display: none;
            font-family: "Prompt", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 12px;

            @include max-screen($screen__l) {
                display: inline-block;
            }
        }
    }
}

.checkout-cart-index {
    .block.crosssell {
        margin-bottom: $indent__xxl !important; // sass-lint:disable-line no-important
    }
}

// Cart USPS
.cart-usps {
    .pagebuilder-column-group {
        @include max-screen($screen__l) {
            flex-direction: column;
        }

        @include screen($screen__m, $screen__l) {
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .pagebuilder-column {
        @include max-screen($screen__l) {
            width: 100% !important;
        }
    }

    .pagebuilder-column {
        @include screen($screen__m, $screen__l) {
            width: 45% !important;
        }
    }
}

.cart-usps__icon {
    position: relative;
    padding-left: 50px !important;

    &::before {
        position: absolute;
        top: 50%;

        left: -10px;
        width: 54px;
        content: '';
        transform: scale(0.6);
    }
}

.cart-usps__icon-fast-shipping {
    &::before {
        background: url("../images/icons/fast-shipping.svg") no-repeat left;
        height: 41px;
        margin-top: -20px;
    }
}

.cart-usps__icon-free-shipping {
    &::before {
        background: url("../images/icons/free-shipping.svg") no-repeat left;
        height: 34px;
        margin-top: -18px;
    }
}

.cart-usps__icon-safe-payment {
    &::before {
        background: url("../images/icons/safe-payment.svg") no-repeat left;
        height: 45px;
        margin-top: -22px;
    }
}

.cart-usps__icon-free-returns {
    &::before {
        background: url("../images/icons/free-returns.svg") no-repeat left;
        height: 34px;
        margin-top: -18px;
    }
}

.checkout-shipping-address__description {
    margin-bottom: 20px;
}

.cart-summary {
    .block.giftcard {
        display: none;
    }
}

// CART POPUP
.aw-acp-popup {
    .aw-acp-popup__message {
        background: #27ae60;
        color: white;
        border-radius: 0;
        font-size: 12px;
    }

    .aw-acp-popup__result-view-cart {
        a.btn.btn-outline {
            background-color: black;
            color: white;
            border-color: black;

            &:after {
                background-color: white !important;
            }
        }
    }

    .aw-acp-popup__submit-wrapper.aw-acp-popup__column {
        a.btn.btn-outline:after {
            background-color: white !important;
        }

    }

    .aw-acp-popup__submit-wrapper {
        .btn[data-action="checkout"] {
            background-color: white;
            color: black;
            border-color: black;
            margin-bottom: 0;

            &:hover {
                &:after {
                    background-color: white !important;
                }
            }
        }

        .btn[data-action="checkout"]:after {
            background-color: black !important;
        }

        .btn[data-action="checkout"]:hover {
            background-color: black;
            color: white;
            border-color: black;
        }

        .btn[data-action="checkout"]:hover:after {
            background-color: white !important;
        }
    }

    .aw-acp-popup__close-button {
        display: none;
    }

    .aw-acp-popup__account-message {
        display: none;
        margin-bottom: 40px;

        @include min-screen($screen__m) {
            display: none !important;
        }

        p {
            font-size: 1.4rem;
        }

        @include max-screen($screen__m) {
            &.active {
                display: block !important;
            }
        }
    }

    .aw-acp-popup__result {
        margin-bottom: 20px !important;

        @include min-screen($screen__m) {
            margin-bottom: 0 !important;
            padding-bottom: 0;
        }

        .aw-acp-popup__account-message {
            display: none !important;
            margin-top: 20px;
            background-color: white;
            margin: 0 -40px;
            padding: 0;
            margin-bottom: -10px;
            padding-top: 10px;

            p {
                margin-bottom: 0;
            }

            @include min-screen($screen__m) {
                &.active {
                    display: block !important;
                }
            }
        }
    }

    .aw-acp-popup__message a {
        color: white;
    }

    .aw-acp-popup__result-view-cart {
        @include min-screen($screen__m) {
            margin-bottom: 20px;
        }
    }

    @include max-screen($screen__m) {
        text-align: center;

        .aw-acp-popup__close-wrapper {
            display: block;

            a.btn.btn-outline:last-child {
                display: block;
                padding: 0;
                border: 0;
            }
        }

        .aw-acp-popup__result-price {
            font-size: 1.8rem;
        }

        .aw-acp-popup__mobile-header {
            display: none;
        }

        .aw-acp-popup__result-title {
            width: 50%;
            float: left;
            margin: 0;
        }

        .aw-acp-popup__result-price {
            width: 50%;
            float: right;
            text-align: right;
            margin: 0;
        }

        .aw-acp-popup__mobile-header .aw-acp-popup__mobile-close {
            padding: 0;
        }

        .aw-acp-popup__mobile-header .aw-acp-popup__mobile-close:after {
            display: none;
        }

        .aw-acp-popup__main {
            padding: 40px 5px 0 5px;
        }

        .aw-acp-popup__result-view-cart a.btn {
            display: none;
        }

        .aw-acp-popup__result-view-cart a.btn:after {
            background-color: white !important;
        }

        .aw-acp-popup--result .aw-acp-popup__columns .aw-acp-popup__result-items {
            display: none;
        }

        .aw-acp-popup--result .aw-acp-popup__columns .aw-acp-popup__result {
            width: 100%;
            display: flex;
            margin: 0;
            padding: 20px;
            box-sizing: border-box;
            background-color: #EAEAEB;
        }

        .aw-acp-popup--result .aw-acp-popup__columns {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;

            .aw-acp-popup__column {
                order: 2;
            }

            .aw-acp-popup__column:last-child {
                order: 1;
            }
        }

        .aw-acp-popup__account-message {
            margin-top: 0;
            margin-bottom: 20px;
            text-align: center;
        }

        .aw-acp-popup .aw-acp-popup__account-message p {
            font-size: 14px;
        }

        .aw-acp-popup__submit-wrapper {
            position: relative;
        }

        .aw-acp-popup__actions.aw-acp-popup__columns.aw-acp-popup__actions.aw-acp-popup__columns--submit + a[data-action="continue"] {
            text-decoration: underline;
            padding: 0;
            background: transparent !important;
            border: 0;
            color: black !important;
            text-transform: inherit;
            font-size: 14px;
            font-weight: normal;
            margin-top: 10px;
        }

        .aw-acp-popup__actions.aw-acp-popup__columns.aw-acp-popup__actions.aw-acp-popup__columns--submit + a[data-action="continue"]:after {
            display: none;
        }

        .aw-acp-popup__submit-wrapper.aw-acp-popup__column a.btn.btn-outline {
            background-color: black;
            color: white;
            border-color: black;
            text-align: center;
        }

        .aw-acp-popup__submit-wrapper.aw-acp-popup__column a.btn.btn-outline:after {
            background-color: white !important;
        }

        .aw-acp-popup__submit-wrapper .btn[data-action="wait"] {
            margin-bottom: 20px;
        }

        .aw-acp-popup__submit-wrapper .btn[data-action="checkout"] {
            margin-bottom: 10px;
            background-color: white;
            color: black;
            border-color: black;

            &:hover:after {
                background-color: white !important;
            }
        }

        .aw-acp-popup__submit-wrapper .btn[data-action="checkout"]:after {
            background-color: black !important;
        }

        .aw-acp-popup__close-button {
            display: block;
            position: fixed;
            top: 10px;
            right: 20px;
            font-size: 20px;
            cursor: pointer;
        }
    }

    .aw-acp-popup__result-price {
        //Hide eurosign logic
        .price {
            display: block;
            margin-left: -1.8rem;
        }

        .price::first-letter {
            visibility: hidden;
        }
    }

    .product.details.product-item-details .product-item-name {
        min-height: inherit !important;
        text-transform: inherit !important;
    }
}
