// Specific Pagebuilder Faq styling
.pagebuilder-faq {
    list-style: none;
    margin-bottom: 30px;
    padding: 0;
}

li.pagebuilder-faq__item {
    padding: 10px 0 !important;
    border-bottom: 1px solid #ececec;

    .pagebuilder-faq__item {
        padding: 0 !important;
    }

    h3 {
        position: relative;
        padding: 0 !important;
        cursor: pointer;

        &::after {
            background-image: url('../images/icons/icon-tab-plus.svg');
            background-repeat: no-repeat;
            background-size: cover;
            height: 11px;
            width: 11px;
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -5.5px;
        }
    }

    h3[aria-selected="true"] {
        &::after {
            background-image: url('../images/icons/icon-tab-min.svg');
            background-size: cover;
            height: 1px;
            width: 11px;
        }
    }

}
