//
//  Layout
//  ---------------------------------------------

@mixin layout-inner($max-width: $layout__max-width) {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: $max-width;
    padding-left: $layout-indent__width;
    padding-right: $layout-indent__width;
    width: auto;
}



@mixin layout-inner-small($max-width: $layout__inner-width) {
    @include layout-inner($max-width);
}

@mixin reset-inner() {
    margin-left: auto;
    margin-right: auto;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

//
//  Padding / Margin
//  ---------------------------------------------

@mixin no-padding($important: false) {
@if $important == true {
        padding-right: 0 !important; // sass-lint:disable-line no-important
        padding-left: 0 !important; // sass-lint:disable-line no-important
    }
@else {
        padding-right: 0;
        padding-left: 0;
    }
}

@mixin no-margin($important: false) {
@if $important == true {
        margin-right: 0 !important; // sass-lint:disable-line no-important
        margin-left: 0 !important; // sass-lint:disable-line no-important
    }
@else {
        margin-right: 0;
        margin-left: 0;
    }
}


.navigation,
.breadcrumbs,
.page-header .header.panel,
.header.content,
.footer.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.page-main,
.header.headroom,
.footer__columns,
.footer__bottom,
.sticky-bottom__inner,
.headroom--not-top,
.page.messages {
    .header.content {
        @include screen($screen__xl, 1920px) {
            padding: 0 20px;
        }
    }

    @include screen($screen__xl, 1920px) {
        width: 100% !important;
        max-width: calc(100% - 50px) !important;
    }
}

.page.messages {
    @include screen($screen__xl, 1920px) {
        max-width: 100% !important;
    }
}

.header.sticky {
    @include screen($screen__xl, 1920px) {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.footer__columns {
    @include min-screen($screen__xl) {
        width: 100% !important;
        margin: 0;
    }
}


.checkout-cart-index,
.checkout-cart-index.header-is-pinned {
    .page-main {
        margin-top: 30px;

        @include max-screen($screen__md) {
            margin-top: 80px;
        }
    }
}
