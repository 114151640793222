.seo {
    margin: $indent__xxl 0 $indent__xl;

    @include min-screen($screen__m) {
        display: flex;
    }

    &__text {
        @include min-screen($screen__m) {
            flex: 1 1 60%;
        }
    }

    &__usps {
        margin-top: $indent__xl;

        @include min-screen($screen__m) {
            flex: 1 1 40%;
            padding-left: $indent__xl * 2;
            margin-top: 0;
        }

        ul {
            @include list-unstyled;
            @include list-usps;
            @include text-normal;
            margin-top: 2rem;
            margin-bottom: 4rem;
        }
    }
}
