.checkout-index-index {
    .fieldset.address{
        .input-text[value=""] {
            border-color: $border-color__base;
        }

        //.input-text[aria-invalid="false"]:not([value=""]) {
        //    border-color: $color-green;
        //    background-image: url('../images/icons/check-green.svg');
        //    background-repeat: no-repeat;
        //    background-position: center right 10px;
        //}

        .input-text[aria-invalid="true"] {
            border-color: $color-red;
            background-image: url('../images/icons/close-red.svg');
            background-repeat: no-repeat;
            background-position: center right 10px;
        }
    }

    // Phone new TEL fields
    .field._error .control .input-text {
        border-color: $color-red !important;
        background-image: url('../images/icons/close-red.svg');
        background-repeat: no-repeat;
        background-position: center right 10px;
    }

    .field .control._valid .input-text,
    .field .control input.valid {
        background-image: url('../images/icons/check-green.svg');
        background-repeat: no-repeat;
        background-position: center right 10px;
    }
}
