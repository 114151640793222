.product-recent-item-color {
    display: block;
    text-align: center;

    em {
        font-style: normal;
    }
}

.widget.block-viewed-products-grid {
    margin: 0 -30px;

    .products-grid {
        margin: 0;
    }

    .slick-slider .slick-list {
        margin: 0 -20px;
    }

    .recently-viewed-slider {
        display: block !important;
    }

    .block-title {
        margin-bottom: 20px;
    }

    .product-items .product-item {
        max-width: 100% !important;
    }

    .product-item-name a {
        font-weight: 400;
    }

    .product-item .price-box .price {
        font-size: 1.5rem;
    }
}

@include min-screen($screen__m) {
    .page-layout-1column .block.block-viewed-products-grid .block-title strong[role="heading"] {
        font-weight: 300 !important;
        font-size: 2.8rem !important;
    }
}
