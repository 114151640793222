.page-footer--spaced {
    margin-top: 60px;

    @include max-screen($screen__m) {
        margin-top: 0;
    }
}

.footer {
    &__content {
        background-color: $color-black;
        color: $color-white;
    }

    &.content {
        border-top: none;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;

        @include min-screen($screen__m) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

