//
//  Layout
//  ---------------------------------------------

$page__max-width: 1920px;
$layout__max-width: $page__max-width;
$layout__inner-width: 1480px;
$layout-max-inner-offset: ($layout__inner-width - $page__max-width) / 2; // offset left/right side for wider containers

$layout__width-xs-indent: 20px;
$page-main__offset-top: 40px;
$page-main__offset-top-desktop: 60px;
