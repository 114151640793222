.column.main .block.block-products-list {
    margin: 0;
}

.block-products-list {
    position: relative;
    margin: $indent__base 0;

    .block-products-list--header {
        @include max-screen($screen__m) {
            max-width: calc(100% - 120px);

            h2 {
                margin-top: 8px;
                font-size: $h4__font-size;
            }
        }
    }

    .swatch-attribute {
        &.size {
            .size-advice {
                display: none;
            }
        }
    }

    .swiper-container {
        padding: $indent__xl 0 0;

        @include max-screen($screen__m) {
            margin-right: -20px;
            clip-path: inset(-100vw -100vw -100vw 0);
        }
    }

    .swiper-button--container {
        .swiper-button-line {
            content: '';
            position: absolute;
            width: calc(50vw - 50% + 106px);
            right: calc(-50vw + 50%);
            top: 23px;
            border-bottom: dashed 1px grey;
            z-index: -1;
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background: white;
            background-color: $color-white;
            border: 1px solid $color-primary;
            border-radius: 50%;
            top: 23px;
            height: 47px;
            width: 47px;

            &:after {
                content: '';
                display: none;
            }

            .arrow-next {
                @include icon('chevron-right-black');
            }

            .arrow-prev {
                @include icon('chevron-right-black');
                transform: rotate(180deg);
            }

            &:hover {
                background-color: $color-primary;
                border-color: $color-primary;

                .arrow-next,
                .arrow-prev {
                    @include icon('chevron-right-white');
                }

                .arrow-next {
                    animation: shake-right 0.3s linear both;
                }

                .arrow-prev {
                    animation: shake-left-rotated 0.3s linear both;
                }
            }

            &.swiper-button-disabled {
                opacity: 1;
                border-color: $color-gray-darker;

                i {
                    opacity: 0.2;
                }
            }
        }

        .swiper-button-next {
            right: 0;
            left: auto;
        }

        .swiper-button-prev {
            right: 60px;
            left: auto;
        }
    }
}

.cms-index-index {
    .block-products-list {
        // hide sizes
        .swatch-attribute.size {
            display: none;
        }
    }
}

// when screen size is bigger than layout
@include min-screen($layout__max-width) {

    .block-products-list {
        // overflow right only
        .swiper-container {
            overflow: visible;
            clip-path: inset(-100vw -100vw -100vw 0);
        }
    }

}
