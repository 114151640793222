.header__account {
    display: block;
    margin-right: $indent__base;
    position: relative;

    .guestRedDot.hide-guest-message {
        display: none !important;
    }

    .guestMessage.hide-guest-message {
        display: none !important;
    }

    @include max-screen($screen__m) {
        margin-right: 36px;
        margin-top: -8px;

        .dropdown__content {
            display: none !important;
        }
    }

    @include min-screen($screen__m) {
        display: block;

        &.header__account--user {
            @include lib-dropdown;

            .action.toggle {
                margin-top: 3px;
                display: flex !important;
            }

            .dropdown__content {
                display: none;

                &.active {
                    &,
                    .dropdown {
                        display: block;
                    }
                }
            }

            ul.dropdown {
                @include dropdown-defaults-right(4px);
            }
        }
    }

    .header__account-icon {
        display: flex;
        align-items: center;
        text-decoration: none;

        @include max-screen($screen__m) {
            transform: translateX(8px);
        }

        span {
            margin-left: $indent__s;
            font-size: 1.2rem;
            display: none;

            @include max-screen($screen__m) {
                position: absolute;
                left: 0;
                bottom: -13px;
                font-size: 9px;
                margin: 0 0 0 calc(-50% - 4px);
                white-space: nowrap;
                display: none;
            }
        }

        &:before {
            @include icon('user');
            display: inline-block;
            content: '' !important;
        }

        &:hover:before {
            opacity: 0.5;
        }
    }

    &--guest {
        .guestRedDot {
            &.firstTimeVisitDot {
                display: block;
                &:after {
                    content: '';
                    height: 10px;
                    width: 10px;
                    top: -2px;
                    right: -8px;
                    position: absolute;
                    background: #ec0808;
                    border-radius: 50%;

                    @include max-screen($screen__m) {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    .guestMessage {
        position: absolute;
        display: none;
        padding: 1.2rem 2rem;
        margin-top: 2.9rem;
        width: 160px;
        right: -10px;
        background: #EAEAEB;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        z-index: 1;

        p {
            text-align: center;
            word-wrap: break-word;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.7rem;
            margin: 0;
            padding: 5px 0;
        }

        a {
            text-decoration: none;
        }

        &:hover {
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
        }

        &:before {
            content: "";
            position: absolute;
            top: -12px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 15px solid #EAEAEB;
            z-index: 210;
        }

        .closeGuestMessage {
            position: absolute;
            top: 10px;
            right: 15px;
            transition: all 200ms;
            font-size: 1.5rem;
            text-decoration: none;
            color: #1D1C22;
            z-index: 5;

            &:hover {
                color: #565458;
            }
        }

        @include max-screen($screen__l) {
            margin-top: 2.1rem;
        }
    }

    .firstTimeVisitGuest {
        display: block;
    }

    .hiddenGuestMessage {
        display: none;
    }
}

// Visibility triggered from knockout
.header__account.header__account--user {
    display: none;

    &.is--visible {
        display: block;
        padding-bottom: 8px;
        bottom: -4px;

        &:hover {
            .dropdown__content,
            .dropdown__content .dropdown {
                display: block;
            }

            .dropdown__content .dropdown {
                top: calc(100% - 4px);
            }
        }

        .dropdown__content .dropwdown {
            margin-top: 0;
        }
    }
}
