
// Custom Hero styling
.pagebuilder-hero-small-row {
    .pagebuilder-column-group {
        justify-content: space-between;
        margin-top: $indent__xl;
    }

    .pagebuilder-column {
        a {
            text-decoration: none;
        }

        figure {
            margin-bottom: $indent__l !important;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                transition: transform 350ms ease-in-out;
            }

            @include max-screen($screen__m) {
                margin-bottom: $indent__m !important;
            }
        }

        @include max-screen($screen__m) {
            &:not(:last-child) {
                margin-bottom: $indent__l !important;
            }
        }

        @include min-screen($screen__m) {
            width: calc(50% - 20px) !important;

            &:hover figure img {
                transform: scale(1.05);
            }
        }
    }
}

.pagebuilder-hero-small-row--bg-image {
    margin-bottom: $indent__m !important;
    margin-top: 0 !important;

    .pagebuilder-column {
        position: relative;
        align-items: center;
        justify-content: center !important;

        figure {
            position: relative;
            margin-bottom: $indent__l !important;
        }

        [data-content-type="text"] {
            * {
                color: $color-white !important;
            }
        }
    }
}

.pagebuilder-hero-small-content {
    position: absolute;
    margin-top: $indent__s;

    div[class^='pagebuilder-'] {
        [data-content-type="buttons"] {
            margin-bottom: 0 !important;
        }
    }
}

// Page builder hero's
.pagebuilder-hero-large-row {
    @include max-screen($screen__m) {
        min-height: 300px !important;
        background-image: none !important;

        figure {
            margin-bottom: $indent__l !important;
        }

        [data-content-type="text"] {
            * {
                color: $color-black !important;
            }

            em span {
                color: $color-gray-dark !important;
            }
        }

        [data-content-type="buttons"] {
            @media only screen and (max-width: 370px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .btn {
            @include btn-dark;

            @media only screen and (max-width: 371px) {
                font-size: 1.1rem;
                margin: 0 !important;

                & + .btn {
                    margin: 0 !important;
                }
            }

            @media only screen and (max-width: 348px) {
                width: 100%;
                margin-bottom: 5px !important;
            }

            &:after {
                background-color: $color-white !important;
            }

            &:hover  {
                color: $color-black !important;

                a,
                span {
                    color: $color-black !important;
                }
            }
        }

        // Video Background
        &.jarallax {
            min-height: 300px !important;

            [data-content-type="text"] {
                * {
                    color: $color-white !important;
                }
            }

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba($color-black, 0.3);
            }
        }
    }

    @include min-screen($screen__m) {
        .title-widget {
            h1 {
                font-size: 5.3rem !important;
                letter-spacing: 11.6px;
                font-weight: 600;

                em {
                    font-size: 2.2rem !important;
                    letter-spacing: 7px !important;
                }
            }
        }

        h1 {
            font-size: 5.3rem !important;
        }

        .title-widget * {
            color: white;
        }
    }

    @include max-screen($screen__m) {
        h1 {
            font-size: 2.7rem;
            font-weight: 300;
            margin-bottom: 10px;
        }
    }

    [data-content-type="text"] {
        @include min-screen($screen__m)  {
            margin-bottom: $indent__l*2 !important;

            &:first-of-type {
                margin-bottom: $indent__s !important;
            }
        }

        a {
            color: $color-white;
            font-weight: 400;
            font-size: 1.3rem;
            text-decoration: none;

            span {
                border-bottom: 1px solid $color-white;
            }

            @include max-screen($screen__m) {
                color: $color-black;

                span {
                    border-color: $color-black;
                }
            }
        }

        &.hero-link-location {
            @include hero-link-location;
            position: relative;
        }
    }
}

.pagebuilder-hero-large-row.jarallax + .pagebuilder-column-group-cloned {
    margin-top: $indent__m;

    * {
        color: $color-black !important;
    }

    h2:last-child {
        margin-bottom: $indent__s !important;
    }

    .btn {
        @include btn-outlined;
    }

    .hero-link-location {
        @include hero-link-location;
        position: relative;

        span {
            border-bottom: 1px solid $color-black;
        }
    }

    @include min-screen($screen__m)  {
        display: none;
    }
}


