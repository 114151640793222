.header,
.offscreen-nav {
    .nav-toggle {
        top: 10px;
        left: 10px;
        padding: 10px;
        cursor: pointer;

        &:before {
            display: none;
        }

        @include min-screen($screen__m) {
            display: none;
            top: 28px;
            left: 0;
            padding-left: 0;
        }

        @include max-screen($screen__xl) {
            display: flex;
            align-items: center;

            &__label {
                height: auto;
                width: auto;
                margin: 0;
                padding-left: 36px;
                font-weight: 300;
                color: $color-primary;
                font-size: 1.4rem;
                clip: auto;


                &:last-child {
                    display: none;
                }
            }
        }

        @include max-screen($screen__xs) {
            &__label {
                padding-left: 28px;
                font-size: 1.2rem;
            }
        }

        &__icon {
            display: block;
            width: 22px;
            height: 22px;
            position: relative;
            margin: 0;

            span {
                display: block;
                position: absolute;
                height: 1px;
                width: 100%;
                background: $color-primary;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                margin: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
                transition-property: left, transform, top, opacity;

                &:nth-child(1) {
                    top: 4px;
                }

                &:nth-child(2) {
                    top: 10px;
                }

                &:nth-child(3) {
                    top: 16px;
                }
            }
        }

        &:hover {
            .nav-toggle__label {
                color: $color-primary-lighter;
            }

            .nav-toggle__icon {
                span {
                    background: $color-primary-lighter;
                }
            }
        }
    }
}

body.offscreen-nav-open .headroom,
.headroom--not-top {
    .nav-toggle {
        @include min-screen($screen__m) {
            top: 28px;
            left: 10px;
            padding-left: 10px;
        }
    }
}

body.offscreen-nav-open {
    .header,
    .offscreen-nav {
        .nav-toggle__icon {
            span {
                &:nth-child(1) {
                    top: 10px;
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    transform: rotate(135deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    left: -60px;
                }

                &:nth-child(3) {
                    top: 10px;
                    -webkit-transform: rotate(-135deg);
                    -moz-transform: rotate(-135deg);
                    -o-transform: rotate(-135deg);
                    transform: rotate(-135deg);
                }
            }
        }
    }
}
