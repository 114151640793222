.product-info-main {
    .page-title.bbd-page-title {
        line-height: 1;
        margin-bottom: $indent__xs;
        font-size: $h3__font-size;
        letter-spacing: 3.78px;
        color: $color-black;
        font-weight: $font-weight__regular;

        @include min-screen($screen__m) {
            margin-top: 0;
            font-size: 38px;
            letter-spacing: 5.33px;
        }

        @include min-screen($screen__l) {
            margin-top: 0;
            font-size: $h1__font-size;
            letter-spacing: $h1__letter-spacing;
        }
    }

    .page-title__subtitle {
        font-style: normal;
        font-family: $heading_sub__font-family-name__base;
        text-transform: uppercase;
        font-weight: $font-weight__light;
        font-size: 1.4rem;
        letter-spacing: 2px;
        color: $color-gray-dark;

        @include min-screen($screen__l) {
            font-size: 2.2rem;
            letter-spacing: 7px;
        }
    }

    .page-title-wrapper.product {
        display: none;
    }

    .product-info-overview-price {
        display: flex;
        align-items: baseline;
        position: relative;

        .product-sale-label {
            position: relative;
            top: -2px;
            right: 0;
            margin-left: 10px;
        }
    }

    .product.attribute.overview {
        margin: 0 $indent__s 0 0;
        @include min-screen($screen__l) {
            margin: 0 $indent__base 0 0;
        }

        &,
        p {
            font-weight: $font-weight__light;
            font-family: $heading_sub__font-family-name__base;
            color: $color-gray-dark;
            text-transform: uppercase;
            line-height: 1;
            font-size: 1.8rem;
            letter-spacing: 5px;

            @include min-screen($screen__l) {
                font-size: 2.2rem;
                letter-spacing: 7px;
            }
        }

        p {
            margin: 0;
        }
    }

    .product.attribute.product-intro-text {
        margin-top: $indent__l;

        @include max-screen($screen__m) {
            margin-top: 10px;
        }

        .type {
            display: none;
        }

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            padding: 24px 0 17px 0;
            border-top: 1px solid $color-gray-darker;
            border-bottom: 1px solid $color-gray-darker;

            @include max-screen($screen__m) {
                max-width: inherit;
                padding: 15px 0 8px 0;
            }

            @include max-screen($screen__l) {
                max-width: 100%;
            }

            @include min-screen($screen__l) {
                margin-bottom: 30px;
            }
        }

        li {
            font-size: 1.5rem;
            font-weight: $font-weight__light;
            letter-spacing: 1.9px;
            color: $color-black;
            margin-bottom: 7px !important;
            width: calc(50% - 10px);
            position: relative;
            padding-left: 10px;

            &:before {
                content: '-';
                font-size: 15px;
                color: $color-black;
                position: absolute;
                left: 0;
            }

            @include max-screen($screen__m) {
                font-size: 1.3rem;
            }
        }
    }

    .more-info {
        display: block;
        margin: $indent__base 0;
        font-size: 1.5rem;
        font-weight: $font-weight__regular;
        letter-spacing: 1.9px;

        @include min-screen($screen__l) {
            font-size: 1.7rem;
            letter-spacing: 2.25px;
        }
    }
}

.add-to-cart-header {
    .product-sale-label {
        display: none;
    }
}
