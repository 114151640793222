.add-to-cart-header {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: block;
    z-index: 110;
    padding: 10px 0;
    box-sizing: border-box;
    transform: translateY(100%);
    background-color: #EAEAEB;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        transform: translateY(100%);
    }

    @include max-screen($screen__s) {
        bottom: 0;
    }

    @include max-screen($screen__m) {
        display: none;
    }

    @include min-screen($screen__m) {
        .add-to-cart-header__button.js-sticky-add-to-cart {
            margin-left: 20px;
        }

        &.is--active {
            transform: translateY(0);
            z-index: 10;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                transform: translateY(0%);
            }

            .aw-acp-mpf-open & {
                transform: translateY(100%);
            }
        }
    }

    @include min-screen($screen__xs) {
        height: 80px;
    }

    .wrapper {
        max-width: calc(100% - 300px) !important;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include max-screen(1360px) {
            margin: 0 15px;
            max-width: 100% !important;
        }
    }
}

.add-to-cart-header__title-wrapper,
.add-to-cart-header__add-wrapper {
    display: flex;
    align-items: center;
}

.add-to-cart-header__title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include max-screen($screen__xs) {
        display: none;
    }

    h3 {
        margin-top: 8px;
        margin-bottom: 5px;
        font-size: 1.8rem;

        @include max-screen($screen__l) {
            font-size: 1.2rem;
        }

    }

    h4 {
        margin-top: 0;
        font-style: normal;
        font-family: $heading_sub__font-family-name__base;
        text-transform: uppercase;
        font-weight: $font-weight__light;
        font-size: 1.3rem;
        letter-spacing: 2px;
        color: $color-gray-dark;
    }
}

.add-to-cart-header__add-wrapper {
    justify-content: center;

    .product-info-price {
        order: 1;

        .price-box.price-final_price {
            display: flex;
            align-items: center;
        }

        .old-price {
            order: 1;
            font-size: 1.3rem;
            opacity: 0.5;
            margin-right: 10px;

            * {
                font-size: 1.3rem;
            }
        }

        .normal-price {
            order: 2;

            * {
                font-size: 1.5rem;
            }
        }
    }

    .box-tocart__price {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .price {
            font-weight: 600 !important;

            @include max-screen($screen__xs) {
                font-size: 1.6rem;
            }
        }
    }

    .product-info-overview-price {
        .price-label {
            display: none;
        }
    }
}

.add-to-cart-header__button {
    background-color: black;
    color: $color-white;
    order: 3;
    padding: 0 60px 0 $indent__base !important;
    position: relative;
    width: 100%;
    height: 50px;
    box-sizing: border-box;

    &:before {
        @include icon('cart-white');
        position: absolute;
        right: 20px;
        top: 14px;
    }

    &:after {
        display: none !important;
    }

    &.disabled {
        color: black !important;
        background-color: white !important;
        opacity: 1 !important;

        &:before {
            @include icon('cart');
        }

        &:focus {
            color: black !important;
            background-color: white !important;
            opacity: 1 !important;
        }

        &:hover {
            color: black !important;
            background-color: white !important;
            opacity: 1 !important;
        }
    }

    &:hover {
        &:before {
            @include icon('cart');
        }
    }
}

.add-to-cart-header__size-selection {
    order: 2;
    display: none;

    .swatch-dropdown,
    .swatch-dropdown-placeholder {
        display: block;
        min-width: 140px!important;
        margin: 0 20px;
    }

    .swatch-dropdown .action.toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 1rem;
        border: 1px solid #1D1C22;
        background-color: #fff;
        font-size: 15px;
        box-sizing: border-box;
        // height: auto;

        &:after {
            float: right;
            position: relative;
            right: -5px;
        }

        @include min-screen($screen__m) {
            min-width: 288px;
        }
    }

    .swatch-dropdown ul.dropdown {
        top: inherit !important;
        bottom: 1px !important;
        border-bottom: 0 !important;
        border-top: 1px solid #565458 !important;
    }

    .swatch-dropdown__selected {
        font-size: 1.3rem;
    }
}

// Stock popup
@include max-screen($screen__l) {
    .dropdown--active {
        .product-stock-indicator-dropdown__container {
            overflow: hidden;
        }

        .product-stock-indicator-dropdown__dropdown-content {
            position: relative;
            padding: 0;
            box-shadow: none;
            background: none;
        }

        .product-stock-indicator__store-list-header-row {
            margin: 0;
        }

        .product-stock-indicator__store-list-row {
            width: 50%;
            margin: 5px 0;
            font-size: 13px;
        }
    }
}


.product-giftcard-tcs {
    .product-info-overview-price,
    .add-to-cart-header__add-wrapper .product-info-price {
        display: none !important;
    }
}

.toggle-size-selector-button.btn.btn-dark,
.size-selector__submit-button,
.cart-container .checkout-methods-items .action.primary,
.checkout-index-index .checkout-shipping-method .actions-toolbar .action.primary,
.opc-wrapper .actions-toolbar>.primary .action:not(.disabled),
.aw-acp-popup .aw-acp-popup__submit-wrapper .btn[data-action=checkout] {
    @include btn-cta;
}
