//TODO: remove after testing
.message.global.demo {
    display: none;
}

.page-header {
    border-bottom: 0;
    position: relative;
    z-index: 101; // for minicart dropdown in sticky header

    .catalog-product-view & {
        &.headroom--pinned {
            position: fixed;
        }

        &.headroom--top {
            position: relative;
            z-index: 99;
        }
    }

    @include min-screen($screen__m) {
        margin-bottom: 0;
    }

    [data-content-type="row"] {
        margin-bottom: 0 !important;
    }
}

.header.content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    height: calc(#{$header-height} - 36px);
    padding-top: 0;
    box-sizing: border-box;
    width: 100%;

    @include min-screen($screen__m) {
        width: calc(100% - 40px);
        height: $header-height-desktop;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@include min-screen($screen__m) {
    .header.sticky {
        @include layout-inner;
        padding-left: 0;
        padding-right: 0;
    }
}

body.offscreen-nav-open .headroom,
.headroom--not-top {
    @include min-screen($screen__m) {
        @include reset-inner;
        max-width: none !important;

        .header.content {
            @include layout-inner;
            height: $header-height-desktop-sticky;
        }
    }
}

body.checkout-cart-index {
    @include max-screen($screen__m) {
        .header.sticky {
            height: 60px;
        }

        .page-header {
            height: 40px;
        }

        .block-search {
            display: none;
        }
    }
}
