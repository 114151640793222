
// Map storelocator css overwrites
// PDP & Checkout
///////////////////////////////////////
.catalog-product-view,
.checkout-index-index {
    #mw-store-locator-locations {
        // Search (location) button
        .mw-sl__store__select,
        .mw-sl__search__submit {
            background: $color-black;
            border: 1px solid #222128;
            box-sizing: border-box;
            border-radius: 0;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 2.5px;
            line-height: 2.1rem;
            margin: 0;
            padding: 12.5px 35px 12.5px 20px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .2s ease-in-out,color .2s ease-in-out,border .2s ease-in-out;
            vertical-align: middle;

            &:after {
                background-color: white !important;
                content: "";
                height: 1px;
                position: absolute;
                right: 0;
                top: 50%;
                transition: background-color .25s ease-in-out;
                width: 25px;
            }

            &:hover {
                background: transparent;
                color: $color-black;

                &:after {
                    background-color:$color-black !important;
                }
            }
        }

        // Button if not enough quantity
        .mw-sl__store__select__gray {
            height: 40px;
            margin: 10px 0;
            max-width: unset;
            width: 100%;
            padding: 5px 30px;
        }

        // My location button
        .mw-sl__search__current-location {
            border-radius: 0;
            width: 100%;
            box-sizing: border-box;
            position: relative;
            min-height: 50px;
            display: inline-block;
            border: 1px solid $color-black;
            background: transparent!important;
            box-shadow: none!important;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;

            & > a {
                text-align: center;
                text-decoration: none;
                color: $color-black;
                font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
                text-transform: uppercase;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: 2.5px;
                line-height: 2.1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

            &:hover > a {
                color: #fff;
                text-decoration: none;
                font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
                text-transform: uppercase;
                font-size: 1.3rem;
                font-weight: 500;
                letter-spacing: 2.5px;
                line-height: 2.1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

            &:hover {
                background: $color-black !important;
            }

            &:before {
                @include icon('location');
                position: absolute;
                right: 15px;
                top: 14px;

                @include max-screen($screen__m) {
                    top: 9px;
                }

                &:hover {
                    &:before {
                        @include icon('location-white');
                        transition: 200ms ease-in-out;
                    }
                }
            }
        }

        // Header of found stores block
        .mw-sl__stores__header .location-header {
            .mw-sl__store__info__name {
                display: none; // to display country or all stores selector
            }
        }

        // Header when my location has been used
        .mw-sl__stores__header .mw-sl__stores__current {
            margin-left: 15%;
            padding-top: 15px;
            font-weight: 500;
            font-style: normal;
            line-height: 1.5;
            font-size: 14px;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        // Store-detail-information overlay
        .mw-sl__stores__details.is-active {
            .mw-sl__stores__details__image {
                display: none;
            }

            .mw-sl__stores__header__item_pin,
            .mw-sl__icon--close {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .mw-sl__stores__details__name {
                color: $color-black;
                font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
                font-size: 1.8rem;
                font-weight: 400;
                letter-spacing: 3.78px;
                line-height: 1.33;
                margin-bottom: 1rem;
                margin-top: 1.5rem;
            }

            .mw-sl__stores__details__address_details {
                width: 100%;
                font-style: normal;
                line-height: 1.5;
                font-size: 14px;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            .mw-sl__stores__details__route {
                display: none;
            }
        }

        .mw-sl__stores__details__wrapper {
            align-items: flex-start;
        }

        // After having used search
        .mw-sl__search-select {
            display: flex;
            flex-direction: column;
        }

        .mw-sl__search-select-content {
            display: flex;
            flex-direction: row;
            margin: 10px;
        }

        .mw-sl__search-select-location {
            flex: 1;
        }

        .mw-sl__search-select-actions {
            display: inline-flex;
            flex: 1;
            margin: 5px;

            &:first-child {
                margin: 0 5px 0 0;
            }

            &:last-child {
                margin: 0 0 0 5px;
            }
        }

        .mw-sl__search-select-action {
            flex: 1;
            background: $color-black;
            box-sizing: border-box;
            border-radius: 0;
            color: #fff;
            cursor: pointer;
            display: inline-block;
            border: 1px solid $color-black;
            font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            letter-spacing: 2.5px;
            line-height: 2.1rem;
            margin: 5px;
            padding: 10px 35px 10px 20px;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color .2s ease-in-out,color .2s ease-in-out,border .2s ease-in-out;
            vertical-align: middle;

            &:after {
                background-color: white;
                content: "";
                height: 1px;
                position: absolute;
                right: 0;
                top: 50%;
                transition: background-color .25s ease-in-out;
                width: 25px;
            }

            &:hover {
                background-color: white !important;
                color: $color-black !important;

                &:after {
                    background-color: white !important;
                }
            }
        }
    }

    #mw-store-locator-locations .mw-sl__search__current-location .mw-sl__icon {
        display: none;
    }

    #mw-store-locator-locations .mw-sl__store__info {
        width: 100%;
    }
}

// pdp only
///////////////////////////////////////
.catalog-product-view {
    #mw-store-locator-locations .mw-sl__stores__list__item__right {
        display: none;
    }
}

///////////
// Checkout only
///////////////////////////////////////
.checkout-index-index {
    #mw-store-locator-locations {
        .mw-sl__stores__list__item {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            @include max-screen($screen__m) {
               display: block !important;
            }

            .mw-sl__store__info {
                width: 100%;
            }

            .mw-sl__stores__list__item__right {
                width: 100%;
                max-width: unset;
            }
        }

        .mw-sl__store__select {
            height: 40px;
            margin: 10px 0;
            max-width: unset;
            width: 100%;
            padding: 5px 30px;
            transition: background-color .2s ease-in-out,color .2s ease-in-out,border .2s ease-in-out;
        }
    }
}

.checkout-index-index {
    #mw-store-locator-locations {
        .mw-sl__store__select {
            &:hover {
                background: white !important;
                color: $color-black !important;
            }
        }
    }
}

.mageworx-modal-location {
    .action-close {
        width: 32px;
        height: 32px;
        background-color: $color-black !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        text-align: center;
        right: 1.1rem;
        top: 1.1rem;

        &::before {
            color: white;
            transition: transform 250ms ease-in-out;
        }

        &:hover {
            background-color: $color-black !important;

            &::before {
                color: white !important;
                transform: rotate(90deg);
            }

        }
    }
}

// Mobile specifics
///////////////////////////////////////
@include max-screen($screen__m) {
    // Modal overlay mobile
    .mageworx-modal-location.modal-slide._show {
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;

        .modal-content {
            padding: 1rem;
        }

        #mw-store-locator-locations {
            .mw-sl__or {
                display: none;
            }

            .mw-sl__stores {
                height: auto;
                overflow-y: scroll;
            }

            .mw-sl__search__radius {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .mw-sl__search__current-location {
                margin-top: 10px;
            }
        }
    }

    //Hide map
    #mw-store-locator-locations .mw-sl__content--map-overlay .mw-sl__map {
        display: none;
        top: 0;
    }

    .catalog-product-view,
    .checkout-index-index {
        #mw-store-locator-locations .mw-sl__search__current-location {
            min-height: 40px;
        }
    }

    .box-tocart {
        .location-block-pdp {
            order: 1;
            display: inline-flex;
            font-weight: 400;
            margin-top: 0;
            flex: 0 0 auto;
            width: auto;
        }

        .location-button {
            margin: 5px 0 10px;
            padding: 0;
            border: none;
            background: transparent;
            height: auto;
            min-height: unset;
            width: auto;
            margin-top: -3px;

            &:before {
                transform: scale(0.5);
                left: -4px;
                right: auto;
                top: 1px;
            }

            &__select_option,
            &__text {
                font-size: 1.1rem;
                text-transform: none;
                letter-spacing: 1.5px;
                font-style: normal;
                font-weight: 300;
                font-family: Prompt,Helvetica Neue,Helvetica,Arial,sans-serif;
                padding: 0 0 0 21px;
                color: $color-black;
                text-decoration: underline;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:hover {
                &__select_option,
                &__text {
                    text-decoration: none;
                }

                &:before {
                    transform: scale(0.5);
                    left: -4px;
                    right: auto;
                    top: 2px;
                }
            }
        }
    }

    .catalog-product-view .product-options-bottom .box-tocart .actions {
        flex-direction: row;
        flex-wrap: wrap;
    }
}


