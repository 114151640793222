// Frontools v1.8.1 Checkout fix for: https://github.com/SnowdogApps/magento2-theme-blank-sass/pull/224/files
.field-tooltip {
    .label {
        @include abs-visually-hidden();
    }
}

.opc-wrapper {
    width: 100% !important;
}

@include min-screen($screen__m) {
    .opc-wrapper {
        width: 60% !important;
        padding: 0 4rem;
        box-sizing: border-box;
    }

    .opc-sidebar {
        width: 40% !important;
        margin-top: -107px; // compensate for checkout steps height
    }

    .authentication-wrapper {
        width: auto;
    }

    .opc-wrapper li.checkout-shipping-address {
        margin: 0 4rem;
        margin-top: 2rem;
    }
}

// Remove 'create account after ordering' note at email field
.opc-wrapper .form-login .fieldset input[name="username"] + .field-tooltip + .note {
    display: none !important;
}
