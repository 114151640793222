.opc-sidebar {
    background: #f5f5f5;

    .table-totals,
    .grand.totals {
        border-top: 1px solid $color-black !important; // sass-lint:disable-line no-important
    }

    .grand.totals.excl {
        display: none !important; //Hide excl Tax rule in checkout
    }

    .block.items-in-cart {
        margin-bottom: 0;
    }

    .totals-tax,
    .totals.shipping .value {
        display: none;
    }

    .table-totals * > * {
        font-weight: 500 !important; // sass-lint:disable-line no-important
    }

    .shipping-information-title .action-edit {
        display: none;
    }
}

.opc-block-summary {
    background: transparent;
    padding-bottom: 0;

    .items-in-cart > .title {
        display: none;
    }
}

.opc-block-summary > .title,
.opc-block-shipping-information .shipping-information-title {
    font-size: 2rem;
    font-weight: 500;
    border-bottom: none;
    margin: 0;
}

html[lang="de"] {
    .opc-sidebar {
        //.table-totals * > * {
        //    display: block;
        //}

        .table-totals * > .totals {
            display: inline-block;
            width: 100%;
        }

        .table-totals * > .price {
            display: inline-block;
        }

        .table-totals * > .price::first-letter {
            visibility: hidden;
        }

        .table-totals * > .price::after {
            content: ' €';
        }
    }

    .opc-block-summary .table-totals .grand .mark,
    .opc-block-summary .table-totals .amount {
        width: 100%;
    }
}
