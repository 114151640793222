.vertical-note {
    position: relative;

    &__inner {
        position: absolute;
        transform: rotate(90deg);
        transform-origin: 0 30px;
        height: 30px;
        display: flex;
        line-height: 1;
        align-items: flex-end;
    }

    &__text {
        font-size: 1.5rem;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 0.75px;
    }

    &__symbol {
        display: inline-block;
        height: 1px;
        width: 56px;
        background: $color-black;
        position: relative;
        top: -7px;
        left: 15px;

        &:after {
            width: 9px;
            height: 9px;
            display: block;
            content: '';
            background: white;
            border: 1px solid #000;
            border-radius: 50%;
            position: absolute;
            right: 10px;
            top: -5px;
        }
    }

    &.vertical-note--right {
        float: right;
        right: (20px + 20px); // margin + offset
    }
}
