.grecaptcha-badge {
    visibility: hidden;
}

@include min-screen($screen__m) {
    [id^="minimized-trustbadge"] {
        bottom: 90px !important;

        &._1foxozs {
            bottom: 90px !important;

            &:hover {
                bottom: 90px !important;
            }
        }


        &:hover {
            bottom: 90px !important;
        }
    }
}

.catalog-product-view {
    @include min-screen($screen__m) {
        [id^="minimized-trustbadge"] {
            bottom: 170px !important;

            &._1foxozs {
                bottom: 170px !important;

                &:hover {
                    bottom: 170px !important;
                }
            }

            &:hover {
                bottom: 170px !important;
            }
        }
    }
}

// Filter z-index fix
.filters-active {
    #robin_popover,
    #robin_tab_container,
    #trustbadge-container-98e3dadd90eb493088abdc5597a70810{
        z-index: 0 !important;
    }
}

#robin_tab_container {
    opacity: 1;
    transition: opacity 250ms linear;

    &.hide {
        opacity: 0;
    }
}
