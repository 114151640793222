.size-advice {
    @include lib-clearfix;
    clear: both;
    margin-top: -#{$indent__xs};

    &,
    p {
        font-size: 1.3rem;
    }

    .tooltip-widget {
        top: 3px;
        margin-left: $indent__xs;

        .tooltip-widget__content {
            top: -12px;
        }
    }
}

// Size advice tool button
.product-add-form {
    .size-advice-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -5px;
        min-height: 22px;
    }

    .size-advice-link {
        display: none;
        align-items: center;
        justify-content: flex-start;
        order: 1;
        text-decoration: none;
        animation: wobble 1s ease infinite;
        animation-iteration-count: 2;
        padding: 10px 0;

        .size-advice-text {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 1px;
            padding-left: 5px;
        }

        &:hover {
            background-color: transparent;

            svg path {
                stroke: #000;
                fill: #000;
            }

            .size-advice-text {
                color: #000;
                text-decoration: underline;
            }
        }
    }

    @include max-screen($screen__m) {
        .size-advice-link {
            margin-top: 10px;
            color: #565458;

            .size-advice-text {
                color: #565458;

                &:hover {
                    color: #565458;
                }
            }

            svg path {
                stroke: #565458;
                fill: #565458;

                &:hover {
                    stroke: #565458;
                    fill: #565458;
                }
            }
        }
    }
}

@keyframes wobble {
    0%, 100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-15px) rotate(-3deg);
        transform: translateX(-15px) rotate(-3deg);
    }

    30% {
        -webkit-transform: translateX(calc(15px / 2)) rotate(3deg);
        transform: translateX(calc(15px / 2)) rotate(3deg);
    }

    45% {
        -webkit-transform: translateX(calc(-15px / 2)) rotate(calc(-3deg / 1.8));
        transform: translateX(calc(-15px / 2)) rotate(calc(-3deg / 1.8));
    }

    60% {
        -webkit-transform: translateX(calc(15px / 3.3)) rotate(calc(3deg / 3));
        transform: translateX(calc(15px / 3.3)) rotate(calc(3deg / 3));
    }

    75% {
        -webkit-transform: translateX(calc(-15px / 5.5)) rotate(calc(-3deg / 5));
        transform: translateX(calc(-15px / 5.5)) rotate(calc(-3deg / 5));
    }
}
