.contact-index-index {
    .form.contact {
        @include form-reset;
        background: $color-gray;
        padding: 20px 40px 40px;

        .fieldset {
            .legend {
                display: none;
            }
        }

        .action.submit {
            @include btn-dark;
        }

        input[type="text"],
        input[type="password"],
        input[type="url"],
        input[type="tel"],
        input[type="search"],
        input[type="number"],
        input[type="datetime"],
        input[type="email"],
        textarea {
            background-color: transparent;
        }
    }

    .sidebar-main {
        @include max-screen($screen__m) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
        }
    }
}
