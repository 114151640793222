.header.usps {
    margin: 0 -20px;
    position: relative;
    height: $panel-wrapper-height;
    overflow: hidden;

    ul {
        @include list-usps();
        margin-bottom: 0;
        height: $panel-wrapper-height;
        overflow: hidden;
        display: flex;
        width: 100%;
    }

    li {
        display: flex;
        align-items: center;
        height: $panel-wrapper-height;
        justify-content: center;
        font-size: 1.3rem;
        margin-bottom: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        transform: translate3d(100%, 0, 0);
        opacity: 0;
        transition: transform 0.5s;

        &.exit {
            transform: translate3d(-100%, 0, 0);
            opacity: 1;
        }

        &.enter {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        @include min-screen($screen__l) {
            font-size: 1.4rem;
        }

        &.usp--trustedshops:before {
            width: 16px;
            height: 16px;
            background: url('../images/trusted-shops.png');
            background-size: cover;
        }
    }

    li p {
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1;
        display: inline-block;

        @include max-screen($screen__m) {
            line-height: 1;
            font-size: 1.2rem;
        }
    }

    a {
        max-width: calc(100% - 40px);
        line-height: 1;
        text-align: left;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include max-screen($screen__m) {
        ul {
            li {
                padding: 0 10px;
            }
        }
    }

    @include min-screen($screen__l) {
        float: left;
        width: 100vw;
        position: absolute;
        max-width: $layout__max-width;
        max-width: 100%;
        text-align: center;
    }

    @include max-screen(360px) {
        a {
            max-width: calc(100% - 80px);
        }
    }

    svg {
        display: inline-block;
        min-width: 72px;
    }

    .rating__background {
        fill: grey;
        stroke-width: 0;
        height: 100%;
        width: 100%;
    }

    .rating__value {
        fill: #FFB94B;
        height: 100%;
    }
}

.usp__rating-svg {
    width: 72px;
    height: auto;
    margin: 0 1rem;
}

.usp__ratings-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100% !important;
    }
}
