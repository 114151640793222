.catalog-product-view {
    .page-main {
        margin-bottom: 60px;
        margin-top: 10px;
    }
}


body[class*="product-giftcard"] {
    .page-main {
        margin-bottom: 0;

        .column.main {
            min-height: inherit;
        }
    }
}

.catalog-product-view .product-main {
    @include min-screen($screen__max-tablet) {
        display: flex;
        margin: 0 auto 10rem;
        justify-content: center;
    }

    @include screen($screen__xl, 1920px) {
        width: 100% !important;
        max-width: 100% !important;

        .header.content {
            padding: 0 20px;
        }
    }

    .product.media {
        float: none;
        text-align: left;

        @include min-screen($screen__max-tablet) {
            min-width: 0; // firefox fix for flex-basis 50%
            width: 100%;
            margin: 0;
            flex: 0 0 55%;
        }

        @include min-screen($screen__xl) {
            flex: 0 0 55%;
        }

        .gallery-placeholder {
            margin: 0 auto;
            max-width: 580px;
            position: relative;
        }

        @include max-screen($screen__max-tablet) {
            max-width: 100%;
            width: 100%;
        }

        @include min-screen($screen__xxl) {
            max-width: 65%;
            flex: 0 0 65%;
        }
    }

    .product-info-main {
        float: none;
        box-sizing: border-box;
        overflow: visible;

        @include min-screen($screen__max-tablet) {
            width: 100%;
            margin-bottom: $indent__xxl;
            padding-left: 10px;
            max-width: 45%;
            flex: 0 0 45%;
        }

        @include max-screen($screen__max-tablet) {
            max-width: 100%;
            width: 100%;
            margin-top: 10px;
        }

        @include min-screen($screen__m) {
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            top: 100px;
            align-self: flex-start; /* <-- this is the fix */
        }

        @include min-screen($screen__xl) {
            max-width: 45%;
            flex: 0 0 45%;
        }

        @include min-screen($screen__xxl) {
            flex: 0 0 35%;
            max-width: 530px;
        }
    }
}

body {
    &.catalog-product-view {
        #launcher {
            bottom: 100px !important;
            @include max-screen($screen__max-tablet) {
                bottom: 150px !important;
            }
        }
    }

    &.catalog-product-view.overlay--active {
        #launcher {
            display: none !important;
        }
    }
}
