.footer {
    &__subscribe {
        @include lib-clearfix;

        .label {
            display: none;
        }

        .control {
            float: left;
            width: calc(100% - 20px);
        }

        .newsletter {
            flex: 1;

            input {
                height: 38px;
                padding: 0;
                background-color: transparent;
                border-bottom: 1px solid $color-gray-darker;
                color: white;
            }
        }
    }

    &__subscribe-button {
        @include btn(small);
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        border: 1px solid $color-white !important;

        &:hover {
            color: $color-white;
        }
    }

    &__subscribe-form {
        margin-top: $indent__l;
        display: flex;
    }
}
