.footer {
    &__bottom {
        margin: $indent__base 0;

        div {
            margin-bottom: 0 !important;
        }

        @include min-screen($screen__l) {
            @include layout-inner;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .footer__socials {
                order: 2;
            }

            .footer__copyright {
                flex: 1;
                order: 1;
                margin-bottom: 0;
                text-align: left;

                .copyright {
                    margin-bottom: 0;
                    margin-top: 0;

                    &::before,
                    &::after {
                        display: none;
                    }
                }

                div {
                    margin: 0 !important;
                    padding: 0 !important;
                }

                ul {
                    display: block;
                }

                li {
                    float: left;
                    padding-left: 0;
                }
            }

            .footer__payment {
                flex: 1;
                order: 3;
                margin-bottom: 0;
                text-align: right;
            }
        }
    }

    &__copyright {
        margin-bottom: $indent__s;
        text-align: center;

        .copyright {
            margin-bottom: $indent__s;
            margin-top: $indent__l;
            font-size: 1.2rem;
            font-weight: $font-weight__regular;
            color: $color-black;
        }

        ul {
            @include list-unstyled;
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 10px 0 15px;

            @include min-screen($screen__l) {
                margin: 15px 0;
            }

            @include max-screen($screen__s) {
                flex-direction: column;
            }
        }

        li {
            margin-bottom: $indent__s !important;

            @include min-screen($screen__s) {
                padding: 0 $indent__s;
            }
        }

        a {
            font-size: 1.2rem;
            font-weight: $font-weight__light;
            color: $color-black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__payment {
        margin-bottom: $indent__l;
        text-align: center;

        ul {
            @include list-unstyled;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin: 0 -7px;

            @include min-screen($screen__l) {
                justify-content: flex-end;
            }

            @include max-screen($screen__xs) {
                max-width: 300px;
                margin: 0 auto;
            }
        }

        li {
            padding: 7px;

            @include max-screen($screen__xs) {
                flex: 0 0 20%;
            }
        }
    }

    &__socials,
    &__payment {
        text-align: center;

        strong {
            font-size: 1.2rem;
            font-weight: $font-weight__regular;
            color: $color-black;
        }
    }

    &__socials {
        div {
            padding: 0 !important;
        }

        @include max-screen($screen__l) {
            padding-bottom: $indent__l;
        }
    }
}
