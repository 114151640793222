@mixin hero-link-location() {
    a {
        font-weight: 400;
        font-size: 1.3rem;
        text-decoration: none;
        position: relative;

        span {
            margin-left: $indent__l !important;
        }

        &::before {
            display: inline-block;
            width: 21px;
            height: 25px;
            content: '';
            position: absolute;
            left: 0;
            background-repeat: no-repeat;

            @extend .icon-location-white;
            @extend .icon-location-white-dims;

            @include max-screen($screen__m) {
                display: none;

                .jarallax & {
                    display: block;
                }
            }
        }

        &::after {
            display: inline-block;
            width: 21px;
            height: 25px;
            content: '';
            position: absolute;
            left: 0;
            background-repeat: no-repeat;

            @extend .icon-location;
            @extend .icon-location-dims;

            .jarallax & {
                display: none;
            }

            @include min-screen($screen__m)  {
                display: none;
            }
        }

        &:hover {
            &::before,
            &::after {
                animation: shake-top 0.35s linear both;
            }
        }
    }
}
