@include min-screen($screen__m) {
    .page-header {
        .switcher {
            margin-right: 15px;
            z-index: 9999;
            position: relative;
            right: 0;

            strong {
                font-size: 1.3rem;
            }

            .options {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: $panel-wrapper-height;
                background: $color-gray;

                .action.toggle {
                    padding: 5px 10px;
                }

                &:hover {
                    background: darken($color-gray, 5%);
                }

                ul.dropdown {
                    @include dropdown-defaults-right;
                    text-transform: none;

                    a {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-left: 3rem;
                        padding-right: 3rem;
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }

    .switcher-option__currency {
        text-transform: uppercase;
    }

    .page-header .switcher .options ul.dropdown,
    .page-footer .switcher .options ul.dropdown {
        min-width: 320px;
        border: 1px solid $color-gray;
        padding: 2rem 0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
        0 2px 2px rgba(0, 0, 0, 0.12),
        0 4px 4px rgba(0, 0, 0, 0.12),
        0 8px 8px rgba(0, 0, 0, 0.12),
        0 16px 16px rgba(0, 0, 0, 0.12);

        &:after {
            border-color: transparent transparent $color-gray transparent;
        }
    }

    .switcher-option__flag {
        display: inline-block;
        margin-right: 1rem;
        border-radius: 50%;
        overflow: hidden;
    }
}
