//
//  Forms
//  ---------------------------------------------

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"],
textarea,
select {
    &,
    &:focus,
    &:disabled {
        box-shadow: none;
        border-bottom: 1px solid $border-color__base;
        border-top: none;
        border-left: none;
        border-right: none;
        font-size: 16px;
        letter-spacing: 1.5px;
    }
}

input[type=checkbox] + label,
input[type=radio] + label {
    margin-left: $indent__s;
}

// select
select {
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='#{$color-black}' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-color: transparent;
    background-origin: content-box;
    background-position: right 4px center;
    background-repeat: no-repeat;
    display: block;
}

.form-create-account,
.form-edit-account,
.form-address-edit,
.form.password.forget,
.form.password.reset,
.form-login {
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"],
    textarea,
    select {
        &,
        &:focus,
        &:disabled {
            min-height: 50px;
            border: 1px solid #8c8c8c !important; // sass-lint:disable-line no-important
            border-radius: 3px;
        }

        &.mage-error,
        &[aria-invalid="true"] {
            border-color: red !important; // sass-lint:disable-line no-important
        }

        &.valid {
            border-color: $color-green !important;
            background-image: url('../images/icons/check-green.svg');
            background-repeat: no-repeat;
            background-position: center right 10px;
        }
    }

    .input-text[value=""] {
        border-color: $border-color__base;
    }

    .input-text[aria-invalid="false"]:not([value=""]) {
        border-color: $color-green;
        background-image: url('../images/icons/check-green.svg');
        background-repeat: no-repeat;
        background-position: center right 10px;
    }

    .input-text[aria-invalid="true"] {
        border-color: $color-red;
        background-image: url('../images/icons/close-red.svg');
        background-repeat: no-repeat;
        background-position: center right 10px;
    }
}

.form.password.reset {
    @include min-screen($screen__m) {
        .control {
            width: 50%;
        }
    }

    .actions-toolbar {
        margin-bottom: 30px;
    }
}
