//
//  Breadcrumbs variables
//  _____________________________________________

$breadcrumbs__font-size                          : 1.4rem;
$breadcrumbs-icon__use                           : false;

//  Current page
$breadcrumbs-current__color                      : $color-primary;
$breadcrumbs-current__font-weight                : $font-weight__bold;

$breadcrumbs-separator__symbol                   : "|";
$breadcrumbs-separator__color                    : $color-primary;

//
//  Link
//  ---------------------------------------------

$breadcrumbs-link__color                         : $color-primary;
$breadcrumbs-link__visited__color                : $color-primary;
$breadcrumbs-link__hover__color                  : $color-primary;
$breadcrumbs-link__active__color                 : $color-primary;
