.modal-popup._show {
    z-index: 99999 !important;
}

.modal-custom,
.modal-popup,
.modal-slide {
    .action-close {
        &:after {
            display: none;
        }
    }
}
