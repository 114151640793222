.product-combination-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px !important;

    &:last-child {
        margin-bottom: 0 !important;
    }

    .product-item-details {
        padding-top: 10px;
    }

    .product-item-details__row {
         .product-sale-label {
            top: 0;
        }
    }

    .old-price {
        margin-right: $indent__s;
        color: $color-gray-light;
    }

    @include min-screen($screen__m) {
        max-width: 25%;

        .product-item-inner {
            position: absolute;
            bottom: 60px!important;
            display: flex;
            justify-content: center;
        }
    }

    @include min-screen($screen__xl) {
        max-width: 21%;
    }
}

// Complex selector for overriding important statement
.product-combinations-section__content .product-combinations-section__list .product-combination-item.product.product-item {
    padding: 0 10px !important;

    @include min-screen($screen__m) {
        padding: 0 15px !important;
    }
}
