$indent__xxs: 1rem !default;
$indent__xs: 2.5rem !default;

.opc-wrapper .fieldset > .field:not(.choice) .field-tooltip {
    position: relative;
    display: inline-block;
    top: auto;
    right: auto;
}

.opc-wrapper .fieldset > .field:not(.choice) .field-tooltip-action {
    display: none;
}

.opc-wrapper .fieldset > .field:not(.choice) .field-tooltip-content {
    display: block;
    background: transparent;
    border: 0;
    border-radius: 1px;
    padding: 0;
    position: relative;
    top: 0;
    width: auto;
    color: #999;
    left: 0;
    font-size: 1.2rem;

    &:before,
    &:after {
        display: none;
    }
}

.opc-wrapper .form-login .fieldset .note,
.opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 1.5rem;
}

.opc-wrapper {
    .shipping-address-item.selected-item {
        border-color: black !important;
    }

    .shipping-address-item.selected-item:after {
        background: black !important;
    }

    .action-select-shipping-item {
        padding: 0;
        text-align: left;
        text-decoration: underline;
        width: 100%;
        border: 0;

        &:hover,
        &:focus {
            background-color: transparent;
            color: $color-black;
            border: 0;
            text-decoration: none !important;
        }

        &:after {
            display: none;
        }
    }

    .shipping-address-items {
        display: flex;
        flex-wrap: wrap;
    }

    .new-address-popup {
        margin-bottom: 50px;
    }
}

.field--newsletter-subscribe {
    margin-top: -10px;
    margin-bottom: 1rem;
}

.checkout-shipping-method tbody tr.row {
    display: block;
    border: 1px solid $color-gray-lighter !important;
    width: 100%;
    margin-top: 10px;
    padding: 0 15px;
    border-radius: 3px;
    box-sizing: border-box;
}

.table-checkout-shipping-method tbody td {
    border-top: none;
}

#pc-postcode-fieldset .field {
    margin: 10px 0 0;

    &[name="shippingAddress.pc_postcode_disable"] {
        margin: 0;
    }
}

.bbd-checkout-shipping-method__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $indent__xxs;
    margin-bottom: 20px !important;
    border: 0.1rem solid $color-gray-lighter !important; // sass-lint:disable-line no-important
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    min-height: 50px;

    .select-service-point-button,
    .bbd-activeants-servicepoint {
        display: none !important; // override activeants
    }

    @include min-screen($screen__m) {
        padding: $indent__xs;
        margin-bottom: $indent__xs * 2;
    }

    &.is--disabled {
        cursor: not-allowed;
        opacity: 0.6;

        .bbd-checkout-shipping-method__label {
            opacity: 0.4;
        }

        .bbd-checkout-shipping-method__header {
            padding-left: 40px;
            pointer-events: none;
        }
    }
}

.bbd-checkout-shipping-method__container + .row.row-error {
    margin-top: -10px;

    @include min-screen($screen__m) {
        margin-top: -$indent__xs * 2;
    }
}

div.admin__field.admin__field-option:nth-of-type(1) {
    margin: 0 0 3rem 4rem;
    font-size: 1.2rem;

    .checkout-b2c-switch & {
        margin: 0;
    }

    label {
        margin-left: 0;
    }
}

.bbd-checkout-shipping-method__carrier {
    font-size: 14px;

    @include max-screen($screen__s) {
        font-size: 12px;
    }
}

.bbd-checkout-shipping-method__container--selected {
    border-color: #8c8c8c !important; // sass-lint:disable-line no-important

    .select-service-point-button,
    .bbd-activeants-servicepoint {
        display: block !important; // override activeants
    }

    .bbd-checkout-shipping-method__carrier {
        font-weight: 700;
    }
}

.bbd-checkout-shipping-method__header {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px;
}

.bbd-checkout-shipping-method__radio-button {
    margin-right: $indent__xxs;
    margin-bottom: 0 !important; // override activeants
}

.bbd-checkout-shipping-method__choice {
    margin-top: $indent__xxs;
}

.bbd-checkout-shipping-method__price {
    margin-left: auto;
}

.bbd-checkout-shipping-method__header img,
.bbd-checkout-shipping-method__image {
    max-height: 2.5rem; // specific as per design
    margin-right: $indent__xs * 3;

    &.pickup.in.store {
        width: 15px;
        transform: scale(2.6);
    }
}

.servicepoint__title {
    font-size: 1.66rem !important; // override activeants
    font-weight: 800 !important; // override activeants
}

.bbd-activeants-servicepoint {
    @include min-screen($screen__m) {
        margin-top: $indent__xs;
    }
}

.bbd-servicepoint__title {
    font-weight: 700;
}

.bbd-checkout-shipping-method__radio-button {
    visibility: hidden;
    opacity: 0;
}

.bbd-checkout-shipping-method__radio-button:checked + label {
    cursor: pointer;

    &::before {
        opacity: 1;
        visibility: visible;
    }
}

.bbd-checkout-shipping-method__label {
    width: 20px;
    height: 20px;
    border: 2px solid $color-black;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    margin-left: 10px;

    &::before {
        width: 10px;
        height: 10px;
        background-color: $color-black;
        position: absolute;
        top: 3px;
        left: 3px;
        opacity: 0;
        content: '';
        border-radius: 50%;
        visibility: hidden;
        transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
    }
}

// Login button
.checkout-shipping-address {
    position: relative;
}

.customer-email__login-toggle {
    font-size: 1.4rem;
    position: absolute;
    right: 0;
    top: 0.3rem;

    @include min-screen($screen__l) {
        position: absolute;
        right: calc(100% - 500px);
        top: 0.3rem;
    }

    @media screen and (min-width: 880px) and (max-width: 1000px) {
        position: relative;
        top: 0;
        left: 0;
        right: auto;
    }

    @media screen and (max-width: 360px) {
        position: relative;
        top: 0;
        left: 0;
        right: auto;
    }
}
