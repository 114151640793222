.product-landing-section {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 30px 0;
    box-sizing: border-box;
    position: relative;
    z-index: 4;

    @include min-screen($screen__m) {
        width: 100%;
        margin: 0 auto;
        padding: 50px 0;
        max-width: $screen__fhd;
    }

    &.bottom-banners .pagebuilder-column {
        @include min-screen($screen__m) {
            padding: 10px;
        }
    }
}

.product-landing-section--intro {
    padding-top: 60px;
}

.product-landing-section__container {
    .aligned-center & {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    h1 {
        text-align: center;
    }
}

.product-landing-section__subtitle {
    @include landingpage-subtitle;
    margin: 0 0 20px;

    @include min-screen($screen__m) {
        margin: 0 0 30px;
    }
}

.product-landing-section__content {
    text-align: center;

    @include min-screen($screen__m) {
        width: 60%;
    }

    @include min-screen($screen__xl) {
        width: 27%;
    }
}

.product-landing-section__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 20px 0;
    padding: 0;

    @include max-screen($screen__xl) {
        &.has-overflow {
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            overflow-x: scroll;
            max-width: 100%;

            @supports (scroll-snap-type: x mandatory) {
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    width: 0 !important;
                }
            }
        }
    }

    @include min-screen($screen__m) {
        margin: 30px 0;
    }
}

.product-landing-section__item {
    width: calc(50% - 10px);
    margin: 0 10px;
    text-align: center;

    img {
        max-width: 100%;
    }

    @include min-screen($screen__m) {
        width: 220px;
        margin: 0 30px;
    }

    @include max-screen($screen__xl) {
        .has-overflow & {
            scroll-snap-align: start;
            min-width: calc(40% - 10px);
            overflow: hidden;

            h3 {
                max-height: 35px;
            }
        }

        h3 {
            font-size: 13px;
        }
    }
}

.product-landing-section__cta {
    @include btn-dark;
    @include landingpage-button-after;

    &:focus {
        color: $color-black !important;
    }
}


.product-landing-container {
    .product-landing-section {
        @include max-screen($screen__m) {
            .product-landing-item__column:first-child {
                order: 2;
            }

            .product-landing-item__column:last-child {
                order: 1;
                align-items: flex-start;
            }
        }
    }
}

// Modifier for every second landing section
.product-landing-container:nth-child(odd) {
    .product-landing-section {
        @include min-screen($screen__m) {
            .product-landing-item__column:first-child {
                order: 2;
            }

            .product-landing-item__column:last-child {
                order: 1;
                align-items: flex-start;
            }
        }
    }

    .product-landing-item__title {
        @include min-screen($screen__m) {
            @include landingpage-content-visibility(50ms, translate3d(100px, -50px, 0) rotate(5deg));
        }
    }

    .product-landing-item__subtitle {
        @include min-screen($screen__m) {
            @include landingpage-content-visibility(150ms, translate3d(100px, -50px, 0) rotate(5deg));
        }
    }

    .product-landing-item__content {
        @include min-screen($screen__m) {
            max-width: 60%;
            @include landingpage-content-visibility(200ms, translate3d(100px, -50px, 0) rotate(5deg));
        }
    }

    .product-landing-item__combinations-list {
        @include min-screen($screen__m) {
            &.section-inview {
                transform: translate3d(-20%, 150%, 0) scale(1.4);
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    .product-landing-item__column-label {
        right: -20px;
        top: -20px;

        @include min-screen($screen__xl) {
            top: 20px;
            right: inherit;
            left: calc(100% - 20px);
        }
    }
}
