.product-landing-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 20px;

    @include min-screen($screen__m) {
        margin: 0;
        flex-direction: row;
    }
}

.product-landing-item__column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    @include min-screen($screen__m) {
        width: calc(100% - 700px);
    }
}

.product-landing-item__column-label {
    background-color: white;
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 2;
    padding: 5px;
    white-space: nowrap;
    @include landingpage-subtitle();

    @include min-screen($screen__xl) {
        right: calc(100% - 20px);
        top: 20px;
    }
}

.product-landing-item__column--visual {
    position: relative;
    max-width: 500px;

    @include min-screen($screen__m) {
        display: flex;
        align-items: flex-end;
    }

    img {
        object-fit: cover;
        font-family: "object-fit: cover;";
    }
}

.product-item-slider {
    width: 100%;
    height: 100%;
    position: relative;
}

.product-item-slider__slides {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.product-item-slider__slide {
    img {
        opacity: 0;
        transition: opacity 250ms ease-in-out;
    }

    &.glide__slide--active {
        img {
            opacity: 1;
        }
    }
}

.product-item-slider__bullets {
    position: absolute;
    height: 40px;
    bottom: 7px;
    left: 0;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-black;
}

.product-item-slider__bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 10px;
    padding: 0;
    background-color: transparent;
    border: 1px solid $color-black;

    &.glide__bullet--active {
        background-color: $color-black;
    }

    &::after {
        display: none;
    }
}

.product-landing-item__product {
    text-align: center;

    h4 {
        margin-bottom: 0;
    }

    p {
        @include landingpage-subtitle;
    }

    img {
        max-width: 100%;
    }

    @include min-screen($screen__m) {
        transition: opacity 250ms ease-in-out;
        transition-delay: 400ms;
        opacity: 0;
    }
}

.product-landing-item__title {
    margin-bottom: 0;

    @include min-screen($screen__m) {
        @include landingpage-content-visibility(50ms, translate3d(-100px, -50px, 0) rotate(-5deg));
    }
}

.product-landing-item__subtitle {
    @include landingpage-subtitle;
    margin-top: 10px;

    @include min-screen($screen__m) {
        @include landingpage-content-visibility(150ms, translate3d(-100px, -50px, 0) rotate(-5deg));
    }
}

.product-landing-item__content {
    text-align: center;
    margin-bottom: 20px;

    @include min-screen($screen__xxl) {
        max-width: 40%;
    }

    @include min-screen($screen__m) {
        max-width: 60%;
        @include landingpage-content-visibility(200ms, translate3d(-100px, -50px, 0) rotate(-5deg));
    }
}

.product-landing-item__combinations {
    text-align: center;

    @include min-screen($screen__m) {
        transition: opacity 250ms ease-in-out;
        transition-delay: 500ms;
        opacity: 0;
    }
}

.product-landing-item__combinations-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 20px 0 40px 0;
    padding: 0;
    justify-content: center;
    transition: transform 550ms ease-in-out, opacity 550ms ease-in-out, visibility 550ms ease-in-out;
    position: relative;
    z-index: 2;

    @include min-screen($screen__m) {
        visibility: hidden;

        .section-inview & {
            visibility: visible;
        }

        &.section-inview {
            transform: translate3d(20%, 150%, 0) scale(1.4);
            transition: transform 550ms ease-in-out, opacity 550ms ease-in-out, visibility 550ms ease-in-out;
            transition-delay: 50ms;
            opacity: 0;
            pointer-events: none;
        }
    }

    li {
        margin: 0 20px;
        cursor: inherit;
        max-width: 25%;
        display: flex;
        align-items: center;

        &:nth-child(2) {
            transform: translateY(20px);
        }

        &:nth-child(3) {
            transform: translateY(-10px) translateX(-5px);
        }

        &:nth-child(4) {
            transform: translateY(30px);
        }
    }

    li img {
        max-width: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;";
    }
}

.product-landing-item__combinations-cta {
    @include btn-dark;
    @include landingpage-button-after;
}

// Modifier if section is inview
.section-inview {
    .product-landing-item__title,
    .product-landing-item__subtitle,
    .product-landing-item__content,
    .product-landing-item__product,
    .product-landing-item__combinations {
        transform: translate3d(0, 0, 0) rotate(0deg) scale(1) !important;
        opacity: 1 !important;
        pointer-events: inherit !important;
    }
}
