@mixin counter() {
    line-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-clip: padding-box;
    font-size: 1.3rem;
    font-weight: $font-weight__light;
    color: $color-black;
    overflow: visible;
    height: auto;
}
