body {
    .slick-slider {
        display: none;
        position: static !important;

        &.slick-initialized {
            display: block;
        }

        .slick-arrow {
            position: absolute;
            z-index: 1;
            margin-top: -29px;
            top: 50%;
            overflow:hidden;
            border: 0;
            padding: 0;
            height: 58px;
            width: 39px;
            text-indent: -9999px;
            white-space: nowrap;
            box-shadow: none !important;
            transform: none !important;

            &,
            &:hover {
                background: transparent;
            }

            &:before {
                content: '';
                width: 46px;
                height: 46px;
                border-radius: 50%;
                background: $color-white;
                position: absolute;
                top: 6px;
                box-shadow: 0 0 6px 1px rgba(0,0,0,0.25);
            }

            &:after {
                content: '';
                position: absolute;
                top: 25px;
            }

            &.slick-prev {
                left: -20px;

                &:before {
                    left: -19px;
                }

                &:after {
                    @include icon('arrow-left');
                    left: 10px;
                }

                @include min-screen($layout__max-width) {
                    left: 5px;
                }
            }

            &.slick-next {
                right: -20px;

                &:before {
                    right: -19px;
                }

                &:after {
                    @include icon('arrow-right');
                    right: 10px;
                }

                @include min-screen($layout__max-width) {
                    right: 5px;
                }
            }

            &.slick-disabled {
                display: none !important;
            }
        }
    }

}
